import React from "react";
import moment from "moment"
import MaterialTable from 'material-table';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import './index.css';


// Require locales
import { enGB, enUS, de } from 'date-fns/locale'

class SpecialDayTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
    };
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  dateRemoveTimePart(date) {
    date.setUTCHours(0, 0, 0, 0);
    return date;
  }

  validateDate(rowData, date, type) {
    const { showNotification } = this.props
    let fromDate
    let toDate

    if (type === "fromDate") {
      const { DateTo } = rowData
      fromDate = moment(date)
      toDate = moment(DateTo)
    }
    if (type === "toDate") {
      const { DateFrom } = rowData
      fromDate = moment(DateFrom)
      toDate = moment(date)
    }

    if (type === "fromDate") {
      if (toDate.diff(fromDate, "days") < 0) {
        showNotification("DateFrom cannot be greater than DateTo", "error")
        return false
      } else {
        if (toDate.diff(fromDate, 'days', true) <= 183) {
          return true
        } else {
          showNotification("The duration cannot be greater than 6 months", "error")
          return false
        }
      }
    }
    if (type === "toDate") {
      if (toDate.diff(fromDate, "days") < 0) {
        showNotification("DateTo cannot be less than DateFrom", "error")
        return false
      } else {
        if (toDate.diff(fromDate, 'days', true) <= 183) {
          return true
        } else {
          showNotification("The duration cannot be greater than 6 months", "error")
          return false
        }
      }
    }
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }
  }

  render() {
    const { organisations, selectedFilters } = this.state;
    const { t, lng } = this.props;
    const lookupOrgs = {};

    const locale = this.selectLocale(lng);
    const formatDate = dateTime => moment(dateTime).format('DD.MM.YYYY');
    const columns = [
      {
        title: t('organisation'), field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-organisation" id="org-label">*</InputLabel>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              autoFocus
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
                /*const waitUntilPopupIsMounted = async () => {
                  await new Promise(resolve => setTimeout(() => resolve(), 100));
                  if (!organisationPopup) {
                    await waitUntilPopupIsMounted();
                  }
                };*/

                if (organisationPopup) {
                  const orgsList = organisationPopup.childNodes[0].childNodes;
                  if (orgsList) {
                    const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
                    let i = 0;
                    orgsList.forEach(orgElement => {
                      const orgObj = organisations.find(item => item.Name === orgElement.getAttribute("data-value"));
                      const orgText = document.getElementsByClassName("MuiListItem-button")[i];
                      if (orgObj.Level === topLevel) {
                        orgText.style.paddingLeft = "10px";
                      } else if (orgObj.Level > topLevel) {
                        const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 15;
                        orgText.style.paddingLeft = `${margin}px`;
                      }
                      i++;
                    })
                  }
                } /*else if (!organisationPopup) {
                    await waitUntilPopupIsMounted();
                  }*/
              }}
            >
              {Object.keys(props.columnDef.lookup).map((key, i) => (
                selectedFilters.length === 0 || selectedFilters.includes(key) ?
                  <MenuItem
                    value={key}
                    key={i}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: t('date-from'), field: "DateFrom", type: "date", filtering: false,
        render: rowData => (<TextField value={formatDate(rowData.DateFrom)} />),
        editComponent: props => (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={locale}>
            <DatePicker
              format="dd.MM.yyyy"
              value={props.value || null}
              onChange={e => {this.validateDate(props.rowData, moment(e).format("YYYY-MM-DD"), "fromDate") && props.onChange(this.dateRemoveTimePart(e))}}
              locale={locale}
              error={props.value === null || props.value === undefined}
              okLabel={t('date-picker-label-ok')}
              cancelLabel={t('date-picker-label-cancel')}
              invalidDateMessage={t('date-picker-label-invalid-date-format')}
            />
          </MuiPickersUtilsProvider>
        )
      },
      {
        title: t('date-to'), field: "DateTo", type: "date", filtering: false,
        render: rowData => (<TextField value={formatDate(rowData.DateTo)} />),
        editComponent: props => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={locale}>
            <DatePicker
              format="dd.MM.yyyy"
              value={props.value || null}
              onChange={e => {this.validateDate(props.rowData, moment(e).format("YYYY-MM-DD"), "toDate") && props.onChange(this.dateRemoveTimePart(e))}}
              okLabel={t('date-picker-label-ok')}
              error={props.value === null || props.value === undefined}
              cancelLabel={t('date-picker-label-cancel')}
              invalidDateMessage={t('date-picker-label-invalid-date-format')}
            />
          </MuiPickersUtilsProvider>
        )
      },
      { title: t('name'), field: "Name", defaultSort: "asc", editComponent: props => (
        <FormControl>
          <InputLabel className="trans-label-name" id="org-label">*</InputLabel>
          <TextField
            id="name-input"
            value={props.value}
            type="text"
            error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
            inputProps={{maxLength: 255}}
            onChange={e => props.onChange(e.target.value)}
          />
        </FormControl>
      ), filtering: false },
      {
        title: t("is-public-holiday"), field: "IsPublicHoliday", type: 'boolean',
        render: rowData => (<Checkbox checked={rowData.IsPublicHoliday} disabled={true} />),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
    }

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={this.state.data}
        options={{
          pageSize: this.state.data.length > 5 ? 10 : 5,
          pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20] : [5],
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            dateTimePickerLocalization: de,
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          },
        }}
        editable={this.state.editable && {
          onRowAdd: newData =>
            new Promise(resolve => {
              this.setState({
                newData: newData
              });
              setTimeout(async () => {
                resolve();
                const { organisations } = this.state;
                if (organisations) {
                  const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                  const errorOrResult = await this.props.onRowAdd({
                    DateFrom: (typeof newData.DateFrom !== "string") && newData.DateFrom !== undefined ? newData.DateFrom.toISOString().split("T", 1)[0] : newData.DateFrom,
                    DateTo: (typeof newData.DateTo !== "string") && newData.DateTo !== undefined ? newData.DateTo.toISOString().split("T", 1)[0] : newData.DateTo,
                    Name: newData.Name,
                    IsPublicHoliday: newData.IsPublicHoliday === undefined ? false : newData.IsPublicHoliday,
                    Organisation: {
                      ID: org ? org.ID : null
                    }
                  });
                  if (!(errorOrResult instanceof Error)) {
                    if (org) {
                      newData.Organisation = org;
                    }
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      newData.ID = errorOrResult.ID;
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }
                }
              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                if (oldData) {
                  const { organisations } = this.state;
                  if (organisations && newData.Organisation) {
                    const org = organisations.find(org => org.Name === newData.Organisation.Name);
                    if (org) {
                      const errorOrResult = await this.props.onRowUpdate({
                        ...newData,
                        DateFrom: (typeof newData.DateFrom !== "string") && newData.DateFrom !== undefined ? newData.DateFrom.toISOString().split("T", 1)[0] : newData.DateFrom,
                        DateTo: (typeof newData.DateTo !== "string") && newData.DateTo !== undefined ? newData.DateTo.toISOString().split("T", 1)[0] : newData.DateTo,
                        IsPublicHoliday: newData.IsPublicHoliday === undefined ? false : newData.IsPublicHoliday,
                        Organisation: {
                          ID: org.ID
                        }
                      }, oldData.tableData);
                      if (!(errorOrResult instanceof Error)) {
                        if (org) {
                          newData.Organisation = org;
                        }
                        this.setState(prevState => {
                          const data = [...prevState.data];
                          data[data.indexOf(oldData)] = newData;
                          return { ...prevState, data };
                        });
                      }
                    }
                  }
                }
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                const errorOrResult = await this.props.onRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }
              }, 100);
            })
        }}
      />
    );
  }
}

export default SpecialDayTable;