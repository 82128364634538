import { REHYDRATE } from 'redux-persist'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_ERROR,
  USER_LOGOUT_SUCCESS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  SET_USER_LANGUAGE,
  SET_CHANGE_MESSAGE_TOKEN,
  SET_ENVIRONMENT_LOGO,
  TOKEN_EXPIRED
} from "../../actions/types";

const initialState = {};

const LoginStatus_LoggedIn = 2**2;
const LoginStatus_SetNewPassword = 2**3;
const LoginStatus_PasswordExpired = 2**4;


export default (state = initialState, action) => {
  switch (action.type) {

    case REHYDRATE: {
      const sessionUser = JSON.parse(sessionStorage.getItem("user"))

      if (sessionUser === null) {
        return initialState
      }

      return {
        ...state,
        username: sessionUser.DisplayName,
        userNumber: sessionUser.UserNumber,
        organisation: sessionUser.Organisation,
        environment: sessionUser.DatabaseInstanceName,
        environmentDisplayName: sessionUser.environmentDisplayName,
        token: sessionUser.WebToken,
        expiresAt: sessionUser.WebTokenValidToUtc,
        lastPasswordChange: sessionUser.LastPasswordChangeUtc,
        passwordOptions: sessionUser.PasswordOptions,
        loginStatusCode: sessionUser.LoginStatusCode,
        roles: sessionUser.Roles,
        crewName: sessionUser.CrewName,
        loginStatusCode: sessionUser.LoginStatusCode,
        isLoggedIn: sessionUser.isLoggedIn,
        isPasswordExpired: sessionUser.isPasswordExpired,
        isSetNewPassword: sessionUser.isSetNewPassword,
        companyLogoUrl: sessionUser.companyLogoUrl
      }
    }

    case SET_USER_LANGUAGE:
      return {
        ...state,
        userLanguage: action.language
      }

    case SET_ENVIRONMENT_LOGO:
      return {
        ...state,
        companyLogoUrl: action.logo
      }

    case USER_LOGIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        isFetching: true,
        error: undefined
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        username: action.payload.DisplayName,
        userNumber: action.payload.UserNumber,
        organisation: action.payload.Organisation,
        environment: action.payload.DatabaseInstanceName,
        environmentDisplayName: action.payload.DatabaseInstanceDisplayName,
        token: action.payload.WebToken,
        expiresAt: action.payload.WebTokenValidToUtc,
        lastPasswordChange: action.payload.LastPasswordChangeUtc,
        passwordOptions: action.payload.PasswordOptions,
        loginStatusCode: action.payload.LoginStatusCode,
        roles: action.payload.Roles,
        crewName: action.payload.CrewName,
        isLoggedIn: action.payload.LoginStatusCode & LoginStatus_LoggedIn,
        isPasswordExpired: action.payload.LoginStatusCode & LoginStatus_PasswordExpired,
        isSetNewPassword: action.payload.LoginStatusCode & LoginStatus_SetNewPassword,
        isFetching: false,
        error: undefined
      };

    case USER_LOGIN_ERROR:
      return {
        isLoggedIn: false,
        isFetching: false,
        error: `${action.error}`
      };

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        username: action.payload.DisplayName,
        userNumber: action.payload.UserNumber,
        organisation: action.payload.Organisation,
        environment: action.payload.DatabaseInstanceName,
        environmentDisplayName: action.payload.environmentDisplayName,
        token: action.payload.WebToken,
        expiresAt: action.payload.WebTokenValidToUtc,
        lastPasswordChange: action.payload.LastPasswordChangeUtc,
        passwordOptions: action.payload.PasswordOptions,
        loginStatusCode: action.payload.LoginStatusCode,
        roles: action.payload.Roles,
        crewName: action.payload.CrewName,
        isLoggedIn: action.payload.LoginStatusCode & LoginStatus_LoggedIn,
        isPasswordExpired: action.payload.LoginStatusCode & LoginStatus_PasswordExpired,
        isSetNewPassword: action.payload.LoginStatusCode & LoginStatus_SetNewPassword,
        isFetching: false,
        error: undefined
      };

    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        isFetching: true,
        error: undefined
      };

    case USER_LOGOUT_SUCCESS:
      return initialState;

    case USER_LOGOUT_ERROR:
      return {
        ...state,
        isFetching: false,
        error: `${action.error}`
      };

    case SET_CHANGE_MESSAGE_TOKEN:
      return {
        ...state,
        changeMessageToken: `${state.token}${Math.floor(Math.random() * 1000000)}`
      }

    case TOKEN_EXPIRED:
      return {
        isLoggedIn: false,
        isFetching: false
      }

    default:
      return state;
  }
};
