import { combineReducers } from "redux";
import user from "./user";
import equipment from "./equipment"
import customer from "./customer";
import employee from "./employee";
import crew from "./crew";
import specialday from "./specialday";
import driver from "./driver";
import mixingPlant from "./mixingPlant";
import mixtureOrder from "./mixtureOrder";
import mixture from "./mixture";
import pensum from "./pensum";
import organisation from "./organisation";
import settings from "./settings";
import notification from "./notification";
import filter from "./filter";
import project from "./project";
import projectActivity from "./projectActivities";
import projectActivities from "./projectActivities";

const appReducer =
  combineReducers({
    user,
    equipment,
    customer,
    employee,
    crew,
    specialday,
    driver,
    mixingPlant,
    mixtureOrder,
    mixture,
    organisation,
    pensum,
    settings,
    notification,
    filter,
    project,
    projectActivity,
    projectActivities
  });

export default (state, action) => {
  if (action.type === "USER_LOGOUT_SUCCESS" || action.type === "TOKEN_EXPIRED") {
    const settings = state.settings.settings
    localStorage.removeItem('persist:root')
    sessionStorage.removeItem('user')
    state = { settings: settings }
  }

  if (action.type === "USER_LOGIN_SUCCESS") {
    state = {
      ...state,
      filter: {}
    }
  }

  return appReducer(state, action);
};