import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';

import Planning from "./Planning";
import pensumActions from "../../actions/pensum";
import customerActions from "../../actions/customer";
import crewActions from "../../actions/crew";
import driverActions from "../../actions/driver";
import mixingPlantActions from "../../actions/mixingPlant";
import mixtureActions from "../../actions/mixture";
import mixtureOrderActions from "../../actions/mixtureOrder";
import employeeActions from "../../actions/employee";
import equipmentActions from "../../actions/equipment";
import projectActions from "../../actions/project";
import projectActivitiesActions from "../../actions/projectActivities";
import entryActions from "../../actions/entries";
import notificationActions from "../../actions/notification";
import userActions from "../../actions/user";
import specialdayActions from "../../actions/specialday";
import filterActions from "../../actions/filter";
import waitForMessages from "../../actions/changeMessages/waitForMessages";

import { isStateFetching } from '../../utils';

const mapStateToProps = ({
  pensum,
  crew,
  user,
  project,
  projectActivities,
  mixture,
  mixtureOrder,
  mixingPlant,
  equipment,
  driver,
  employee,
  specialday,
  settings,
  filter
}) => ({
  crews: crew.crews,
  pensums: pensum.pensums,
  equipment: equipment.equipment,
  employee: employee.employee,
  drivers: driver.driver,
  projects: project.projects,
  projectActivities: projectActivities.projectActivities,
  mixtures: mixture.mixture,
  mixtureOrders: mixtureOrder.mixtureOrder,
  mixingPlants: mixingPlant.mixingPlant,
  specialDays: specialday.specialday,
  headerColor: settings.settings.HeaderBackgroundColor,
  userCrewName: user.crewName,
  userRoles: user.roles,
  filters: filter,
  isFetching: isStateFetching([
    pensum,
    crew,
    project,
    projectActivities,
    mixture,
    mixtureOrder,
    mixingPlant,
    equipment,
    employee,
    settings,
    filter
  ])
});

const mapDispatchToProps = {
  ...pensumActions,
  ...customerActions,
  ...crewActions,
  ...driverActions,
  ...mixingPlantActions,
  ...mixtureActions,
  ...mixtureOrderActions,
  ...projectActivitiesActions,
  ...projectActions,
  ...employeeActions,
  ...equipmentActions,
  ...notificationActions,
  ...entryActions,
  ...userActions,
  ...specialdayActions,
  ...filterActions,
  waitForMessages
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Planning);
export default withNamespaces(['common'])(connected);
