import React, { Component } from 'react';
import styled from 'styled-components';
import clsx from "clsx";
import { Draggable } from 'react-beautiful-dnd';

import NumberInput from '../../../../core/components/Inputs/NumberInput/numberInput';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Modal from '@material-ui/core/Modal';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputAdornment from '@material-ui/core/InputAdornment';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeletePensumIcon from '@material-ui/icons/DeleteForever';
import CopyIcon from '@material-ui/icons/FileCopy';
import CustomerIcon from '@material-ui/icons/PermContactCalendar';
import EmployeeIcon from '@material-ui/icons/SupervisorAccount';
import EquipmentIcon from '@material-ui/icons/Build';
import MixtureIcon from '@material-ui/icons/LineStyle';
import DriverIcon from '@material-ui/icons/LocalShipping';
import InvoiceIcon from '@material-ui/icons/Euro';
import ArrowDropUpIcon  from '@material-ui/icons/UnfoldMore';
import ArrowDropDownIcon from '@material-ui/icons/UnfoldLess';
import ActivityIcon from '@material-ui/icons/LocalActivity';
import OptionsIcon from '@material-ui/icons/MoreVert';
import CommentIcon from '@material-ui/icons/Comment';

import { withStyles } from "@material-ui/core/styles";

import './index.css';
import { Grid } from '@material-ui/core';
import { rgbToHex } from '../../../../utils';

const styles = theme => ({
  input: {
    
  },
  singleWideInput: {
    width: 180
  }
});

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    /*'&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },*/
  },
}))(MenuItem);

const Container = styled.div`
  color: ${props => props.isDragging ? '#969696' : props.popupIsOpen ? '#1f1f1f' : '' };
  min-height: 55px;
  border-radius: 5px 5px 4px 4px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  box-shadow: ${props => props.isDragging ? '' : '0 1px 0 rgba(9,30,66,.55)'};
  background-color: ${props =>
    props.isDragDisabled
    ? 'lightgrey'
    : props.isDragging
    ? '#fff'
    : props.popupIsOpen ? '#f0f0f0' : '#fff'
  };
`

class Pensum extends Component {
  constructor(props) {
    super(props)

    const { task } = props
    const { pensum } = task

    this.state = {
      selectedProjectID: null,
      selectedProjectActivity: null,
      projectActivityEntries: null,
      filteredMixtureOrders: [],
      filteredMixtures: [],
      selectedEmployee: null,
      selectedEquipment: null,
      selectedMixture: null,
      selectedMixtureQuantity: null,
      selectedMixingPlant: null,
      selectedMixtureOrderNumber: null,
      selectedDriver: null,
      selectedDriverPayload: null,
      selectedDriverStartTime: "06:30",
      selectedDriverMixingPlant: null,
      open: false,
      anchorEl: null,
      isHovering: false,
      isMounted: false,
      totalTons: pensum.TotalMixtureAmount
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if(event.keyCode === 27) {
      this.closePopup()
    }
  }

  closePopup = () => {
    this.setState({ open: false, isHovering: false, openForEditEntry: false })
  }

  handleClick = (entryName, entry, realEntry, popupIsOpen, driverMixingPlant) => {
    const {
      task: { pensum },
      projectActivities,
      employee,
      mixingPlants,
      equipment
    } = this.props
    const { open } = this.state
    let defaultDriverMixingPlant = undefined

    if (pensum.MixtureEntries) {
      let i
      for (i = 0; i < pensum.MixtureEntries.length; i++) {
        const mixtureEntry = pensum.MixtureEntries[i]
        if (
          mixtureEntry.Mixture
          && mixtureEntry.Mixture.Order
          && mixtureEntry.Mixture.Order.MixingPlant
          && mixingPlants.some(mixingPlant => mixingPlant.ID === mixtureEntry.Mixture.Order.MixingPlant.ID)
        ) {
          defaultDriverMixingPlant = mixtureEntry.Mixture.Order.MixingPlant.ID
          break;
        }
      }
    }

    if (!defaultDriverMixingPlant) {
      if (pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted) {
        defaultDriverMixingPlant = pensum.Project.DefaultMixingPlant.ID
      }
    }

    if (entryName && entry) {
      const { mixtureOrders, mixtures } = this.props
      let driverTime = entry.StartTime && entry.StartTime.replace("PT","").replace("H",":").replace("M","")
      if (entry.StartTime && entry.StartTime.includes("M") && driverTime.split(":")[1].length === 1)
        driverTime = `${driverTime.split(":")[0]}:0${driverTime.split(":")[1]}`
      if (entry.StartTime && entry.StartTime.includes("H") && !entry.StartTime.includes("M") && driverTime.split(":")[1] === "")
        driverTime = `${driverTime.split(":")[0]}:00`

      this.setState({
        open: !open,
        openDelete: false,
        openForEditEntry: true,
        isHovering: false,

        openAssignEntity: entryName === "activity" || entryName === "employee" || entryName === "equipment" ? true : false,
        openAssignMixtureEntity: entryName === "mixture" ? true : false,
        openAssignDriverEntity: entryName === "driver" ? true : false,

        selectedProjectActivity: entryName === "activity" ? projectActivities.find(obj => obj.ID === entry.ID) : null,
        selectedEmployee: entryName === "employee" ? employee.find(obj => obj.ID === entry.ID) : null,
        selectedEquipment: entryName === "equipment" ? equipment.find(obj => obj.ID === entry.ID) : null,

        editSingleEntry: {
          entryId: realEntry && realEntry.ID,
          entryType:
            entryName === "activity" ? "ProjectActivityEntries"
            : entryName === "employee" ? "EmployeeEntries"
            : entryName === "equipment" ? "EquipmentEntries"
            : null,
          entry: entryName === "activity" ? projectActivities.find(obj => obj.ID === entry.ID)
          : entryName === "employee" ? employee.find(obj => obj.ID === entry.ID)
          : entryName === "equipment" ? equipment.find(obj => obj.ID === entry.ID) : null
        },

        filteredMixtureOrders: entryName === "mixture" ? Array.from(mixtureOrders).filter(mix => mix.MixingPlant.ID === entry.Mixture.Order.MixingPlant.ID) : [],
        filteredMixtures: entryName === "mixture" ? Array.from(mixtures).filter(mix => mix.Order.OrderNumber === entry.Mixture.Order.OrderNumber) : [],
        selectedMixture: entryName === "mixture" ? entry.Mixture && entry.Mixture.ID : null,
        selectedMixingPlant: entryName === "mixture" ? entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.MixingPlant && entry.Mixture.Order.MixingPlant.ID : null,
        selectedMixtureQuantity: entryName === "mixture" ? entry.MixtureAmount : null,
        mixtureComment: entryName === "mixture" ? entry.Comment : null,
        selectedMixtureOrderNumber: entryName === "mixture" ? entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.OrderNumber : null,

        selectedDriver: entryName === "driver" ? entry.Driver && entry.Driver.ID : null,
        selectedDriverStartTime: entryName === "driver" ? driverTime : null,
        selectedDriverPayload: entryName === "driver" ? entry.Payload : null,
        selectedDriverMixingPlant: entryName === "driver" ? entry.MixingPlant && entry.MixingPlant.ID : null,
        driverComment: entryName === "driver" ? entry.Comment : null,

        editComplexEntry:
          entryName === "mixture" ? {
            entryId: entry.ID,
            entryType: "MixtureEntries",
            mixture: entry.Mixture,
            mixingPlant: entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.MixingPlant && entry.Mixture.Order.MixingPlant.ID,
            mixtureOrderNumber: entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.OrderNumber,
            mixtureQuantity: entry.Mixture && entry.MixtureAmount,
            mixtureComment: entry.Comment
          } : entryName === "driver" ? {
            entryId: entry.ID,
            entryType: "DriverEntries",
            driver: entry.Driver,
            mixingPlant: entry.MixingPlant && entry.MixingPlant.ID,
            driverPayload: entry && entry.Payload,
            driverComment: entry.Comment
          } : null
      })
    } else {
      const { mixtureOrders } = this.props
      const defaultMixingPlant = pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted ? pensum.Project.DefaultMixingPlant.ID : null
      let filteredMixtureOrders = []
      
      if (defaultMixingPlant) {
        filteredMixtureOrders = Array.from(mixtureOrders).filter(mix => mix.MixingPlant.ID === defaultMixingPlant)
      }

      this.setState({
        open: popupIsOpen !== null && popupIsOpen !== undefined ? popupIsOpen : true,
        openDelete: false,
        isHovering: popupIsOpen !== null && popupIsOpen !== undefined ? popupIsOpen : true,
        selectedProjectActivity: null,
        selectedEquipment: null,
        selectedEmployee: null,
        selectedMixture: null,
        selectedMixingPlant: pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted ? pensum.Project.DefaultMixingPlant.ID : null,
        selectedMixtureQuantity: null,
        selectedMixtureOrderNumber: null,
        mixtureComment: null,
        selectedDriver: null,
        selectedDriverStartTime: null,
        selectedDriverPayload: null,
        selectedDriverMixingPlant: defaultDriverMixingPlant || driverMixingPlant,
        driverComment: null,
        filteredMixtureOrders: filteredMixtureOrders,
        openForEditEntry: false,
        openAssignEntity: true,
        openAssignMixtureEntity: true,
        openAssignDriverEntity: true,
      })
    }
  }

  handleSave = async () => {
    const {
      task,
      createEquipmentEntry,
      createMixtureEntry,
      createDriverEntry,
      createEmployeeEntry,
      createProjectActivityEntry,
      getPensums,
      showNotification,
      selectedFromDate,
      selectedToDate,
      handleNewObjectInHistory
    } = this.props
    const {
      selectedProjectActivity,
      selectedEmployee,
      selectedEquipment,
      selectedMixture,
      selectedMixtureQuantity,
      selectedMixtureOrderNumber,
      selectedMixingPlant,
      mixtureComment,
      selectedDriver,
      selectedDriverStartTime,
      selectedDriverPayload,
      selectedDriverMixingPlant,
      driverComment,
      openForEditEntry
    } = this.state
    const { pensum } = task
    const startTimeForDriver = selectedDriverStartTime === null ? "06:30" : selectedDriverStartTime

    /*
      Edit Entries of Pensum
    */
    if (openForEditEntry) {
      const { editEntry } = this.props
      const { editSingleEntry, editComplexEntry } = this.state

      if (editSingleEntry && editSingleEntry.entryId) {
        let newSingleEntry, entityId

        if (editSingleEntry.entryType === "ProjectActivityEntries") {
          newSingleEntry = { ProjectActivity: { ID: selectedProjectActivity.ID } }
          entityId = selectedProjectActivity.ID
        }
        
        if (editSingleEntry.entryType === "EmployeeEntries") {
          newSingleEntry = { Employee: { ID: selectedEmployee.ID } }
          entityId = selectedEmployee.ID
        }
        
        if (editSingleEntry.entryType === "EquipmentEntries") {
          newSingleEntry = { Equipment: { ID: selectedEquipment.ID } }
          entityId = selectedEquipment.ID
        }

        if (editSingleEntry.entry.ID !== entityId) {
          const errorOrResult = await editEntry(editSingleEntry.entryId, editSingleEntry.entryType, newSingleEntry)

          if (errorOrResult !== null) {
            if (!(errorOrResult instanceof Error)) {
              /*handleNewObjectInHistory(
                pensum,
                {...pensum, EmployeeEntries: [...pensum.EmployeeEntries, selectedEmployee]},
                "EmployeeEntry",
                errorOrResult,
                selectedEmployee.ID,
                1
              )*/

              showNotification("SUCCESSFUL_SAVE", "success")
              await getPensums(new Date(selectedFromDate), new Date(selectedToDate))
              this.handleClick(null, null, null, false)
            } else {
              await new Promise(resolve => setTimeout(() => resolve(), 2000));
              showNotification(`editEntryError: ${errorOrResult.message}`, "error")
            }
          }
        }
      }

      if (editComplexEntry && (editComplexEntry.entryType === "MixtureEntries" || editComplexEntry.entryType === "DriverEntries")) {
        /*const { mixtures, mixingPlants } = this.props
        const newMixture = mixtures.find(mix => mix.ID === selectedMixture)
        const newMixingPlant = mixingPlants.find(mix => mix.ID === selectedMixingPlant)*/
        let newComplexEntryForAction

        /*delete newMixingPlant.Orders
        delete newMixingPlant.Organisation*/

        if (editComplexEntry.entryType === "MixtureEntries") {
          newComplexEntryForAction = {
            Mixture: { ID: selectedMixture },
            MixtureAmount: selectedMixtureQuantity,
            Comment: mixtureComment
          }
        }
        if (editComplexEntry.entryType === "DriverEntries") {
          newComplexEntryForAction = {
            Driver: { ID: selectedDriver },
            Payload: selectedDriverPayload,
            StartTime: `PT${startTimeForDriver.split(":")[0]}H${startTimeForDriver.split(":")[1]}M`,
            MixingPlant: { ID: selectedDriverMixingPlant },
            Comment: driverComment,
          }
        }

        await editEntry(editComplexEntry.entryId, editComplexEntry.entryType, newComplexEntryForAction)
        this.handleClick(null, null, null, false)
      }

      return
    }

    /*
      Create Entries for Pensum
    */
    let pensumProActitvies
    if (selectedProjectActivity) {
      if (pensum.ProjectActivityEntries && pensum.ProjectActivityEntries.length > 0) {
        pensumProActitvies = pensum.ProjectActivityEntries
        pensumProActitvies.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const projectActivityErrorOrResult =
      selectedProjectActivity !== null
        ? await createProjectActivityEntry({
            ProjectActivity: { ID: selectedProjectActivity.ID },
            Pensum: { ID: pensum.ID },
            SortOrder: pensumProActitvies ? pensumProActitvies[pensumProActitvies.length - 1].SortOrder + 16 : 0
          })
        : null

    let employees
    if (selectedEmployee) {
      if (pensum.EmployeeEntries && pensum.EmployeeEntries.length > 0) {
        employees = pensum.EmployeeEntries
        employees.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const employeeErrorOrResult =
      selectedEmployee !== null
        ? await createEmployeeEntry({
            Employee: { ID: selectedEmployee.ID },
            Pensum: { ID: pensum.ID },
            SortOrder: employees ? employees[employees.length - 1].SortOrder + 16 : 0
          })
        : null

    let equipments
    if (selectedEquipment) {
      if (pensum.EquipmentEntries && pensum.EquipmentEntries.length > 0) {
        equipments = pensum.EquipmentEntries
        equipments.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const equipmentErrorOrResult =
      selectedEquipment !== null
        ? await createEquipmentEntry({
            Equipment: { ID: selectedEquipment.ID },
            Pensum: { ID: pensum.ID },
            SortOrder: equipments ? equipments[equipments.length - 1].SortOrder + 16 : 0
          })
        : null

    let mixtures
    if (selectedMixture && selectedMixtureQuantity && selectedMixtureQuantity !== ""
      && Number(selectedMixtureQuantity) >= 0 && selectedMixtureOrderNumber !== null) {
      if (pensum.MixtureEntries && pensum.MixtureEntries.length > 0) {
        mixtures = pensum.MixtureEntries
        mixtures.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const mixtureErrorOrResult =
      selectedMixture !== null
      && selectedMixtureQuantity !== null && selectedMixtureQuantity !== "" && Number(selectedMixtureQuantity) >= 0
      && selectedMixtureOrderNumber !== null ?
        await createMixtureEntry({
          Mixture: { ID: selectedMixture },
          MixtureAmount: selectedMixtureQuantity,
          Comment: mixtureComment,
          Pensum: { ID: pensum.ID },
          SortOrder: mixtures ? mixtures[mixtures.length - 1].SortOrder + 16 : 0
        })
      : null

    let drivers
    if (selectedDriver && selectedDriverPayload && selectedDriverPayload !== "" && Number(selectedDriverPayload) >= 0 && selectedDriverMixingPlant) {
      if (pensum.DriverEntries && pensum.DriverEntries.length > 0) {
        drivers = pensum.DriverEntries
        drivers.sort((a, b) => { return a.SortOrder - b.SortOrder })
      }
    }
    const driverErrorOrResult =
      selectedDriver !== null
      && selectedDriverPayload !== null && selectedDriverPayload !== "" && Number(selectedDriverPayload) >= 0
      && selectedDriverMixingPlant !== null ?
        await createDriverEntry({
          Driver: { ID: selectedDriver },
          MixingPlant: { ID: selectedDriverMixingPlant },
          Pensum: { ID: pensum.ID },
          Payload: selectedDriverPayload,
          StartTime: `PT${startTimeForDriver.split(":")[0]}H${startTimeForDriver.split(":")[1]}M`,
          Comment: driverComment,
          SortOrder: drivers ? drivers[drivers.length - 1].SortOrder + 16 : 0
        })
      : null

    if (selectedProjectActivity !== null && projectActivityErrorOrResult !== null) {
      if (!(projectActivityErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, ProjectActivityEntries: [...pensum.ProjectActivityEntries, selectedProjectActivity]},
          "ProjectActivityEntry",
          projectActivityErrorOrResult,
          selectedProjectActivity.ID,
          1
        )

        showNotification("SUCCESSFUL_SAVE_projectactivity", "success")
      } else {
        showNotification(`CreateProjectActivityEntryError: ${projectActivityErrorOrResult.message}`, "error")
      }
    }

    if (selectedEmployee !== null && employeeErrorOrResult !== null) {
      if (!(employeeErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, EmployeeEntries: [...pensum.EmployeeEntries, selectedEmployee]},
          "EmployeeEntry",
          employeeErrorOrResult,
          selectedEmployee.ID,
          1
        )

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE_employee", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateEmployeeEntryError: ${employeeErrorOrResult.message}`, "error")
      }
    }

    if (selectedEquipment !== null && equipmentErrorOrResult !== null) {
      if (!(equipmentErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, EquipmentEntries: [...pensum.EquipmentEntries, selectedEquipment]},
          "EquipmentEntry",
          equipmentErrorOrResult,
          selectedEquipment.ID,
          1
        )

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE_equipment", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateEquipmentEntryError: ${equipmentErrorOrResult.message}`, "error")
      }
    }

    if (selectedMixture !== null && mixtureErrorOrResult !== null) {
      if (!(mixtureErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, MixtureEntries: [...pensum.MixtureEntries, selectedMixture]},
          "MixtureEntry",
          mixtureErrorOrResult,
          selectedMixture,
          1
        )

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE_mixture", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateMixtureEntryError: ${mixtureErrorOrResult.message}`, "error")
      }
    }

    if (selectedDriver !== null && driverErrorOrResult !== null) {
      if (!(driverErrorOrResult instanceof Error)) {
        handleNewObjectInHistory(
          pensum,
          {...pensum, DriverEntries: [...pensum.DriverEntries, selectedDriver]},
          "DriverEntry",
          driverErrorOrResult,
          selectedDriver,
          1
        )
        await new Promise(resolve => setTimeout(() => resolve(), 1000));
        showNotification("SUCCESSFUL_SAVE", "success")
      } else {
        await new Promise(resolve => setTimeout(() => resolve(), 2000));
        showNotification(`CreateDriverEntryError: ${driverErrorOrResult.message}`, "error")
      }
    }

    if (
      selectedProjectActivity === null &&
      selectedEmployee === null &&
      selectedEquipment === null &&
      (selectedMixture === null || selectedMixtureOrderNumber === null || selectedMixtureQuantity === null || selectedMixtureQuantity === "") &&
      (selectedDriver === null || selectedDriverMixingPlant === null || selectedDriverPayload === null || selectedDriverPayload === "")
    ) {
      return
    } else {
      await getPensums(new Date(selectedFromDate), new Date(selectedToDate))
      this.handleClick(null, null, null, null, selectedMixingPlant)
    }
  }

  handleDelete = async () => {
    const { deletePensum, getPensums, task, selectedFromDate, selectedToDate } = this.props
    const { pensum } = task

    const errorOrResult = await deletePensum({ ID: pensum.ID })

    if (errorOrResult !== undefined && !(errorOrResult instanceof Error)) {
      const { handleNewObjectInHistory } = this.props
      
      handleNewObjectInHistory(pensum, null, null, null, null, null, true)

      await getPensums(new Date(selectedFromDate), new Date(selectedToDate))
      this.setState({ openDeletePensum: false })
    }
  }

  handleDeleteEntry = async () => {
    const { deleteEntry, getPensums, selectedFromDate, selectedToDate, task } = this.props
    const { deleteEntryObj } = this.state
    const { pensum } = task

    const errorOrResult = await deleteEntry(deleteEntryObj.entryTypeForAction, deleteEntryObj.entryId)

    if (errorOrResult !== undefined && !(errorOrResult instanceof Error)) {
      const { handleNewObjectInHistory } = this.props
      let newPensum

      switch (deleteEntryObj.entryType) {
        case "ProjectActivityEntry":
          newPensum = {...pensum, ProjectActivityEntries: [...pensum.ProjectActivityEntries, deleteEntryObj.entry]}
          break;
        case "EmployeeEntry":
          newPensum = {...pensum, EmployeeEntries: [...pensum.EmployeeEntries, deleteEntryObj.entry]}
          break;
        case "EquipmentEntry":
          newPensum = {...pensum, EquipmentEntries: [...pensum.EquipmentEntries, deleteEntryObj.entry]}
          break;
        case "MixtureEntry":
          newPensum = {...pensum, MixtureEntries: [...pensum.MixtureEntries, deleteEntryObj.entry]}
          break;
        case "DriverEntry":
          newPensum = {...pensum, DriverEntries: [...pensum.DriverEntries, deleteEntryObj.entry]}
          break;
      
        default:
          break;
      }

      handleNewObjectInHistory(
          pensum,
          newPensum,
          deleteEntryObj.entryType,
          deleteEntryObj.entry,
          deleteEntryObj.entityId,
          3
      )

      await getPensums(new Date(selectedFromDate), new Date(selectedToDate))
      this.handleClick(null, null, null, false)
      this.setState({ openDeleteEntry: false })
    }
  }

  handleMixingPlantChange = async event => {
    const { mixtureOrders } = this.props
    const { value } = event.target

    const filteredMixtureOrders = Array.from(mixtureOrders).filter(mix => mix.MixingPlant.ID === value)

    this.setState({
      selectedMixingPlant: value,
      filteredMixtureOrders: value !== null ? filteredMixtureOrders : [],
      selectedMixtureOrderNumber: null,
      selectedMixture: null,
      selectedMixtureQuantity: null,
    })
  }

  handleSelectedDriverChange = async event => {
    const { drivers } = this.props
    const { value } = event.target
    const selectedDriver = drivers.find(d => d.ID === Number(value))
    let defaultDriverPayload = null

    if (selectedDriver) {
      if (selectedDriver.DefaultPayload) {
        defaultDriverPayload = selectedDriver.DefaultPayload
      }
    }

    this.setState({
      selectedDriver: value,
      selectedDriverPayload: defaultDriverPayload
    })
  }

  handleMixtureOrderChange = async event => {
    const { mixtures } = this.props
    const { value } = event.target

    const filteredMixtures = Array.from(mixtures).filter(mix => mix.Order.OrderNumber === String(value))
    
    this.setState({
      selectedMixtureOrderNumber: value,
      filteredMixtures: value !== null ? filteredMixtures : [],
      selectedMixture: null
    })
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleHeaderPopupChange = () => {
    const { task: { pensum }, updatePensum } = this.props
    const { pensumComment, workDuration, totalTons } = this.state

    if (Number(totalTons) >= 0) {
      updatePensum({ [pensum.ID]: {
        WorkDuration: `PT${workDuration.split(":")[0]}H${workDuration.split(":")[1]}M`,
        TotalMixtureAmount: totalTons,
        Comment: pensumComment
      }})
    }

    this.setState({ openHeaderPopup: false })
  }

  handleDriverStartTimeChange = event => {
    const { value } = event.target
    this.setState({ selectedDriverStartTime: value })
  }

  handleWorkDurationChange = event => {
    const { value } = event.target
    this.setState({ workDuration: value })
  }

  handleMixtureCommentChange = (event) => {
    const { value } = event.target;
    this.setState({
      mixtureComment: value
    });
  }

  handleDriverCommentChange = (event) => {
    const { value } = event.target;
    this.setState({
      driverComment: value
    });
  }

  closeDeleteEntryPopup = () => {
    this.setState({ openDeleteEntry: false, deleteEntryObj: null })
  }

  closeHeaderPopup = () => {
    this.setState({ openHeaderPopup: false })
  }

  onMouseEnter = () => {
    this.setState({ isHovering: this.state.open || this.state.openForEditEntry ? false :  true });
  }

  onMouseLeave = () => {
    this.setState({ isHovering: false });
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePensumCopy = () => {
    const { task: { pensum }, handleCopy, showNotification, t } = this.props
    handleCopy(pensum)
    showNotification(t('pensum-copied'), "success")
  }

  render() {
    const {
      task,
      t,
      index,
      projectActivities,
      employee,
      drivers,
      equipment,
      mixingPlants,
      popupIsOpen,
      classes,
      view
    } = this.props
    const {
      open,
      openDelete,
      openHeaderPopup,
      openForEditEntry,
      anchorEl,
      openDeleteEntry,
      openAssignEntity,
      openAssignMixtureEntity,
      openAssignDriverEntity,
      isHovering,
      hoveringAssignEntity,
      hoveringAssignMixtureEntry,
      hoveringAssignDriverEntry,
      filteredMixtureOrders,
      filteredMixtures,
      selectedProjectActivity,
      selectedEmployee,
      selectedEquipment,
      selectedMixture,
      selectedMixtureQuantity,
      selectedMixingPlant,
      selectedMixtureOrderNumber,
      mixtureComment,
      selectedDriver,
      selectedDriverPayload,
      selectedDriverMixingPlant,
      selectedDriverStartTime,
      driverComment,
      totalTons,
      workDuration,
      pensumComment
    } = this.state
    const { pensum } = task
    const {
      EmployeeEntries,
      EquipmentEntries,
      MixtureEntries,
      DriverEntries,
      ProjectActivityEntries,
    } = pensum
    const saveIsDisabled =
      (selectedMixingPlant !== null || selectedMixture !== null)
        && (selectedMixture !== null && (
          selectedMixtureQuantity === null
          || selectedMixtureQuantity === ""
          || Number(selectedMixtureQuantity) < 0
          || String(selectedMixtureQuantity).charAt(0) === "-"
        ))
      || (selectedDriver !== null && (!selectedDriverMixingPlant || selectedDriverMixingPlant == "empty"
        || (selectedDriverPayload === null || selectedDriverPayload === "" || Number(selectedDriverPayload) < 0 || String(selectedDriverPayload).charAt(0) === "-"))
        || selectedDriverStartTime === "")
    const headerPopupSaveIsDisabled =
      totalTons === "" || totalTons === null || Number(totalTons) < 0 || String(totalTons).charAt(0) === "-" || workDuration === ""
    const firstAssignSectionIsEmpty =
      !selectedProjectActivity && !selectedEmployee && !selectedEquipment
    const showFirstAssignSection = !openForEditEntry || (openForEditEntry && (selectedProjectActivity || selectedEmployee || selectedEquipment))
    const showSecondAssignSection = !openForEditEntry || (openForEditEntry && (selectedMixingPlant || selectedMixtureOrderNumber || selectedMixture))
    const showThirdAssignSection = !openForEditEntry || (openForEditEntry && (selectedDriver || selectedDriverStartTime || selectedDriverMixingPlant))
    let DriverEntriesSum = 0
    let formattedStartTime

    if (selectedDriverStartTime && selectedDriverStartTime.split(":")[0].length === 1) {
      formattedStartTime = `0${selectedDriverStartTime}`
    }

    let defaultWorkduration = "00:00"
    if (pensum.WorkDuration) {
      if (!(pensum.WorkDuration.includes("H"))) {
        defaultWorkduration = pensum.WorkDuration.replace("PT","00:").replace("M","").replace("S","")
        if (defaultWorkduration.split(":")[1].length === 0) {
          defaultWorkduration = `${defaultWorkduration}00`
        }
        if (defaultWorkduration.split(":")[1].length === 1) {
          if (defaultWorkduration.split(":")[1] === "0") {
            defaultWorkduration = `${defaultWorkduration}0`
          } else {
            defaultWorkduration = `${defaultWorkduration.split(":")[0]}:0${defaultWorkduration.split(":")[1]}`
          }
        }
        if (defaultWorkduration.split(":")[0].length === 1) {
          defaultWorkduration = `0${defaultWorkduration}`
        }
      } else {
        defaultWorkduration = pensum.WorkDuration.replace("PT","").replace("H",":").replace("M","").replace("S","")
        if (defaultWorkduration.includes(":")) {
          if (defaultWorkduration.split(":")[1].length === 0) {
            defaultWorkduration = `${defaultWorkduration}00`
          }
          if (defaultWorkduration.split(":")[1].length === 1) {
            defaultWorkduration = `${defaultWorkduration}0`
          }
          if (defaultWorkduration.split(":")[0].length === 1) {
            defaultWorkduration = `0${defaultWorkduration}`
          }
        }
      }
    }

    let defaultTotalTons = 0
    if (pensum.TotalMixtureAmount) {
      defaultTotalTons = pensum.TotalMixtureAmount
    }

    if (DriverEntries) {
      DriverEntries.forEach(entry => {
        if (entry.Payload !== null && entry.Payload !== undefined) {
          DriverEntriesSum += Number(entry.Payload)
        }
      })
    }

    EmployeeEntries.sort((a, b) => {
      return a.SortOrder - b.SortOrder
    })

    EquipmentEntries.sort((a, b) => {
      return a.SortOrder - b.SortOrder
    })

    MixtureEntries.sort((a, b) => { 
      return a.SortOrder - b.SortOrder
    })

    ProjectActivityEntries.sort((a, b) => {
      return a.SortOrder - b.SortOrder
    })

    return (
      <Draggable
        draggableId={`pensum-${pensum.ID}`}
        index={index}
        isDragDisabled={open}
      >
        {(provided, snapshot) => (
          <Container
            className="Pensum"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            isDragDisabled={open}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            isHovering={isHovering}
            popupIsOpen={popupIsOpen}
          >
            <div className="pensum-wrapper" onClick={(e) => { e.preventDefault(); this.handleClick(); }}>
              <div
                className="project-section"
                style={{ backgroundColor: 'rgb(145, 145, 145)' }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.setState({
                    openHeaderPopup: !openHeaderPopup,
                    totalTons: defaultTotalTons,
                    workDuration: defaultWorkduration,
                    pensumComment: pensum.Comment
                  });
                }}
              >
                <div style={{ height: 17, float: 'left', paddingLeft: 5, textShadow: "0px 1px 3px rgba(0, 0, 0)" }}>
                  {pensum.Project.Name}
                  {/*<div style={{ display: 'inline-block', fontSize: '0.735rem', marginLeft: 4, textShadow: "0px 1px 2px rgba(0, 0, 0)" }}>
                    &nbsp;{isHovering && `${pensum.Project.Supervisor.FirstNames}`}
                  </div>*/}
                </div>
                <div className="pensum-options">
                  <OptionsIcon
                    onClick={(event) => { event.stopPropagation(); this.handleOpen(event); }}
                    style={{
                      height: 20,
                      width: 20,
                      color: "#fff",
                      borderRadius: 10
                    }}
                  />
                    <StyledMenu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={(event) => { event.stopPropagation(); this.handleClose() }}
                    >
                      <StyledMenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({ openDeletePensum: true })
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 25 }}>
                          <DeletePensumIcon fontSize="small" />
                        </ListItemIcon>
                        {t('delete')}
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          this.handlePensumCopy();
                          this.handleClose();
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 25 }}>
                          <CopyIcon fontSize="small" />
                        </ListItemIcon>
                        {t('copy')}
                      </StyledMenuItem>
                    </StyledMenu>
                </div>
                <div className="costCenter">
                  <div
                    className="costCenter-text"
                    style={{
                      border: `thin solid ${rgbToHex(pensum.Project.Supervisor.ColorRgb)}`,
                      backgroundColor: "rgb(100, 100, 100)",
                      textShadow: "0px 0px 6px rgba(0, 0, 0)"
                    }}>
                    {task.CostCenter}
                  </div>
                  {pensum.Project.HasPartialInvoice &&
                    <div className="invoice-icon">
                      <InvoiceIcon style={{
                        width: 17,
                        height: 17,
                        borderRadius: 5,
                        border: pensum.Project.HasPartialInvoice ? `thin solid ${!pensum.Project.FinalInvoiceTimeUtc ? "#fff" : "rgb(255,0,0)" }` : null,
                        paddingLeft: 1,
                        paddingRight: 2,
                        backgroundColor: pensum.Project.FinalInvoiceTimeUtc && 'rgb(255,0,0)'
                      }} />
                    </div>
                  }
                  {/*
                    isHovering && (pensum.Project.Name.length > 25 || pensum.Project.Supervisor.FirstNames.length > 8) ? null :
                      <div className="costCenter-icon">
                        <BankIcon  style={{ width: 15, color: "rgb(255, 255, 255)" }} />
                      </div>
                  */}
                </div>
                <div className="clear" />
              </div>
              <div className="details">
                {view.id === 3 &&
                <div className={ProjectActivityEntries.length > 0
                  || EmployeeEntries.length > 0
                  || EquipmentEntries.length > 0
                  || MixtureEntries.length > 0
                  || DriverEntries.length > 0
                  || Pensum.Comment ? "detail-section-bordered smaller" : "detail-section smaller"}
                >
                  <div className="icon">
                    <CustomerIcon style={{ width: 20 }}/>
                  </div>
                  <div className="detail">
                    {pensum.Project.Customer.Name}
                  </div>
                </div>}
                {pensum.Comment && <div className={
                  view.id === 1 && ProjectActivityEntries.length > 0
                  || (view.id !== 1 &&
                    (ProjectActivityEntries.length > 0
                    || EmployeeEntries.length > 0
                    || EquipmentEntries.length > 0
                    || MixtureEntries.length > 0
                    || DriverEntries.length > 0)) ? "detail-section-bordered" : "detail-section"
                }>
                  <div className="icon">
                    <CommentIcon style={{ width: 20 }}/>
                  </div>
                  <div className="detail">
                    {pensum.Comment.slice(0, 39)}{pensum.Comment.length > 39 ? <>...</> : ""}
                  </div>
                </div>}
                {ProjectActivityEntries.length > 0
                  && <div className={view.id === 3
                  && (
                    EmployeeEntries.length > 0
                    || EquipmentEntries.length > 0
                    || MixtureEntries.length > 0
                    || DriverEntries.length > 0)
                    ? "detail-section-bordered smaller"
                    : "detail-section smaller"}>
                  <div className="icon">
                    <ActivityIcon style={{ width: 20 }}/>
                  </div>
                  <div className={view.id === 3 ? "detail" : "single-detail"}>
                    {ProjectActivityEntries.map((entry, i) => {
                      const displayName = `${entry.ProjectActivity.Name} ${entry.ProjectActivity.Address !== null ? entry.ProjectActivity.Address : ""}`
                      return (
                        <div className={
                          clsx(
                            view.id !== 3 && i === 0 ? "subsided-detail" : null,
                            i !== 0 ? "separate-detail" : null
                          )}
                        >
                          {displayName.slice(0, 38)}{displayName.length > 38 && <>...</>}
                          <div style={{ display: "inline-block", float: "right", height: 15 }}>
                            <EditIcon
                              className="entry-button" style={{ width: 17 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleClick("activity", entry.ProjectActivity, entry);
                                this.setState({
                                  deleteEntryObj: {
                                    entryId: entry.ID,
                                    entryType: "ProjectActivityEntry",
                                    entryTypeForAction: "ProjectActivityEntries",
                                    entityId: entry.ProjectActivity.ID,
                                    entry
                                  }
                                })
                              }}
                            />
                            <DeleteIcon
                              className="entry-button" style={{ width: 17 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                  openDeleteEntry: true,
                                  deleteEntryObj: {
                                    entryId: entry.ID,
                                    entryType: "ProjectActivityEntry",
                                    entryTypeForAction: "ProjectActivityEntries",
                                    entityId: entry.ProjectActivity.ID,
                                    entry
                                  }
                                })
                              }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>}
                {view.id === 3 && EmployeeEntries.map((entry, i) => (
                  <div
                    key={i}
                    className={
                      EquipmentEntries.length > 0
                      || MixtureEntries.length > 0
                      || DriverEntries.length > 0
                      || i !== EmployeeEntries.length - 1
                      ? "detail-section-bordered smaller"
                      : "detail-section smaller"
                    }
                  >
                    <div className="icon">
                      <EmployeeIcon style={{ width: 20 }}/>
                    </div>
                    <div className="detail">
                      {entry.Employee && entry.Employee.LastName} {entry.Employee && entry.Employee.FirstNames}
                      <div style={{ display: "inline-block", float: "right", height: 15 }}>
                        <EditIcon
                          className="entry-button" style={{ width: 17 }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.handleClick("employee", entry.Employee, entry);
                            this.setState({
                              deleteEntryObj: {
                                entryId: entry.ID,
                                entryType: "EmployeeEntry",
                                entryTypeForAction: "EmployeeEntries",
                                entityId: entry.Employee.ID,
                                entry
                              }
                            })
                          }}
                        />
                        <DeleteIcon
                          className="entry-button" style={{ width: 17 }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.setState({
                              openDeleteEntry: true,
                              deleteEntryObj: {
                                entryId: entry.ID,
                                entryType: "EmployeeEntry",
                                entryTypeForAction: "EmployeeEntries",
                                entityId: entry.Employee.ID,
                                entry
                              }
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {view.id === 3 && EquipmentEntries.map((entry, i) => (
                  <div className={MixtureEntries.length > 0 || DriverEntries.length > 0 || i !== EquipmentEntries.length - 1 ? "detail-section-bordered" : "detail-section"} key={i}>
                    <div className="icon">
                      <EquipmentIcon style={{ width: 16 }}/>
                    </div>
                    <div className="detail">
                      {entry.Equipment && entry.Equipment.Name}
                      <div style={{ display: "inline-block", float: "right", height: 15 }}>
                        <EditIcon
                          className="entry-button" style={{ width: 17 }}
                          onClick={(event) => {
                            event.stopPropagation(); this.handleClick("equipment", entry.Equipment, entry);
                            this.setState({
                              deleteEntryObj: {
                                entryId: entry.ID,
                                entryType: "EquipmentEntry",
                                entryTypeForAction: "EquipmentEntries",
                                entityId: entry.Equipment.ID,
                                entry
                              }
                            })
                          }}
                        />
                        <DeleteIcon
                          className="entry-button" style={{ width: 17 }}
                          onClick={(event) => {
                            event.stopPropagation();
                            this.setState({
                              openDeleteEntry: true,
                              deleteEntryObj: {
                                entryId: entry.ID,
                                entryType: "EquipmentEntry",
                                entryTypeForAction: "EquipmentEntries",
                                entityId: entry.Equipment.ID,
                                entry
                              }
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                {view.id === 3 && MixtureEntries.length > 0 && <div className={DriverEntries.length > 0 ? "detail-section-bordered" : "detail-section"}>
                  <div className="icon">
                    <MixtureIcon style={{ width: 20 }}/>
                  </div>
                  <div className="detail">
                    {MixtureEntries.map((entry, i) => (
                      <>
                        <div style={{ display: "inline-block", minWidth: 25, marginRight: 2 }}>
                          {entry.MixtureAmount}
                        </div>
                        <div style={{ display: "inline-block", width: 100, marginRight: 3 }}>
                          {entry.Mixture && entry.Mixture.Name.slice(0,15)}
                        </div>
                        <div style={{ display: "inline-block", width: 39, marginRight: 4 }}>
                          {entry.Mixture && entry.Mixture.Order && entry.Mixture.Order.OrderNumber}
                        </div>
                        <div style={{ display: "inline-block", width: 64, marginRight: 1 }}>
                          {
                          entry.Mixture
                          && entry.Mixture.Order
                          && entry.Mixture.Order.MixingPlant
                          && entry.Mixture.Order.MixingPlant.Name
                          && (entry.Mixture.Order.MixingPlant.Name).split("[")[0].slice(0, 8)}
                            {entry.Mixture && entry.Mixture.Order.MixingPlant.Name.length > 8 && <>...</>}
                        </div>
                        <div style={{ display: "inline-block", float: "right", height: 15 }}>
                          <EditIcon
                            className="entry-button" style={{ width: 17 }}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.handleClick("mixture", entry);
                              this.setState({
                                deleteEntryObj: {
                                  entryId: entry.ID,
                                  entryType: "MixtureEntry",
                                  entryTypeForAction: "MixtureEntries",
                                  entityId: entry.Mixture.ID,
                                  entry
                                }
                              })
                            }}
                          />
                          <DeleteIcon
                            className="entry-button" style={{ width: 17 }}
                            onClick={(event) => {
                              event.stopPropagation();
                              this.setState({
                                openDeleteEntry: true,
                                deleteEntryObj: {
                                  entryId: entry.ID,
                                  entryType: "MixtureEntry",
                                  entryTypeForAction: "MixtureEntries",
                                  entityId: entry.Mixture.ID,
                                  entry
                                }
                              })
                            }}
                          />
                        </div>
                        <br/>
                        <div style={{ color: "rgb(75,75,75)", marginLeft: 27, marginBottom: 2 }}>{entry.Comment}</div>
                      </>
                    ))}
                  </div>
                </div>}
                {view.id === 3 && DriverEntries.length > 0 && <div className="detail-section">
                  <div className="icon">
                    <DriverIcon style={{ width: 20 }}/>
                  </div>
                  <div className="detail">
                    <div style={{ textDecoration: "underline" }}>
                      {DriverEntriesSum} / {pensum.TotalMixtureAmount}
                    </div>
                    {DriverEntries.sort((a, b) => {
                      if (a.StartTime < b.StartTime) {
                        return -1;
                      }
                      if ( a.StartTime > b.StartTime) {
                        return 1;
                      }
                      return a.SortOrder - b.SortOrder
                    }).map((entry, i) => {
                      const { MixingPlant, Driver: { Name, CompanyName }, Payload, StartTime } = entry
                      let formattedTime = StartTime !== null ? StartTime.replace("PT","").replace("H",":").replace("M","") : ""
                      if (StartTime && StartTime.includes("M") && formattedTime.split(":")[1].length === 1)
                        formattedTime = `${formattedTime.split(":")[0]}:0${formattedTime.split(":")[1]}`
                      if (StartTime && StartTime.includes("H") && !StartTime.includes("M") && formattedTime.split(":")[1] === "")
                        formattedTime = `${formattedTime.split(":")[0]}:00`
                      const displayName = `${Name} ${CompanyName}`
                      let displayNameFormatLengthLimit = 12
                      if (displayName.indexOf(" ") !== -1) {
                        if (
                          displayName.length >= displayNameFormatLengthLimit
                          && displayName.slice(displayName.indexOf(" "), displayName.length-1).indexOf(" ") !== -1
                        ) {
                          displayNameFormatLengthLimit = 11
                        }
                      }
                      return (
                        <div style={{ marginBottom: 2 }}>
                          <div style={{
                            display: "inline-block",
                            minWidth:
                              String(DriverEntriesSum).length === 3 ? 22 :
                              String(DriverEntriesSum).length === 2 ? 20 :
                              String(DriverEntriesSum).length === 1 && Number(DriverEntriesSum) !== 0 ? 16 : 10,
                            height: 15,
                            marginRight: 3
                          }}>
                            {Payload}
                          </div>
                          <div style={{ display: "inline-block", width: 90, marginRight: 2 }}>
                            {displayName.slice(0, displayNameFormatLengthLimit)}{displayName.length > displayNameFormatLengthLimit && <>...</>}
                          </div>
                          <div style={{ display: "inline-block", width: 30, marginRight: 2 }}>
                            {formattedTime}
                          </div>
                          {MixingPlant !== null && MixingPlant.Name.slice(0, 11)}{MixingPlant !== null && MixingPlant.Name.length > 11 && <>...</>}
                          <div style={{ display: "inline-block", float: "right", height: 15 }}>
                            <EditIcon
                              className="entry-button" style={{ width: 17 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.handleClick("driver", entry);
                                this.setState({
                                  deleteEntryObj: {
                                    entryId: entry.ID,
                                    entryType: "DriverEntry",
                                    entryTypeForAction: "DriverEntries",
                                    entityId: entry.Driver.ID,
                                    entry
                                  }
                                })
                              }}
                            />
                            <DeleteIcon
                              className="entry-button" style={{ width: 17 }}
                              onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                  openDeleteEntry: true,
                                  deleteEntryObj: {
                                    entryId: entry.ID,
                                    entryType: "DriverEntry",
                                    entryTypeForAction: "DriverEntries",
                                    entityId: entry.Driver.ID,
                                    entry
                                  }
                                })
                              }}
                            />
                            </div>
                          <br/>
                          <div style={{
                            color: "rgb(75,75,75)",
                            marginLeft:
                              String(DriverEntriesSum).length === 3 ? 24 :
                              String(DriverEntriesSum).length === 2 ? 22 :
                              String(DriverEntriesSum).length === 1 && Number(DriverEntriesSum) !== 0 ? 18 : 12
                          }}>{entry.Comment}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>}
              </div>
            </div>
            <Modal
              open={open}
              onClose={this.closePopup}
            >
              <div className={openForEditEntry ? "edit-popup single" : "edit-popup"}>
                {showFirstAssignSection && <div
                  onMouseEnter={() => this.setState({ hoveringAssignEntity: !openForEditEntry })}
                  onMouseLeave={() => this.setState({ hoveringAssignEntity: false })}
                  className="popup-menu"
                  onClick={() => this.setState({ openAssignEntity: !openForEditEntry ? !this.state.openAssignEntity : true })}
                  style={{
                    backgroundColor: hoveringAssignEntity ? '#182f63' : '#152957',
                    textShadow: hoveringAssignEntity ? "0px 2px 2px rgb(0, 0, 0)" : null,
                    marginBottom: openAssignEntity ? 17 : 0,
                    paddingTop: 2,
                    paddingBottom: 3,
                    paddingRight: 4,
                    paddingLeft: 14,
                    borderBottom: !openAssignEntity ? "thin solid #ededed" : null,
                    fontFamily: 'Open Sans',
                    color: hoveringAssignEntity ? '#fcfcfc' : 'white',
                    letterSpacing: 0.05,
                    cursor: !openForEditEntry ? 'pointer' : 'default'
                  }}
                >
                  <div style={{ fontSize: 17.5 }}>
                    {openForEditEntry ? <>{t('edit')}</> : <>{t('assign')}</>}
                    {!openForEditEntry && <div style={{ float: "right", paddingTop: 10 }}>
                      {openAssignEntity && hoveringAssignEntity
                      ? (<ArrowDropDownIcon size="small"/>)
                      : !openAssignEntity && !hoveringAssignEntity
                      ? (<ArrowDropDownIcon size="small"/>)
                      : (<ArrowDropUpIcon size="small" />)}
                    </div>}
                  </div>
                  <div style={{ paddingLeft: 11, fontSize: 12 }}>
                    {!openForEditEntry && `${t('project-activity')}, ${t('employee')}, ${t('equipment')}`}
                    {openForEditEntry && selectedProjectActivity && t('project-activity')}
                    {openForEditEntry && selectedEmployee && t('employee')}
                    {openForEditEntry && selectedEquipment && t('equipment')}
                  </div>
                </div>}
                {/* Assign ProjectActivity, Employee, Equipment */}
                {openAssignEntity ? (
                  <Grid container>
                    {!(openForEditEntry && !selectedProjectActivity) && <Grid item xs={12} className="selectGrid">
                      <div className="single-entity">
                        <div className="filter filter-select-wide-max">
                          <FormControl
                            className="select-container"
                            variant="outlined"
                          >
                            <InputLabel
                              id="selectedProjectActivity"
                              htmlFor="selectedProjectActivity"
                              ref={ref => {
                                  this.InputLabelRef = ref;
                              }}
                            >
                              {t('project-activity')}
                            </InputLabel>
                            <Select
                              labelId="selectedProjectActivity"
                              id="selectedProjectActivity"
                              className="wide-popup-select"
                              value={selectedProjectActivity || ""}
                              onChange={this.handleChange}
                              disabled={openForEditEntry && !selectedProjectActivity}
                              inputProps={{
                                  name: "selectedProjectActivity",
                                  id: "selectedProjectActivity",
                                  className: classes.input
                              }}
                              labelWidth={107}
                            >
                              <MenuItem value={null}>&nbsp;</MenuItem>
                              {projectActivities.map((activity, i) => {
                                if (activity.Project.ID === pensum.Project.ID) {
                                  return <MenuItem key={i} value={activity}>{activity.Name}</MenuItem>
                                } else {
                                  return null
                                }
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>}
                    {!(openForEditEntry && !selectedEmployee) && <Grid item xs={12} className="selectGrid">
                      <div className="single-entity">
                        <div className="filter filter-select-wide-max">
                          <FormControl
                            className="select-container"
                            variant="outlined"
                          >
                            <InputLabel
                              id="selectedEmployee"
                              htmlFor="selectedEmployee"
                              ref={ref => {
                                  this.InputLabelRef = ref;
                              }}
                            >
                              {t('employee')}
                            </InputLabel>
                            <Select
                              labelId="selectedEmployee"
                              id="selectedEmployee"
                              className="wide-popup-select"
                              value={selectedEmployee || ""}
                              onChange={this.handleChange}
                              disabled={openForEditEntry && !selectedEmployee}
                              inputProps={{
                                  name: "selectedEmployee",
                                  id: "selectedEmployee",
                                  className: classes.input
                              }}
                              labelWidth={75}
                            >
                              <MenuItem value={null}>&nbsp;</MenuItem>
                              {employee.map((employee, i) => {
                                return <MenuItem key={i} value={employee}>{employee.LastName} {employee.FirstNames}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>}
                    {!(openForEditEntry && !selectedEquipment) &&<Grid item xs={12} className="selectGrid" style={{ marginBottom: 3 }}>
                      <div className="single-entity">
                        <div className="filter filter-select-wide-max">
                          <FormControl
                            className="select-container"
                            variant="outlined"
                          >
                            <InputLabel
                              id="selectedEquipment"
                              htmlFor="selectedEquipment"
                              ref={ref => {
                                  this.InputLabelRef = ref;
                              }}
                            >
                              {t('equipment')}
                            </InputLabel>
                            <Select
                              labelId="selectedEquipment"
                              id="selectedEquipment"
                              className="wide-popup-select"
                              value={selectedEquipment || ""}
                              onChange={this.handleChange}
                              disabled={openForEditEntry && !selectedEquipment}
                              inputProps={{
                                  name: "selectedEquipment",
                                  id: "selectedEquipment",
                                  className: classes.input
                              }}
                              labelWidth={107}
                            >
                              <MenuItem value={null}>&nbsp;</MenuItem>
                              {equipment.map((equipment, i) => {
                                return <MenuItem key={i} value={equipment}>{equipment.Name}</MenuItem>
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </Grid>}
                  </Grid>
                ) : null}
                {showSecondAssignSection && <div
                  onMouseEnter={() => this.setState({ hoveringAssignMixtureEntry: !openForEditEntry })}
                  onMouseLeave={() => this.setState({ hoveringAssignMixtureEntry: false })}
                  className="popup-menu"
                  style={{
                    backgroundColor: hoveringAssignMixtureEntry ? '#182f63' : '#152957',
                    textShadow: hoveringAssignMixtureEntry ? "0px 2px 2px rgb(0, 0, 0)" : null,
                    marginTop: openAssignEntity ? 20 : 0,
                    marginBottom: openAssignMixtureEntity ? 5 : 0,
                    paddingTop: 2,
                    paddingBottom: 3,
                    paddingLeft: 14,
                    paddingRight: 4,
                    borderBottom: !openAssignMixtureEntity ? "thin solid #ededed" : null,
                    color: 'white',
                    fontFamily: 'Open Sans',
                    fontSize: 17,
                    letterSpacing: 0.05,
                    cursor: !openForEditEntry ? 'pointer' : 'default'
                  }}
                  onClick={() => this.setState({ openAssignMixtureEntity: !openForEditEntry ? !this.state.openAssignMixtureEntity : true })}
                >
                  <div style={{ fontSize: 17.5 }}>
                    {openForEditEntry ? <>{t('edit')}</> : <>{t('assign')}</>}
                    {!openForEditEntry && <div style={{ float: "right", paddingTop: 10 }}>
                      {openAssignMixtureEntity && hoveringAssignMixtureEntry
                      ? (<ArrowDropDownIcon size="small"/>)
                      : !openAssignMixtureEntity && !hoveringAssignMixtureEntry
                      ? (<ArrowDropDownIcon size="small"/>)
                      : (<ArrowDropUpIcon size="small" />)}
                    </div>}
                  </div>
                  <div style={{ paddingLeft: 11, fontSize: 12 }}>
                    {t('asphalt-mixture')}
                  </div>
                </div>}
                {/* Assign Mixture */}
                {openAssignMixtureEntity ? (
                  <Grid container spacing={2} style={{ maxWidth: 420, margin: 0 }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl className="formControl-wrapper">
                        <InputLabel>{t('asphalt-mixing-plant')}</InputLabel>
                        <Select
                          value={selectedMixingPlant || ''}
                          defaultValue={pensum.Project.DefaultMixingPlant !== null && !pensum.Project.DefaultMixingPlant.IsDeleted ? pensum.Project.DefaultMixingPlant.ID : selectedMixingPlant}
                          onChange={this.handleMixingPlantChange}
                          disabled={openForEditEntry && !selectedMixingPlant}
                          inputProps={{
                            name: "selectedMixingPlant",
                            id: "selectedMixingPlant"
                          }}
                          color="warning"
                        >
                          <MenuItem value={null}>&nbsp;</MenuItem>
                          {mixingPlants.map((mix, i) => {
                            return <MenuItem key={i} value={mix.ID}>{mix.Name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <InputLabel>{t('asphalt-mixture-order')}</InputLabel>
                        <Select
                          value={selectedMixtureOrderNumber || ''}
                          onChange={this.handleMixtureOrderChange}
                          inputProps={{
                            name: "selectedMixtureOrderNumber",
                            id: "selectedMixtureOrderNumber"
                          }}
                          disabled={selectedMixingPlant === null}
                        >
                          <MenuItem value={null}>&nbsp;</MenuItem>
                          {filteredMixtureOrders.map((mix, i) => {
                            return <MenuItem key={i} value={mix.OrderNumber}>{mix.OrderNumber}&nbsp;{mix.Description}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className="formControl-wrapper">
                        <InputLabel>{t('asphalt-mixture')}</InputLabel>
                        <Select
                          id="selectedMixture"
                          value={selectedMixture || ''}
                          onChange={this.handleChange}
                          inputProps={{
                            name: "selectedMixture",
                            id: "selectedMixture"
                          }}
                          disabled={(selectedMixingPlant !== null || selectedMixingPlant === null) && selectedMixtureOrderNumber === null}
                        >
                          <MenuItem value={null}>&nbsp;</MenuItem>
                          {filteredMixtures.map((mix, i) => {
                            return <MenuItem key={i} value={mix.ID}>{mix.Name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <InputLabel className="transformed-label">
                            {t('quantity')}
                        </InputLabel>
                        <NumberInput
                          id="selectedMixtureQuantity"
                          handleChange={this.handleChange}
                          value={selectedMixtureQuantity}
                          disabled={selectedMixture === null || selectedMixtureOrderNumber === null || selectedMixture === ""}
                          error={selectedMixture !== null && (selectedMixtureQuantity === "" || selectedMixtureQuantity === null
                            || Number(selectedMixtureQuantity) < 0 || String(selectedMixtureQuantity).charAt(0) === "-")}
                          inputProps={{ maxLength: 15, style: { textAlign: 'right' } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">to</InputAdornment>
                            )
                          }}
                          {...this.props}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextareaAutosize
                        style={{
                          marginLeft: 17,
                          minWidth: 367,
                          maxWidth: 367,
                          maxHeight: 120,
                          marginTop: 12,
                          fontWeight: "normal",
                          fontSize: 13,
                          fontFamily: 'Open Sans'
                        }}
                        defaultValue={mixtureComment}
                        id="mixtureComment"
                        aria-label={t('comment')}
                        rowsMin={3}
                        placeholder={`${t('comment')}...`}
                        onChange={this.handleMixtureCommentChange}
                      />
                    </Grid>
                  </Grid>
                ) : null}
                {showThirdAssignSection && <div
                  onMouseEnter={() => this.setState({ hoveringAssignDriverEntry: !openForEditEntry })}
                  onMouseLeave={() => this.setState({ hoveringAssignDriverEntry: false })}
                  className="popup-menu"
                  style={{
                    backgroundColor: hoveringAssignDriverEntry ? '#152957' : '#122247',
                    textShadow: hoveringAssignDriverEntry ? "0px 2px 2px rgb(0, 0, 0)" : null,
                    marginTop: openAssignMixtureEntity ? 15 : 0,
                    marginBottom: openAssignDriverEntity ? 5 : 0,
                    paddingTop: 2,
                    paddingBottom: 3,
                    paddingLeft: 14,
                    paddingRight: 4,
                    color: 'white',
                    fontFamily: 'Open Sans',
                    fontSize: 17,
                    letterSpacing: 0.05,
                    cursor: !openForEditEntry ? 'pointer' : 'default'
                  }}
                  onClick={() => this.setState({ openAssignDriverEntity: !openForEditEntry ? !this.state.openAssignDriverEntity : true })}
                >
                  <div style={{ fontSize: 17.5 }}>
                    {openForEditEntry ? <>{t('edit')}</> : <>{t('assign')}</>}
                    {!openForEditEntry && <div style={{ float: "right", paddingTop: 10 }}>
                      {openAssignDriverEntity && hoveringAssignDriverEntry
                        ? (<ArrowDropDownIcon size="small"/>)
                        : !openAssignDriverEntity && !hoveringAssignDriverEntry
                        ? (<ArrowDropDownIcon size="small"/>)
                        : (<ArrowDropUpIcon size="small" />)}
                    </div>}
                  </div>
                  <div style={{ paddingLeft: 11, fontSize: 12 }}>
                    {t('lkw-driver')}
                  </div>
                </div>}
                {/* Assign Driver */}
                {openAssignDriverEntity ? (
                  <Grid container spacing={2} style={{ maxWidth: 420, margin: 0 }}>
                    <Grid item xs={12} sm={6}>
                      <FormControl className="formControl-wrapper">
                        <InputLabel>{t('lkw-driver')}</InputLabel>
                        <Select
                          value={selectedDriver || ''}
                          onChange={this.handleSelectedDriverChange}
                          disabled={openForEditEntry && !selectedDriver}
                          inputProps={{
                            name: "selectedDriver",
                            id: "selectedDriver"
                          }}
                        >
                          <MenuItem value={null}>&nbsp;</MenuItem>
                          {Array.from(drivers)
                          .filter(driver => !driver.IsDeleted)
                          .sort((a, b) => {
                            var result = a.CompanyName.localeCompare(b.CompanyName, undefined, {sensitivity: 'base'});
                            if (result != 0)
                              return result;
                            var result = a.Name.localeCompare(b.Name, undefined, {sensitivity: 'base'});
                            if (result != 0)
                              return result;
                            return a.SortOrder - b.SortOrder
                          })
                          .map((driver, i) => {
                            return <MenuItem key={i} value={driver.ID}>{driver.CompanyName}&nbsp;{driver.Name}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="time"
                        type="time"
                        value={formattedStartTime}
                        defaultValue="06:30"
                        onChange={this.handleDriverStartTimeChange}
                        disabled={openForEditEntry && !selectedDriver}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          style: { textAlign: 'right' },
                          step: 300, // 5 min
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl className="formControl-wrapper">
                        <InputLabel>{t('asphalt-mixing-plant')}</InputLabel>
                        <Select
                          value={selectedDriverMixingPlant}
                          onChange={this.handleChange}
                          disabled={openForEditEntry && !selectedDriver}
                          inputProps={{
                            name: "selectedDriverMixingPlant",
                            id: "selectedDriverMixingPlant"
                          }}
                          native
                        >
                          <option value="empty">&nbsp;</option>
                          {mixingPlants.map((mix, i) => {
                            return <option key={i} value={mix.ID} selected={mix.ID === selectedDriverMixingPlant}>{mix.Name}</option>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl>
                        <InputLabel className="transformed-label">
                            {t('default-payload')}
                        </InputLabel>
                        <NumberInput
                          id="selectedDriverPayload"
                          value={selectedDriverPayload}
                          handleChange={this.handleChange}
                          value={selectedDriverPayload}
                          disabled={openForEditEntry && !selectedDriver}
                          error={selectedDriver !== null && (selectedDriverPayload === "" || selectedDriverPayload === null
                            || Number(selectedDriverPayload) < 0 || String(selectedDriverPayload).charAt(0) === "-")}
                          inputProps={{ maxLength: 15, style: { textAlign: 'right' } }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">to</InputAdornment>
                            )
                          }}
                          {...this.props}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextareaAutosize
                        style={{
                          marginLeft: 17,
                          minWidth: 367,
                          maxWidth: 367,
                          maxHeight: 76,
                          marginTop: 12,
                          fontWeight: "normal",
                          fontSize: 13,
                          fontFamily: 'Open Sans'
                        }}
                        defaultValue={driverComment}
                        id="driverComment"
                        aria-label={t('comment')}
                        rowsMin={3}
                        placeholder={`${t('comment')}...`}
                        onChange={this.handleDriverCommentChange}
                      />
                    </Grid>
                  </Grid>
                ) : null}
                <div className="buttonContainer"
                  style={{
                    borderTop: openAssignDriverEntity ? 'thin solid #d4d4d4' : null,
                    marginTop: openAssignDriverEntity ? '30px' : '10px'
                  }}
                >
                  <Tooltip title={t('save')}>
                    <Button
                      onClick={this.handleSave}
                      variant="text"
                      className="edit-popup-button"
                      color="primary"
                      size="medium"
                      startIcon={
                        <SaveIcon
                          style={{
                            marginLeft: 10,
                            color:
                              (
                                ((selectedMixtureQuantity || selectedDriverPayload) && !saveIsDisabled)
                                || (!saveIsDisabled && !firstAssignSectionIsEmpty)
                              ) && "#0fbb53"
                          }}
                        />}
                      disabled={saveIsDisabled}
                    />
                  </Tooltip>
                  <Tooltip title={t('close')}>
                    <Button
                      onClick={this.closePopup}
                      variant="text"
                      color="inherit"
                      size="medium"
                      className="edit-popup-button"
                      startIcon={<CloseIcon style={{ marginLeft: 10 }} />}
                    />
                  </Tooltip>
                  {openForEditEntry && <Tooltip title={t('delete')}>
                    <Button
                      onClick={() => this.setState({ openDelete: !this.state.openDelete })}
                      variant="text"
                      color="secondary"
                      size="medium"
                      className="edit-popup-button"
                      startIcon={<DeleteIcon style={{ marginLeft: 10 }} />}
                    />
                  </Tooltip>}
                  {
                    openDelete ? (
                      <div style={{ marginTop: 5 }}>
                        <Button
                          onClick={() => this.setState({ openDelete: !this.state.openDelete })}
                          style={{ marginRight: 2 }}
                          variant="contained"
                          color="primary"
                          size="small"
                        >
                          {t('cancel')}
                        </Button>
                        <Button
                          onClick={this.handleDeleteEntry}
                          variant="contained"
                          color="secondary"
                          size="small"
                        >
                          {t('delete')}
                        </Button>
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </Modal>
            <Modal open={this.state.openDeletePensum} onClose={(event) => { event.stopPropagation(); this.setState({ openDeletePensum: false, isHovering: false }); this.handleClose() }} className="modal">
              <div className="delete-popup">
                <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
                  <h2 style={{ fontFamily: 'Open Sans' }}>{t('deleteText')}</h2>
                </div>
                <div className="buttonContainer">
                  <Button
                    onClick={(event) => { event.stopPropagation(); this.setState({ openDeletePensum: false, isHovering: false }); this.handleClose() }}
                    style={{ marginRight: 7 }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    onClick={(event) => { event.stopPropagation(); this.handleDelete(); this.handleClose() }}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    {t('delete')}
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal open={openDeleteEntry} onClose={this.closeDeleteEntryPopup} className="modal">
              <div className="delete-popup">
                <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
                  <h2 style={{ fontFamily: 'Open Sans' }}>{t('deleteText')}</h2>
                </div>
                <div className="buttonContainer">
                  <Button
                    onClick={this.closeDeleteEntryPopup}
                    style={{ marginRight: 7 }}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    onClick={this.handleDeleteEntry}
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    {t('delete')}
                  </Button>
                </div>
              </div>
            </Modal>
            <Modal open={openHeaderPopup} onClose={this.closeHeaderPopup} className="modal">
              <div className="pensum-header-popup">
                <FormControl style={{ marginBottom: 20 }}>
                  <TextField
                    onChange={this.handleChange}
                    value={pensumComment}
                    id="pensumComment"
                    name="pensumComment"
                    label={t('comment')}
                    InputLabelProps={{
                      style: {
                        fontSize: 14
                      }
                    }}
                    inputProps={{ maxLength: 255 }}
                    multiline
                    rows={2}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel className="transformed-label">
                      {t('workDuration')}
                  </InputLabel>
                  <TextField
                    id="workDuration"
                    type="time"
                    value={workDuration}
                    defaultValue="00:00"
                    onChange={this.handleWorkDurationChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: { textAlign: 'right' },
                      step: 300, // 5 min
                    }}
                  />
                </FormControl>
                <FormControl style={{ marginTop: 20 }}>
                  <InputLabel className="transformed-label">
                      {t('totalTons')}
                  </InputLabel>
                  <NumberInput
                    id="totalTons"
                    handleChange={this.handleChange}
                    value={totalTons}
                    error={totalTons === "" || totalTons === null || Number(totalTons) < 0 || String(totalTons).charAt(0) === "-"}
                    inputProps={{ maxLength: 15, style: { textAlign: 'right' } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">to</InputAdornment>
                      )
                    }}
                    {...this.props}
                  />
                </FormControl>
                <div className="buttonContainer" style={{ marginTop: 35 }}>
                  <Button
                    onClick={this.handleHeaderPopupChange}
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={headerPopupSaveIsDisabled}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
            </Modal>
          </Container>
        )}
      </Draggable>
    )
  }
}

export default withStyles(styles)(Pensum);