import React, { Component } from "react";
import MixingPlantTable from "../MixingPlant/MixingPlantTable";
import MixtureOrderTable from "../MixingPlant/MixtureOrderTable";
import MixtureTable from "../MixingPlant/MixtureTable";
import Loader from '../../core/components/Loader/LinearProgress'

class MixingPlant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      mixingPlantRow: null,
      onMixingPlantRowAdd: props.createMixingPlant,
      onMixingPlantRowUpdate: props.updateMixingPlant,
      onMixingPlantRowDelete: props.deleteMixingPlant,
      mixtureOrderRow: null,
      onMixtureOrderRowAdd: props.createMixtureOrder,
      onMixtureOrderRowUpdate: props.updateMixtureOrder,
      onMixtureOrderRowDelete: props.deleteMixtureOrder,
      onMixtureRowAdd: props.createMixture,
      onMixtureRowUpdate: props.updateMixture,
      onMixtureRowDelete: props.deleteMixture,
      editable: true
    };

    this.onMixingPlantRowChanged = this.onMixingPlantRowChanged.bind(this)
    this.onMixtureOrderRowChanged = this.onMixtureOrderRowChanged.bind(this)
  }

  async onMixingPlantRowChanged(dataRow) {
    const { getMixtureOrder, getMixture } = this.props;
    if (dataRow != null) {
      await getMixtureOrder(null, null, "MixingPlant/ID eq " + dataRow.ID.toString());
    }
    else {
      await getMixtureOrder(null, null, "MixingPlant/ID eq 0");
    }

    await getMixture(null, null, "Order/ID eq 0");
    this.setState({ mixingPlantRow: dataRow, mixtureOrderRow: null });
  }

  async onMixtureOrderRowChanged(dataRow) {
    const { getMixture } = this.props;
    if (dataRow != null) {
      await getMixture(null, null, "Order/ID eq " + dataRow.ID.toString());
    }
    else
      await getMixture(null, null, "Order/ID eq 0");

    this.setState({ mixtureOrderRow: dataRow });
  }

  async componentDidMount() {
    const { getMixingPlant, getOrganisations, getMixtureOrder, getMixture } = this.props;
    await getMixingPlant();
    await getOrganisations();
    await getMixtureOrder(null, null, "ID eq 0");
    await getMixture(null, null, "ID eq 0");
    this.setState({ isMounted: true });

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }
    // if (tableHeader !== undefined) {
    //   tableHeader.style.marginBottom = "35px";
    // }
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  render() {
    const { isMounted, mixingPlantRow, mixtureOrderRow, ...rest } = this.state;
    const { mixingPlant, organisations, mixtureOrder, mixture, t, isFetching } = this.props;

    return (
      <div
        style={{
          width: "auto",
          marginTop: isFetching && !isMounted ? 1 : 30,
          justifyContent: "center"
        }}
      >
        {
          isMounted ? (
            <div className="rowC">
              <div style={{ padding: "5px", height: "100%", width: "45%", margin: "0 auto" }}>
                <MixingPlantTable
                  title={t('asphalt-mixing-plant')}
                  data={mixingPlant && mixingPlant}
                  organisations={organisations}
                  t={t}
                  onMixingPlantRowChanged={this.onMixingPlantRowChanged}
                  {...rest}
                />
              </div>
              <div style={{ padding: "5px", height: "100%", width: "35%", margin: "0 auto", pointerEvents: mixingPlantRow == null ? "none" : "inherit", opacity: mixingPlantRow == null ? "0.4" : "inherit" }}>
                <MixtureOrderTable
                  title={t('asphalt-mixture-order')}
                  data={mixtureOrder && mixtureOrder}
                  t={t}
                  mixingPlantRow={mixingPlantRow}
                  onMixtureOrderRowChanged={this.onMixtureOrderRowChanged}
                  {...rest}
                />
              </div>
              <div style={{ padding: "5px", height: "100%", width: "30%", margin: "0 auto", pointerEvents: mixtureOrderRow == null ? "none" : "inherit", opacity: mixtureOrderRow == null ? "0.4" : "inherit" }}>
                <MixtureTable
                  title={t('asphalt-mixture')}
                  data={mixture && mixture}
                  t={t}
                  mixtureOrderRow={mixtureOrderRow}
                  {...rest}
                />
              </div>
            </div>
          ) : <Loader />
        }
      </div>
    );
  }
}

export default MixingPlant;
