import odata from 'odata-client';
import {
    GET_CREWS_REQUEST,
    GET_CREWS_ERROR,
    GET_CREWS_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const getCrewsRequest = () => ({
    type: GET_CREWS_REQUEST,
});

export const getCrewsError = errorOrResults => ({
    type: GET_CREWS_ERROR,
    error: errorOrResults.message,
});

export const getCrewsSuccess = payload => ({
    type: GET_CREWS_SUCCESS,
    payload,
});

export default () => async (dispatch, getState) => {
    dispatch(getCrewsRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Crews);
    query.filter("not IsDeleted");

    const errorOrResult = await query.get({
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('get equipment api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : Message;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const payload = body.value;
                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(getCrewsError(errorOrResult, GET_CREWS_ERROR));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(getCrewsSuccess(errorOrResult));

    return errorOrResult;
};