import React from "react";
import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import './index.css';

class ProjectActivityTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.projectActivities ? props.projectActivities : '',
      editable: props.editable ? props.editable : '',
      projectRow: props.projectRow,
      newData: null,
      error: null
    };
  }

  calcCurrentSortOrder(data) {
    if (data === undefined || data === null || data.length === 0)
      return 0;

    const step = 16;
    var maxSortOrder = 0;
    data.forEach(d => { maxSortOrder = (d.SortOrder < maxSortOrder ? maxSortOrder : d.SortOrder); });
    return maxSortOrder + step;
  }

  render() {
    const { t } = this.props;

    const columns = [
      { title: t('tender-position'), field: "TenderPosition", filtering: false },
      {
        title: t('name'), field: "Name", editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-name" id="org-label">*</InputLabel>
            <TextField
              id="name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      { title: t('address'), field: "Address", filtering: false },
      { title: t('location-pluscode'), field: "LocationPluscode", filtering: false },
      //{ title: t('sequence'), field: "SortOrder", filtering: false, defaultSort: "asc"},
    ];

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={this.props.projectActivities}
        options={{
          pageSize: 12,
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true,
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          }
        }}
        editable={this.props.editable && {
          onRowAdd: newData =>
            new Promise(resolve => {
              this.setState({
                newData: newData
              });
              setTimeout(async () => {
                const errorOrResult = await this.props.onProjectActivityRowAdd({
                  ...newData,
                  SortOrder: this.calcCurrentSortOrder(this.state.data),
                  Project: {
                    ID: this.props.projectRow.ID
                  }
                });
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    newData.ID = errorOrResult.ID;
                    newData.SortOrder = errorOrResult.SortOrder;
                    data.push(newData);
                    return { ...prevState, data };
                  });
                }
                resolve();
              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                if (oldData) {
                  const errorOrResult = await this.props.onProjectActivityRowUpdate({
                    ...newData,
                    Project: {
                      ID: this.props.projectRow.ID
                    }
                  }, oldData.tableData);
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                  resolve();
                }
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                const errorOrResult = await this.props.onProjectActivityRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    if (data.indexOf(oldData) >= 0)
                      data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }
                resolve();
              }, 100);
            })
        }}
      />
    );
  }
}

export default ProjectActivityTable;