import odata from 'odata-client';
import {
    CREATE_ENTRY_REQUEST,
    CREATE_ENTRY_ERROR,
    CREATE_ENTRY_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const createEntryRequest = () => ({
    type: CREATE_ENTRY_REQUEST,
});

export const createEntryError = errorOrResults => ({
    type: CREATE_ENTRY_ERROR,
    error: errorOrResults.message,
});

export const createEntrySuccess = payload => ({
    type: CREATE_ENTRY_SUCCESS,
    payload,
});

export default (entityType, entry) => async (dispatch, getState) => {
    dispatch(createEntryRequest());

    const query = odata({service: getServiceUrl()}).resource(entityType);

    const errorOrResult = await query.post(entry, {
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('create entry api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                return payload;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(createEntryError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return errorOrResult;
    } else {
        /*dispatch(showNotification("SUCCESSFUL_SAVE", "success"))*/
    }

    dispatch(createEntrySuccess(errorOrResult));

    return errorOrResult;
};