import {
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_ERROR,
  GET_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_ERROR,
  DELETE_CUSTOMER_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_CUSTOMER_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_CUSTOMER_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_CUSTOMER_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_CUSTOMER_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_CUSTOMER_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_CUSTOMER_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_CUSTOMER_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_CUSTOMER_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_CUSTOMER_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_CUSTOMER_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_CUSTOMER_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_CUSTOMER_SUCCESS;
  reducers.entityName = "customer";

  return reducers.entity(reducers)(state, action);
};