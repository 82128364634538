import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Box from '@material-ui/core/Box';

import "./index.css";

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            oldPassword: null,
            newPassword: null,
            newPasswordConfirmation: null
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        const oldPassword = event.target.oldPassword && event.target.oldPassword.value
        const newPassword = event.target.newPassword.value
        const newPasswordConfirmation = event.target.newPasswordConfirmation.value
        const { environmentDisplayName } = this.props

        const { changePassword } = this.props
        const errorOrResult = await changePassword(
            {
                CurrentPassword: oldPassword,
                NewPassword: newPassword,
                NewPasswordConfirmation: newPasswordConfirmation
            },
            environmentDisplayName
        )
        if (!errorOrResult.error) {
            this.setState({ oldPassword: "", newPassword: "", newPasswordConfirmation: "" })
        }
    };

    handleChange = event => {
        const { id, value } = event.target
        this.setState({ [id]: value })
    }

    render() {
        const { t, isSetNewPassword, isPasswordExpired, passwordOptions, lastPasswordChange } = this.props
        const { oldPassword, newPassword, newPasswordConfirmation } = this.state

        const minimumLength = passwordOptions && passwordOptions.MinimumLength
        const oldPwError = Boolean(!isSetNewPassword) && (oldPassword === null && (newPassword || newPasswordConfirmation))

        return (
            <>
                <div className="changePasswordMessage">
                {
                    Boolean(isSetNewPassword) ?
                        Boolean(lastPasswordChange)
                        ? <>{t('passwordPolicyMessageSetNewPassword')}</>
                        : <>{t('passwordPolicyMessageSetFirstPassword').replace("&lt;br/&gt;", "\n")}</>
                    : Boolean(isPasswordExpired)
                        ? <>{t('passwordPolicyMessagePasswordExpired')}</>
                        : null
                }
                </div>
                <div className="changePasswordTitle">
                    <h2>{t('changePasswordTitle')}</h2>
                </div>
                <Container component="div" maxWidth="sm" style={{ marginTop: 20, marginBottom: 40, fontFamily: "Sans Serif" }}>
                    <form onSubmit={this.handleSubmit}>
                        {Boolean(!isSetNewPassword) && <TextField
                            type="password"
                            variant="outlined"
                            margin="normal"
                            value={oldPassword}
                            error={oldPwError}
                            onChange={this.handleChange}
                            required
                            fullWidth
                            id="oldPassword"
                            label={t('oldPassword')}
                            name="oldPassword"
                        />}
                        <TextField
                            type="password"
                            variant="outlined"
                            margin="normal"
                            onChange={this.handleChange}
                            value={newPassword}
                            error={
                                (newPassword && newPassword.length < minimumLength)
                                || newPassword === null && newPasswordConfirmation
                                || (Boolean(!isSetNewPassword) && oldPassword && newPassword === null)
                            }
                            inputProps={{ minlength: minimumLength }}
                            required
                            fullWidth
                            id="newPassword"
                            label={t('newPassword')}
                            name="newPassword"
                        />
                        <TextField
                            type="password"
                            variant="outlined"
                            margin="normal"
                            onChange={this.handleChange}
                            value={newPasswordConfirmation}
                            error={
                                (newPasswordConfirmation && newPasswordConfirmation.length < minimumLength)
                                || (newPassword && newPasswordConfirmation === null)
                                || (newPassword && newPasswordConfirmation && newPassword !== newPasswordConfirmation)
                                || (Boolean(!isSetNewPassword) && oldPassword && newPasswordConfirmation === null)
                            }
                            inputProps={{ minlength: minimumLength }}
                            required
                            fullWidth
                            name="newPasswordConfirmation"
                            label={t('newPasswordConfirmation')}
                            id="newPasswordConfirmation"
                        />
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="submitButton"
                            disabled={
                                (newPassword && newPasswordConfirmation === null)
                                || (oldPassword && (newPassword === null || (newPasswordConfirmation === null)))
                                || (newPasswordConfirmation && newPassword === null)
                                || (newPassword && newPassword.length < minimumLength)
                                || (newPasswordConfirmation && newPasswordConfirmation.length < minimumLength)
                                || (newPassword && newPasswordConfirmation && newPassword !== newPasswordConfirmation)
                                || (newPasswordConfirmation === "")
                            }
                        >
                            {t('save')}
                        </Button>
                    </form>
                    {passwordOptions && <div className="password-note">
                        <Box mt={5}>
                            {t('changePasswordNote', {
                                MinimumLength: minimumLength,
                                MinimumCharacterGroupCount: passwordOptions.MinimumCharacterGroupCount
                            })}
                        </Box>
                    </div>}
                </Container>
            </>
        )
    }
}
