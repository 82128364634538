import {
  GET_CREW_REQUEST,
  GET_CREW_ERROR,
  GET_CREW_SUCCESS,
  CREATE_CREW_REQUEST,
  CREATE_CREW_ERROR,
  CREATE_CREW_SUCCESS,
  UPDATE_CREW_REQUEST,
  UPDATE_CREW_ERROR,
  UPDATE_CREW_SUCCESS,
  DELETE_CREW_REQUEST,
  DELETE_CREW_ERROR,
  DELETE_CREW_SUCCESS,
  GET_CREWS_REQUEST,
  GET_CREWS_ERROR,
  GET_CREWS_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_CREW_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_CREW_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_CREW_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_CREW_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_CREW_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_CREW_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_CREW_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_CREW_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_CREW_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_CREW_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_CREW_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_CREW_SUCCESS;
  reducers.entityName = "crew";

  reducers.GET_ENTITIES_REQUEST = GET_CREWS_REQUEST;
  reducers.GET_ENTITIES_ERROR = GET_CREWS_ERROR;
  reducers.GET_ENTITIES_SUCCESS = GET_CREWS_SUCCESS;
  reducers.entitiesName = "crews";

  return reducers.entity(reducers)(state, action);
};
