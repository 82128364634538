import {
    GET_ORGANISATIONS_REQUEST,
    GET_ORGANISATIONS_ERROR,
    GET_ORGANISATIONS_SUCCESS,
  } from "../../actions/types";
  
  const initialState = {};
  
  export default (state = initialState, action) => {
    switch (action.type) {
  
      case GET_ORGANISATIONS_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };
  
      case GET_ORGANISATIONS_SUCCESS:
        return {
          ...state,
          organisations: action.payload,
          isFetching: false,
          error: undefined
        };
  
      case GET_ORGANISATIONS_ERROR:
        return {
          organisations: {},
          error: `${action.error}`
        };
  
      default:
        return state;
    }
  };
  