import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AppContainer from "../AppContainer";

import NotFound from "../../../pages/NotFound";
import Login from "../../../pages/Login";
import Welcome from "../../../pages/Welcome";
import ChangePassword from "../../../pages/ChangePassword";
import Planning from "../../../pages/Planning";
import Help from "../../../pages/Help";
import Equipment from "../../../pages/Equipment";
import Customer from "../../../pages/Customer";
import Employee from "../../../pages/Employee";
import Crew from "../../../pages/Crew";
import Projects from "../../../pages/Projects";
import SpecialDay from "../../../pages/SpecialDay";
import Driver from "../../../pages/Driver";
import MixingPlant from "../../../pages/MixingPlant";

const AppRouter = ({ isLoggedIn, isSetNewPassword, isPasswordExpired }) => (
  <Router>
    <AppContainer>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (!isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : isLoggedIn ? <Welcome /> : <Login />)}
        />
        <Route
          exact
          path="/changePassword"
          render={() => (isLoggedIn || (!isLoggedIn && (isSetNewPassword || isPasswordExpired)) ? <ChangePassword /> : <Login />)}
        />
        <Route exact path="/login" render={() => <Login />} />
        <Route
          path="/planning"
          render={() =>
            isLoggedIn ? <Planning /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/project"
          render={() =>
            isLoggedIn ? <Projects /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/help"
          render={() =>
            isLoggedIn ? <Help /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/equipment"
          render={() =>
            isLoggedIn ? <Equipment /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/customer"
          render={() =>
            isLoggedIn ? <Customer /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/employee"
          render={() =>
            isLoggedIn ? <Employee /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/crew"
          render={() =>
            isLoggedIn ? <Crew /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/specialdays"
          render={() =>
            isLoggedIn ? <SpecialDay /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/driver"
          render={() =>
            isLoggedIn ? <Driver /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route
          path="/mixingplant"
          render={() =>
            isLoggedIn ? <MixingPlant /> : !isLoggedIn && (isSetNewPassword || isPasswordExpired) ? <ChangePassword /> : <Login />
          }
        />
        <Route path="/" component={NotFound} />
      </Switch>
    </AppContainer>
  </Router>
);

export default AppRouter;
