import {
  EXPORT_PROJECT_OFFER_DOCUMENT_REQUEST,
  EXPORT_PROJECT_OFFER_DOCUMENT_ERROR,
  EXPORT_PROJECT_OFFER_DOCUMENT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader, downloadFileRequest, obj_to_map } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const exportProjectOfferDocumentRequest = () => ({
  type: EXPORT_PROJECT_OFFER_DOCUMENT_REQUEST
});

export const exportProjectOfferDocumentError = errorOrResult => ({
  type: EXPORT_PROJECT_OFFER_DOCUMENT_ERROR,
  error: errorOrResult.message
});

export const exportProjectOfferDocumentSuccess = payload => ({
  type: EXPORT_PROJECT_OFFER_DOCUMENT_SUCCESS,
  payload
});

export default (attachment) => async (dispatch, getState) => {

  dispatch(exportProjectOfferDocumentRequest());
  const url = getServiceUrl() + RESOURCES.Attachments + "(" + attachment.ID + ")" + "/" + RESOURCES.DownloadFile;

  const headers = {
    Authorization: `Bearer ${getState().user.token}`,
    ...getDefaultHeader()
  };

  const errorOrResult = await downloadFileRequest(url, obj_to_map(headers))
    .then(res => {
      const ok = res.status <= 299 && res.status >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        const errorKey = res.status === 401 ? `401: ${res.responseText}` : `${res.responseText}`;
        console.log('export project offer document api error', res.responseText);
        return new Error(errorKey);
      } else {
        return res.response;
      }
    }, res => {
      // error
      const errorKey = `Error: ${res.status}, ${res.statusText}`;
      console.log('export project offer document api error', res.responseText);
      return new Error(errorKey);
    }
    )
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(exportProjectOfferDocumentError(errorOrResult, EXPORT_PROJECT_OFFER_DOCUMENT_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_EXPORT_OFFER_DOCUMENT", "success"))
  }

  dispatch(exportProjectOfferDocumentSuccess(errorOrResult));

  return errorOrResult;
};