import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { changePassword } from '../../actions/user';
import notificationActions from "../../actions/notification";

import ChangePassword from "./ChangePassword";

const mapStateToProps = ({ user }) => ({
    isSetNewPassword: user.isSetNewPassword,
    isPasswordExpired: user.isPasswordExpired,
    passwordOptions: user.passwordOptions,
    lastPasswordChange: user.lastPasswordChange,
    environmentDisplayName: user.environmentDisplayName
  });

const mapDispatchToProps = {
  ...notificationActions,
  changePassword
};

const connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
export default withNamespaces(["common"])(connected);
