import {
    GET_PENSUMS_REQUEST,
    GET_PENSUMS_ERROR,
    GET_PENSUMS_SUCCESS,
    CREATE_PENSUM_OBJ,
    UPDATE_PENSUM_REQUEST,
    UPDATE_PENSUM_ERROR,
    UPDATE_PENSUM_SUCCESS,
    UPDATE_PENSUM_OBJ,
    DELETE_PENSUM_REQUEST,
    DELETE_PENSUM_ERROR,
    DELETE_PENSUM_SUCCESS
  } from "../../actions/types";
  
  const initialState = {};
  
  export default (state = initialState, action) => {
    switch (action.type) {
  
      case GET_PENSUMS_REQUEST:
        return {
          ...state,
          isFetching: !action.isSideEffect,
          error: undefined
        };
  
      case GET_PENSUMS_SUCCESS:
        return {
          ...state,
          pensums: action.payload,
          isFetching: false,
          error: undefined
        };
  
      case GET_PENSUMS_ERROR:
        return {
          pensums: [],
          error: `${action.error}`
        };

        case UPDATE_PENSUM_REQUEST:
          return {
            ...state,
            isFetching: true,
            error: undefined
          };
    
        case UPDATE_PENSUM_SUCCESS:
          return {
            ...state,
            /*pensums: Array.from(action.payload),*/
            isFetching: false,
            error: undefined
          };
    
        case UPDATE_PENSUM_ERROR:
          return {
            ...state,
            isFetching: false,
            error: `${action.error}`
          };
        
        case DELETE_PENSUM_REQUEST:
          return {
            ...state,
            isFetching: true,
            error: undefined
          };
    
        case DELETE_PENSUM_SUCCESS:
          return {
            ...state,
            pensums: state.pensums.filter(item => item.ID !== action.payload.ID),
            isFetching: false,
            error: undefined
          };
    
        case DELETE_PENSUM_ERROR:
          return {
            ...state,
            isFetching: false,
            error: `${action.error}`
          };

        case UPDATE_PENSUM_OBJ:
          return {
            ...state,
            pensums: state.pensums.map(obj => obj.ID === action.payload.ID ? Object.assign(obj, { ...action.payload }) : obj),
            isFetching: false,
            error: undefined
          }

        case CREATE_PENSUM_OBJ:
          return {
            ...state,
            pensums: [...state.pensums, action.payload],
            isFetching: false,
            error: undefined
          }
  
      default:
        return state;
    }
  };
  