import {
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_ERROR,
  GET_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_ERROR,
  CREATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_EMPLOYEE_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_EMPLOYEE_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_EMPLOYEE_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_EMPLOYEE_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_EMPLOYEE_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_EMPLOYEE_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_EMPLOYEE_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_EMPLOYEE_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_EMPLOYEE_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_EMPLOYEE_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_EMPLOYEE_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_EMPLOYEE_SUCCESS;
  reducers.entityName = "employee";

  return reducers.entity(reducers)(state, action);
};