import React, { Component } from 'react'
import Tab from "@material-ui/core/Tab";

export default class MenuItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isHovering: null
        }
    }

    render() {
        const {
            label,
            component,
            className,
            to,
            style,
            onClick,
            disabled
        } = this.props
        const { isHovering } = this.state

        return (
            <Tab
                label={label && label}
                component={label && component}
                className={className && className}
                onMouseEnter={() => this.setState({ isHovering: true })}
                onMouseLeave={() => this.setState({ isHovering: false })}
                to={to && to}
                disabled={disabled}
                style={isHovering ? style : { color: className === "menu-item" ? "rgb(0,0,0)" : "#18242e" }}
                onClick={onClick && onClick}
            />
        )
    }
}