import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import employeeActions from "../../actions/employee";
import organisationActions from "../../actions/organisation";
import crewActions from "../../actions/crew";
import Employee from "./Employee";
import settingsActions from "../../actions/settings";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ employee, organisation, crew, settings }) => ({
  employee: employee.employee,
  organisations: organisation.organisations,
  crews: crew.crew,
  settings: settings.settings,
  isFetching: isStateFetching([
    employee,
    organisation,
    crew,
    settings
  ])
});

const mapDispatchToProps = {
  ...employeeActions,
  ...organisationActions,
  ...crewActions,
  ...settingsActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Employee);
export default withNamespaces(["common"])(connected);
