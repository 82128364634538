import {
  GET_PROJECT_ACTIVITY_REQUEST,
  GET_PROJECT_ACTIVITY_ERROR,
  GET_PROJECT_ACTIVITY_SUCCESS,
  CREATE_PROJECT_ACTIVITY_REQUEST,
  CREATE_PROJECT_ACTIVITY_ERROR,
  CREATE_PROJECT_ACTIVITY_SUCCESS,
  UPDATE_PROJECT_ACTIVITY_REQUEST,
  UPDATE_PROJECT_ACTIVITY_ERROR,
  UPDATE_PROJECT_ACTIVITY_SUCCESS,
  DELETE_PROJECT_ACTIVITY_REQUEST,
  DELETE_PROJECT_ACTIVITY_ERROR,
  DELETE_PROJECT_ACTIVITY_SUCCESS,
  GET_PROJECT_ACTIVITIES_REQUEST,
  GET_PROJECT_ACTIVITIES_ERROR,
  GET_PROJECT_ACTIVITIES_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_PROJECT_ACTIVITY_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_PROJECT_ACTIVITY_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_PROJECT_ACTIVITY_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_PROJECT_ACTIVITY_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_PROJECT_ACTIVITY_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_PROJECT_ACTIVITY_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_PROJECT_ACTIVITY_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_PROJECT_ACTIVITY_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_PROJECT_ACTIVITY_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_PROJECT_ACTIVITY_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_PROJECT_ACTIVITY_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_PROJECT_ACTIVITY_SUCCESS;
  reducers.entityName = "projectActivity";

  reducers.GET_ENTITIES_REQUEST = GET_PROJECT_ACTIVITIES_REQUEST;
  reducers.GET_ENTITIES_ERROR = GET_PROJECT_ACTIVITIES_ERROR;
  reducers.GET_ENTITIES_SUCCESS = GET_PROJECT_ACTIVITIES_SUCCESS;
  reducers.entitiesName = "projectActivities";

  return reducers.entity(reducers)(state, action);
};
