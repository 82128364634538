import odata from 'odata-client';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import { showNotification } from '../notification';

var deleteEntity = {

  tokenExpired: context => () => ({
    type: context.TOKEN_EXPIRED
  }),
  deleteEntityRequest: context => () => ({
    type: context.DELETE_ENTITY_REQUEST
  }),
  deleteEntityError: context => errorOrResult => ({
    type: context.DELETE_ENTITY_ERROR,
    error: errorOrResult.message
  }),
  deleteEntitySuccess: context => payload => ({
    type: context.DELETE_ENTITY_SUCCESS,
    payload
  }),

  init: context => {
    context.DELETE_ENTITY_REQUEST = null;
    context.DELETE_ENTITY_ERROR = null;
    context.DELETE_ENTITY_SUCCESS = null;
    context.TOKEN_EXPIRED = null;
    context.entityName = null;
    context.entityLocation = null;
  },

  execute: context => entity => async (dispatch, getState) => {

    dispatch(context.deleteEntityRequest(context)());

    const query = odata({ service: getServiceUrl() }).resource(context.entityLocation, entity.ID);
    const errorOrResult = await query.delete({
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        ...getDefaultHeader()
      }
    })
      .then(res => {
        const ok = res.statusCode <= 299 && res.statusCode >= 200;
        if (res.statusCode === 500) {
          const errorKey = "GENERAL_SERVER_ERROR";
          return new Error(errorKey);
        } else if (!ok) {
          const response = res.toJSON()
          const error = JSON.parse(response.body).Error
          const errorKey = res.statusCode === 401 ? `401: ${error.Message}` : `${error.Message}`;
          console.log(`delete ${context.entityName} api error`, res.toJSON());
          return new Error(errorKey);
        } else {
          return entity;
        }
      }, res => {
        // error
        const errorKey = `Error: ${res.status}, ${res.statusText}`;
        console.log('export project tender document api error', res.responseText);
        return new Error(errorKey);
      })
      .catch((error) => {
        console.error('Error:', error);
        dispatch(showNotification("Request probably timed out.", "error"))
      });

    if (errorOrResult instanceof Error) {
      dispatch(context.deleteEntityError(context)(errorOrResult, context.DELETE_ENTITY_ERROR));
      if (errorOrResult.message.split(":")[0] === "401") {
        dispatch(context.tokenExpired(context)());
        dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
      }
      console.log("CODE ERROR: ", errorOrResult.message);
      if (errorOrResult.message.includes(","))
        dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
      else
        dispatch(showNotification(errorOrResult.message, "error"))
      return errorOrResult;
    } else {
      dispatch(showNotification("SUCCESSFUL_DELETE", "success"))
    }

    dispatch(context.deleteEntitySuccess(context)(errorOrResult));

    return errorOrResult;
  }
};

export default deleteEntity;