import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import customerActions from "../../actions/customer";
import organisationActions from "../../actions/organisation";
import Customer from "./Customer";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ customer, organisation }) => ({
  customer: customer.customer,
  organisations: organisation.organisations,
  isFetching: isStateFetching([
    customer,
    organisation
  ])
});

const mapDispatchToProps = {
  ...customerActions,
  ...organisationActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Customer);
export default withNamespaces(["common"])(connected);
