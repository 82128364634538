import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import Welcome from "./Welcome";

const mapStateToProps = ({ user }) => ({
    username: user.username
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps)(Welcome);
export default withNamespaces(["common"])(connected);
