import odata from 'odata-client';
import {
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const deleteEmployeeRequest = () => ({
  type: DELETE_EMPLOYEE_REQUEST
});

export const deleteEmployeeError = errorOrResults => ({
  type: DELETE_EMPLOYEE_ERROR,
  error: errorOrResults.message
});

export const deleteEmployeeSuccess = payload => ({
  type: DELETE_EMPLOYEE_SUCCESS,
  payload
});

export default employee => async (dispatch, getState) => {
  dispatch(deleteEmployeeRequest());

  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Employees, employee.ID);
  const errorOrResult = await query.delete({
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        console.log("delete employee api error", res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        return employee;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(deleteEmployeeError(errorOrResult, DELETE_EMPLOYEE_ERROR));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_DELETE", "success"))
  }

  dispatch(deleteEmployeeSuccess(errorOrResult));

  return errorOrResult;
};