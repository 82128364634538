import odata from 'odata-client';
import {
  DELETE_PROJECT_IMAGE_REQUEST,
  DELETE_PROJECT_IMAGE_ERROR,
  DELETE_PROJECT_IMAGE_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const deleteProjectImageRequest = () => ({
  type: DELETE_PROJECT_IMAGE_REQUEST
});

export const deleteProjectImageError = errorOrResult => ({
  type: DELETE_PROJECT_IMAGE_ERROR,
  error: errorOrResult.message
});

export const deleteProjectImageSuccess = payload => ({
  type: DELETE_PROJECT_IMAGE_SUCCESS,
  payload
});

export default (project, image) => async (dispatch, getState) => {

  dispatch(deleteProjectImageRequest());
  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Projects, project.ID).resource(RESOURCES.Images).resource(RESOURCES.Ref);
  var body = `{ "@odata.id": "${getServiceUrl()}${RESOURCES.Attachments}(${image.ID})" }`;
  const errorOrResult = await query.delete({
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      "Content-Length": body.length,
      "Content-Type": "application/json",
      ...getDefaultHeader()
    },
    body: body
  })
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (!ok) {
        console.log('delete project image api error', res.toJSON());
        const body = JSON.parse(res.body);
        const { Error: { Message } } = body
        const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
        return new Error(errorKey);
      } else {
        return project;
      }
    }, res => {// error
      var errorKey = null
      if (res.responseText && res.responseText !== null) {
        const error = JSON.parse(res.responseText).Error
        errorKey = res.statusCode === 401 ? `401: ${error.Message}` : `${error.Message}`;
      }
      else {
        errorKey = `Error: ${res.status}, ${res.statusText}`;
      }

      console.log('import project image api error', res.responseText);
      return new Error(errorKey);
    });

  if (errorOrResult instanceof Error) {
    dispatch(deleteProjectImageError(errorOrResult, DELETE_PROJECT_IMAGE_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
    }
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_DELETE_IMAGE", "success"))
  }

  dispatch(deleteProjectImageSuccess(errorOrResult));

  return errorOrResult;
};