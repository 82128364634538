import React, { Component } from 'react';
import styled from 'styled-components';

import moment from "moment"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { withStyles } from "@material-ui/core/styles";
import { enGB, enUS, de } from 'date-fns/locale';

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { InputLabel } from '@material-ui/core';

import { rgbToHex } from '../../../../utils';

import './index.css'

const Container = styled.div`
  display: flex;
  width: 1500px;
  margin-top: 15px;
  position: -webkit-sticky;
  position: sticky;
  left: 40px;
  z-index: 4;
`

const FilterContainer = styled.div`
  min-height: 95px;
  padding-top: 35px;
  border-radius: 5px;
  font-family: "Open Sans";
`

const FirstColumn = styled.div`
  border-radius: 8px;
  margin-top: 0px;
  width: 130px;
  display: flex;
  text-align: center;
  flex-direction: column;
`

const styles = theme => ({
    inputFocused: {
        
    }
});

class Filter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFromDate: null,
            selectedToDate: null,
            isMounted: false
        }
    }

    componentDidMount() {
        const { selectedFromDate, selectedToDate } = this.props
        this.setState({
            selectedFromDate,
            selectedToDate,
            isMounted: true
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
          nextProps.selectedFromDate !== this.state.selectedFromDate) {
          this.setState({ selectedFromDate: nextProps.selectedFromDate });
        }
        if (
            nextProps.selectedToDate !== this.state.selectedFromDate) {
            this.setState({ selectedToDate: nextProps.selectedToDate });
        }
    }

    selectLocale(language) {
        let languages = new Map();
        languages.set("de-DE", de);
        languages.set("en-GB", enGB);
        languages.set("en-US", enUS);
    
        let res = languages.get(language);
        return res != null ? res : languages["de-DE"];
    }

    render() {
        const {
            t,
            lng,
            classes,
            handleFromDateChange,
            handleToDateChange,
            handleCustomerChange,
            handleSupervisorChange,
            handleCrewChange,
            handleMixingPlantChange,
            handleViewChange,
            crews,
            customers,
            mixingPlants,
            customer,
            supervisor,
            selectedCrew,
            mixingPlant,
            employees,
            view,
            userCrewName
        } = this.props
        const { selectedFromDate, selectedToDate } = this.state
        const locale = this.selectLocale(lng);

        return (
            <Container>
                <FirstColumn />
                <FilterContainer>
                    <div className="filters">
                        <div className="filter">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={locale}>
                                <DatePicker
                                    format="yyyy-MM-dd"
                                    className="datePicker-planning"
                                    InputProps={{ className: classes.input, classes: { focused: classes.inputFocused } }}
                                    inputVariant="outlined"
                                    locale={locale}
                                    error={moment(selectedToDate).diff(selectedFromDate, "days") < 0}
                                    value={selectedFromDate}
                                    onChange={handleFromDateChange}
                                    okLabel={t('date-picker-label-ok')}
                                    cancelLabel={t('date-picker-label-cancel')}
                                    invalidDateMessage={t('date-picker-label-invalid-date-format')}
                                    />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="filter">
                            <MuiPickersUtilsProvider
                                utils={DateFnsUtils}
                                locale={locale}>
                                <DatePicker
                                    format="yyyy-MM-dd"
                                    className="datePicker-planning"
                                    InputProps={{ className: classes.input, classes: { focused: classes.inputFocused } }}
                                    inputVariant="outlined"
                                    locale={locale}
                                    error={moment(selectedToDate).diff(selectedFromDate, "days") < 0}
                                    value={selectedToDate}
                                    onChange={handleToDateChange}
                                    okLabel={t('date-picker-label-ok')}
                                    cancelLabel={t('date-picker-label-cancel')}
                                    invalidDateMessage={t('date-picker-label-invalid-date-format')}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="filter filter-select-wide-max">
                            <FormControl
                                className="select-container"
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel
                                    id="customer"
                                    htmlFor="customer"
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                >
                                    {t('customer')}
                                </InputLabel>
                                <Select
                                    labelId="customer"
                                    id="customer"
                                    onChange={handleCustomerChange}
                                    inputProps={{
                                        name: "customer",
                                        id: "customer",
                                        className: classes.input,
                                        classes: { focused: classes.inputFocused }
                                    }}
                                    labelWidth={97}
                                    native
                                >
                                    <option value="all">{t('all')}</option>
                                    {customers.map((filterCustomer, i) => {
                                        return <option key={i} value={filterCustomer.ID} selected={filterCustomer.ID === customer}>{filterCustomer.Name}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="filter filter-select-wide-max">
                            <FormControl
                                className="select-container"
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel
                                    id="supervisor"
                                    htmlFor="supervisor"
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                >
                                    {t('construction-supervisor')}
                                </InputLabel>
                                <Select
                                    labelId="supervisor"
                                    id="supervisor"
                                    className="wide-select-max"
                                    onChange={handleSupervisorChange}
                                    inputProps={{
                                        name: "supervisor",
                                        id: "supervisor",
                                        className: classes.input,
                                        classes: { focused: classes.inputFocused }
                                    }}
                                    labelWidth={67}
                                    native
                                >
                                    <option value="all">{t('all')}</option>
                                    {employees.map((employee, i) => {
                                        if (employee.IsSupervisor) {
                                            return (
                                                <option
                                                    key={i}
                                                    value={employee.ID}
                                                    selected={employee.ID === supervisor}
                                                    style={{
                                                        backgroundColor: `${rgbToHex(employee.ColorRgb)}4d`
                                                    }}
                                                >
                                                    {employee.LastName} {employee.FirstNames}
                                                </option>
                                            )
                                        }

                                        return null
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="filter-select">
                            <FormControl
                                className="select-container"
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel
                                    id="crew"
                                    htmlFor="crew"
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                >
                                    {t('crew')}
                                </InputLabel>
                                <Select
                                    labelId="crew"
                                    id="crew"
                                    onChange={handleCrewChange}
                                    inputProps={{
                                        name: "crew",
                                        id: "crew",
                                        className: classes.input,
                                        classes: { focused: classes.inputFocused }
                                    }}
                                    labelWidth={62}
                                    native
                                >
                                    <option value="all">{t('all')}</option>
                                    {crews.map((crew, i) => {
                                        return (
                                            <option
                                                key={i}
                                                value={crew.ID}
                                                selected={
                                                    (view.id === 2 && userCrewName !== null && crew.Name.toLowerCase() === userCrewName.toLowerCase())
                                                    || (view.id !== 2 && crew.ID === selectedCrew)
                                                }
                                            >
                                                {crew.Name}
                                            </option>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="filter filter-select-wide">
                            <FormControl
                                className="select-container"
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel
                                    id="mixingPlant"
                                    htmlFor="mixingPlant"
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                >
                                    {t('asphalt-mixing-plant')}
                                </InputLabel>
                                <Select
                                    labelId="mixingPlant"
                                    className="wide-select"
                                    id="mixingPlant"
                                    onChange={handleMixingPlantChange}
                                    inputProps={{
                                        name: "mixingPlant",
                                        id: "mixingPlant",
                                        className: classes.input,
                                        classes: { focused: classes.inputFocused }
                                    }}
                                    labelWidth={80}
                                    native
                                >
                                    <option value="all">{t('all')}</option>
                                    {mixingPlants.map((mix, i) => {
                                        return <option key={i} value={mix.ID} selected={mixingPlant === mix.ID}>{mix.Name}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="filter filter-select-wide">
                            <FormControl
                                className="select-container"
                                variant="outlined"
                                margin="normal"
                            >
                                <InputLabel
                                    id="view"
                                    htmlFor="view"
                                    ref={ref => {
                                        this.InputLabelRef = ref;
                                    }}
                                >
                                    {t('view')}
                                </InputLabel>
                                <Select
                                    labelId="view"
                                    id="view"
                                    onChange={handleViewChange}
                                    inputProps={{
                                        name: "view",
                                        id: "view",
                                        className: classes.input,
                                        classes: { focused: classes.inputFocused }
                                    }}
                                    labelWidth={58}
                                    native
                                >
                                    {[{id: 1, name: t('construction-supervisor')}, {id: 2, name: t('crew-leader')}, {id: 3, name: t('dispatcher')}].map((userView, i) => {
                                        return <option key={i} value={userView.id} selected={userView.id=== view.id}>{userView.name}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </FilterContainer>
            </Container>
        )
    }
}

export default withStyles(styles)(Filter);