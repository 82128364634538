import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import pensumActions from "../../../../actions/pensum";

import PensumsContainer from "./PensumsContainer";

const mapStateToProps = ({ project, settings }) => ({
  projects: project.projects,
  headerColor: settings.settings.HeaderBackgroundColor
});

const mapDispatchToProps = {
  ...pensumActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(PensumsContainer);
export default withNamespaces(['common'])(connected);
