import { SAVE_FILTER_SUCCESS } from '../types';

export const saveFilterSuccess = payload => ({
    type: SAVE_FILTER_SUCCESS,
    payload,
});

export default (filters) => async (dispatch) => {
    dispatch(saveFilterSuccess(filters));
};
