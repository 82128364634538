import getProject from "./getProject";
import createProject from "./createProject";
import updateProject from "./updateProject";
import deleteProject from "./deleteProject";
import getProjects from "./getProjects";
import createProjects from "./createProjects";
import deleteProjects from "./deleteProjects";

import importOfferDocument from "./importOfferDocument";
import exportOfferDocument from "./exportOfferDocument";
import deleteOfferDocument from "./deleteOfferDocument";

import importTenderDocument from "./importTenderDocument";
import exportTenderDocument from "./exportTenderDocument";
import deleteTenderDocument from "./deleteTenderDocument";

import importProjectDocument from "./importProjectDocument";
import deleteProjectDocument from "./deleteProjectDocument";

import importProjectImage from "./importProjectImage";
import deleteProjectImage from "./deleteProjectImage";

export {
    getProject, createProject, updateProject, deleteProject, getProjects, createProjects, deleteProjects,
    importOfferDocument, exportOfferDocument, deleteOfferDocument,
    importTenderDocument, exportTenderDocument, deleteTenderDocument,
    importProjectDocument, deleteProjectDocument, importProjectImage, deleteProjectImage
};

export default {
    getProject, createProject, updateProject, deleteProject, getProjects, createProjects, deleteProjects,
    importOfferDocument, exportOfferDocument, deleteOfferDocument,
    importTenderDocument, exportTenderDocument, deleteTenderDocument,
    importProjectDocument, deleteProjectDocument, importProjectImage, deleteProjectImage
};
