import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import Navigation from "./Navigation";

import notificationActions from "../../../actions/notification";

const mapStateToProps = ({ user, settings }) => ({
  headerColor: settings.settings.HeaderBackgroundColor,
  user: user
});

const mapDispatchToProps = {
  ...notificationActions
};

const connectedWithRouter = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
export default withNamespaces(['common'])(connectedWithRouter);
