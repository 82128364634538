import {
  GET_PROJECT_REQUEST,
  GET_PROJECT_ERROR,
  GET_PROJECT_SUCCESS,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_ERROR,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT_SUCCESS,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_ERROR,
  GET_PROJECTS_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_PROJECT_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_PROJECT_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_PROJECT_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_PROJECT_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_PROJECT_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_PROJECT_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_PROJECT_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_PROJECT_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_PROJECT_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_PROJECT_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_PROJECT_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_PROJECT_SUCCESS;
  reducers.entityName = "project";

  reducers.GET_ENTITIES_REQUEST = GET_PROJECTS_REQUEST;
  reducers.GET_ENTITIES_ERROR = GET_PROJECTS_ERROR;
  reducers.GET_ENTITIES_SUCCESS = GET_PROJECTS_SUCCESS;
  reducers.entitiesName = "projects";

  return reducers.entity(reducers)(state, action);
};