import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import specialDayActions from "../../actions/specialday";
import organisationActions from "../../actions/organisation";
import notificationActions from "../../actions/notification";
import SpecialDay from "./SpecialDay";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ specialday, organisation }) => ({
  specialday: specialday.specialday,
  organisations: organisation.organisations,
  isFetching: isStateFetching([
    specialday,
    organisation
  ])
});

const mapDispatchToProps = {
  ...specialDayActions,
  ...organisationActions,
  ...notificationActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(SpecialDay);
export default withNamespaces(["common"])(connected);
