import { UPDATE_PENSUM_OBJ } from '../types';

export const updatePensumObj = payload => ({
    type: UPDATE_PENSUM_OBJ,
    payload
});

export default (entity) => (dispatch) => {
    if (entity === null || entity === undefined) {
        return
    }
    dispatch(updatePensumObj(entity));
};