import { SAVE_FILTER_SUCCESS } from "../../actions/types";
  
const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        case SAVE_FILTER_SUCCESS:
            return {
                ...state,
                [action.payload.id]: action.payload.value
            };

        default:
            return state;
    }
};
  