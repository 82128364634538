import { connect } from "react-redux";

import AppRouter from "./AppRouter";

const mapStateToProps = ({ user }) => ({
  isLoggedIn: user.isLoggedIn,
  isSetNewPassword: user.isSetNewPassword,
  isPasswordExpired: user.isPasswordExpired,
  user: user
});

export default connect(mapStateToProps)(AppRouter);
