var reducers = {
  entityName: null,
  initialState: {},
  init: context => {
    context.GET_ENTITY_REQUEST = null;
    context.GET_ENTITIES_REQUEST = null;
    context.GET_ENTITY_SUCCESS = null;
    context.GET_ENTITIES_SUCCESS = null;
    context.GET_ENTITY_ERROR = null;
    context.GET_ENTITIES_ERROR = null;
    context.CREATE_ENTITY_REQUEST = null;
    context.CREATE_ENTITY_SUCCESS = null;
    context.CREATE_ENTITY_ERROR = null;
    context.CREATE_ENTITIES_REQUEST = null;
    context.CREATE_ENTITIES_SUCCESS = null;
    context.CREATE_ENTITIES_ERROR = null;
    context.UPDATE_ENTITY_REQUEST = null;
    context.UPDATE_ENTITY_SUCCESS = null;
    context.UPDATE_ENTITY_ERROR = null;
    context.DELETE_ENTITY_REQUEST = null;
    context.DELETE_ENTITY_SUCCESS = null;
    context.DELETE_ENTITY_ERROR = null;

    context.entityName = null;
    context.entitiesName = null;
  },
  entity: context => (state, action) => {
    switch (action.type) {
      case context.GET_ENTITY_REQUEST:
        return {
          ...state,
          [context.entityName]: {},
          isFetching: true,
          error: undefined
        };
      case context.GET_ENTITIES_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.GET_ENTITY_SUCCESS:
        return {
          ...state,
          [context.entityName]: action.payload,
          isFetching: false,
          error: undefined
        };

      case context.GET_ENTITIES_SUCCESS:
        return {
          ...state,
          [context.entitiesName]: Array.from(action.payload),
          isFetching: false,
          error: undefined
        };

      case context.GET_ENTITY_ERROR:
        return {
          [context.entityName]: {},
          error: `${action.error}`
        };

      case context.GET_ENTITIES_ERROR:
        return {
          [context.entitiesName]: {},
          error: `${action.error}`
        };

      case context.CREATE_ENTITY_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.CREATE_ENTITY_SUCCESS:
        return {
          ...state,
          [context.entityName]: [...state[context.entityName], action.payload],
          isFetching: false,
          error: undefined
        };

      case context.CREATE_ENTITY_ERROR:
        return {
          [context.entityName]: state[context.entityName],
          error: `${action.error}`
        };

      case context.CREATE_ENTITIES_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.CREATE_ENTITIES_SUCCESS:
        return {
          ...state,
          [context.entityName]: action.payload,
          isFetching: false,
          error: undefined
        };

      case context.UPDATE_ENTITY_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.UPDATE_ENTITY_SUCCESS:
        return {
          ...state,
          [context.entityName]: state[context.entityName].map(item => {
            if (item.ID === action.payload.ID) {
              return action.payload;
            }
            return item;
          }),
          isFetching: false,
          error: undefined
        };

      case context.UPDATE_ENTITY_ERROR:
        return {
          ...state,
          error: `${action.error}`
        };

      case context.DELETE_ENTITY_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };

      case context.DELETE_ENTITY_SUCCESS:
        return {
          ...state,
          [context.entityName]: state[context.entityName].filter(item => item.ID !== action.payload.ID),
          isFetching: false,
          error: undefined
        };

      case context.DELETE_ENTITY_ERROR:
        return {
          ...state,
          error: `${action.error}`
        };

      default:
        return state;
    }
  }
};

export default reducers;
