import {
  GET_CREW_REQUEST,
  GET_CREW_ERROR,
  GET_CREW_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_CREW_REQUEST;
  context.GET_ENTITY_ERROR = GET_CREW_ERROR;
  context.GET_ENTITY_SUCCESS = GET_CREW_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "crew";
  context.entityLocation = RESOURCES.Crews;
  context.selectQuery = "ID, Name, DefaultSortOrder, IsDeleted";
  context.expandQuery = "Organisation($select=ID, ShortName, Name)";
  context.orderByQuery = "DefaultSortOrder, Name, ID";

  return context.execute(context)(state, action);
};