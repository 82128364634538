import odata from 'odata-client';
import {
  CREATE_EMPLOYEE_REQUEST,
  CREATE_EMPLOYEE_ERROR,
  CREATE_EMPLOYEE_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const createEmployeeRequest = () => ({
  type: CREATE_EMPLOYEE_REQUEST
});

export const createEmployeeError = errorOrResult => ({
  type: CREATE_EMPLOYEE_ERROR,
  error: errorOrResult.message
});

export const createEmployeeSuccess = payload => ({
  type: CREATE_EMPLOYEE_SUCCESS,
  payload
});

export default employee => async (dispatch, getState) => {
  if (!(employee.LastName) || employee.LastName.trim() === "" || !(employee.FirstNames) || employee.FirstNames.trim() === "" || !(employee.Organisation.ID)) {
    dispatch(showNotification("Required fields must NOT be empty", "error"))
    return new Error()
  }

  dispatch(createEmployeeRequest());

  const odataObj = odata({ service: getServiceUrl() }).resource(RESOURCES.Employees);
  const errorOrResult = await odataObj.post(employee, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        const response = res.toJSON()
        const error = JSON.parse(response.body).Error
        const errorKey = res.statusCode === 401 ? `401: ${error.Details[0].Message}` : `${error.Details[0].Message}`;
        console.log('create employee api error', response);
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.body);
        const { "@odata.context": omit, ...payload } = body;
        payload.Organisation = employee.Organisation;
        return payload;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(createEmployeeError(errorOrResult, CREATE_EMPLOYEE_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_SAVE", "success"))
  }

  dispatch(createEmployeeSuccess(errorOrResult));

  return errorOrResult;
};