import {
  UPDATE_CREW_REQUEST,
  UPDATE_CREW_ERROR,
  UPDATE_CREW_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/updateEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (entity, entityTableData) => (state = initialState, action) => {

  context.init(context);

  context.UPDATE_ENTITY_REQUEST = UPDATE_CREW_REQUEST;
  context.UPDATE_ENTITY_ERROR = UPDATE_CREW_ERROR;
  context.UPDATE_ENTITY_SUCCESS = UPDATE_CREW_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "crew";
  context.entityLocation = RESOURCES.Crews;

  return context.execute(context)(entity, entityTableData)(state, action);
};