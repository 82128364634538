import {
  GET_DRIVER_REQUEST,
  GET_DRIVER_ERROR,
  GET_DRIVER_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_DRIVER_REQUEST;
  context.GET_ENTITY_ERROR = GET_DRIVER_ERROR;
  context.GET_ENTITY_SUCCESS = GET_DRIVER_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "driver";
  context.entityLocation = RESOURCES.Drivers;
  context.selectQuery = "ID, Name, CompanyName, DefaultPayload, IsDeleted";
  context.expandQuery = "Organisation($select=ID, ShortName, Name)";
  context.orderByQuery = "Name, ID";

  return context.execute(context)(state, action);
};
