import React, { Component } from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";

import MenuItem from "../../components/MenuItem";

import routes from "../../../config/routes";

import "./index.css";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuValue: "",
      secondaryMenuValue: "",
      showSecondaryMenu: false
    };

    this.updateNavMenu = this.updateNavMenu.bind(this);
  }

  updateNavMenu = () => {
    const { pathname } = this.props;
    let menuValueDefault = "";
    let secondaryMenuValueDefault = "";

    if (pathname === routes.PLANNING) {
      menuValueDefault = 0;
    } else if (pathname === routes.PROJECT) {
      menuValueDefault = 1;
    } else if (pathname === routes.MASTERDATA) {
      menuValueDefault = 2;
    } else if (pathname === routes.HELP) {
      menuValueDefault = 3;
    } else if (
      pathname !== routes.ROOT &&
      pathname !== routes.LOGIN &&
      Object.values(routes).indexOf(pathname) > -1
    ) {
      menuValueDefault = 2;

      if (pathname === "/employee") {
        secondaryMenuValueDefault = 0;
      } else if (pathname === "/crew") {
        secondaryMenuValueDefault = 1;
      } else if (pathname === "/specialdays") {
        secondaryMenuValueDefault = 2;
      } else if (pathname === "/customer") {
        secondaryMenuValueDefault = 3;
      } else if (pathname === "/equipment") {
        secondaryMenuValueDefault = 4;
      } else if (pathname === "/driver") {
        secondaryMenuValueDefault = 5;
      } else if (pathname === "/mixingplant") {
        secondaryMenuValueDefault = 6;
      }
    }
    this.setState({
      menuValue: menuValueDefault,
      secondaryMenuValue: secondaryMenuValueDefault
    });
  }

  componentDidMount() {
    const { user, showNotification, t } = this.props

    this.updateNavMenu();
    if (Boolean(user.isSetNewPassword)) {
      if (Boolean(user.lastPasswordChange)) {
        showNotification(t('passwordPolicyMessageSetNewPassword'), "error")
      } else {
        showNotification(t('passwordPolicyMessageSetFirstPassword').replace("&lt;br/&gt;", "\n"), "error")
      }
    } else if (Boolean(user.isPasswordExpired)) {
      showNotification(t('passwordPolicyMessagePasswordExpired'), "error")
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.updateNavMenu();
    }
  }

  toggleMenu = (index) => {
    this.setState({
      menuValue: index,
      showSecondaryMenu: false,
      secondaryMenuValue: ""
    });
  }

  render() {
    const { menuValue, showSecondaryMenu, secondaryMenuValue } = this.state;
    const { t } = this.props;
    const menuItemsColor = this.props.headerColor !== undefined ? this.props.headerColor : '#288288'

    return (
      <div className="nav-container">
        <div className="menu-root">
          <Paper className="menu-container">
            <Tabs
              value={menuValue}
              className="menu-list"
              indicatorColor="secondary"
              centered
            >
              <MenuItem
                label={t('planning')}
                component={Link}
                className="menu-item"
                to="/planning"
                style={{ color: menuItemsColor }}
                onClick={() => {
                  this.setState({
                    menuValue: 0,
                    showSecondaryMenu: false,
                    secondaryMenuValue: ""
                  });
                }}
              />
              <MenuItem
                label={t('projects')}
                component={Link}
                className="menu-item"
                to="/project"
                style={{ color: menuItemsColor }}
                onClick={() => {
                  this.setState({
                    menuValue: 1,
                    showSecondaryMenu: false,
                    secondaryMenuValue: ""
                  });
                }}
              />
              <MenuItem
                className="menu-item"
                label={t('masterdata')}
                style={{ color: menuItemsColor }}
                onClick={() => {
                  this.setState({
                    showSecondaryMenu: !this.state.showSecondaryMenu
                  });
                }}
              />
              {/*<MenuItem
                label={t('help')}
                component={Link}
                className="menu-item"
                to="/help"
                disabled={true}
                style={{ color: menuItemsColor }}
                onClick={() => {
                  this.setState({
                    menuValue: 3,
                    showSecondaryMenu: false,
                    secondaryMenuValue: ""
                  });
                }}
              />*/}
            </Tabs>
          </Paper>
        </div>
        {showSecondaryMenu && (
          <div className="submenu-root">
            <Paper className="menu-container" elevation={2}>
              <Tabs
                value={secondaryMenuValue}
                className="submenu-list"
                indicatorColor="primary"
                centered
              >
                <MenuItem
                  label={t('employee')}
                  component={Link}
                  className="submenu-item"
                  to="/employee"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 0
                    });
                  }}
                />
                <MenuItem
                  label={t('construction-crew')}
                  component={Link}
                  className="submenu-item"
                  to="/crew"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 1
                    });
                  }}
                />
                <MenuItem
                  label={t('special-days')}
                  component={Link}
                  className="submenu-item"
                  to="/specialdays"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 2
                    });
                  }}
                />
                <MenuItem
                  label={t('customer')}
                  component={Link}
                  className="submenu-item"
                  to="/customer"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 3
                    });
                  }}
                />
                <MenuItem
                  label={t('equipment')}
                  component={Link}
                  className="submenu-item"
                  to="/equipment"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 4
                    })
                  }}
                />
                <MenuItem
                  label={t('lkw-driver')}
                  component={Link}
                  className="submenu-item"
                  to="/driver"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 5
                    });
                  }}
                />
                <MenuItem
                  label={t('asphalt-mixing-plant')}
                  component={Link}
                  className="submenu-item"
                  to="/mixingplant"
                  style={{ color: "#f5003d" }}
                  subColor={menuItemsColor}
                  onClick={() => {
                    this.setState({
                      secondaryMenuValue: 6
                    });
                  }}
                />
              </Tabs>
            </Paper>
          </div>
        )}
      </div>
    );
  }
}

export default Navigation;
