import odata from 'odata-client';
import {
  UPDATE_EQUIPMENT_REQUEST,
  UPDATE_EQUIPMENT_ERROR,
  UPDATE_EQUIPMENT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const updateEquipmentRequest = () => ({
  type: UPDATE_EQUIPMENT_REQUEST,
});

export const updateEquipmentError = errorOrResults => ({
  type: UPDATE_EQUIPMENT_ERROR,
  error: errorOrResults.message,
});

export const updateEquipmentSuccess = payload => ({
  type: UPDATE_EQUIPMENT_SUCCESS,
  payload,
});


export default (equipment, equipmentTableData) => async (dispatch, getState) => {
  if (!(equipment.Name) || equipment.Name.trim() === "" || !(equipment.Organisation)) {
    dispatch(showNotification("Required fields must NOT be empty", "error"))
    return new Error()
  }

  dispatch(updateEquipmentRequest());

  const query = odata({ service: getServiceUrl() }).resource(RESOURCES.Equipment, equipment.ID);
  const errorOrResult = await query.patch(equipment, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;

      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        const response = res.toJSON()
        const error = JSON.parse(response.body).Error
        const errorKey = res.statusCode === 401 ? `401: ${error.Details[0].Message}` : `${error.Details[0].Message}`;
        console.log('update equipment api error', response);
        return new Error(errorKey);
      } else {
        const payload = equipment;
        payload.tableData = equipmentTableData;
        return payload;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })

  if (errorOrResult instanceof Error) {
    dispatch(updateEquipmentError(UPDATE_EQUIPMENT_ERROR, errorOrResult.message));
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    console.log("CODE ERROR: ", errorOrResult.message);
    dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_SAVE", "success"))
  }

  dispatch(updateEquipmentSuccess(errorOrResult));

  return errorOrResult;
};
