export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

export const SET_USER_LANGUAGE = "SET_USER_LANGUAGE";

export const SET_CHANGE_MESSAGE_TOKEN = "SET_CHANGE_MESSAGE_TOKEN";

export const SET_ENVIRONMENT_LOGO = "SET_ENVIRONMENT_LOGO";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const CHANGE_MESSAGE_REQUEST = "CHANGE_MESSAGE_REQUEST";
export const CHANGE_MESSAGE_ERROR = "CHANGE_MESSAGE_ERROR";
export const CHANGE_MESSAGE_SUCCESS = "CHANGE_MESSAGE_SUCCESS";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const GET_APP_SETTINGS_REQUEST = "GET_APP_SETTINGS_REQUEST";
export const GET_APP_SETTINGS_ERROR = "GET_APP_SETTINGS_ERROR";
export const GET_APP_SETTINGS_SUCCESS = "GET_APP_SETTINGS_SUCCESS";

export const SAVE_QUERY_PARAMS_REQUEST = "SAVE_QUERY_PARAMS_REQUEST";
export const SAVE_QUERY_PARAMS_ERROR = "SAVE_QUERY_PARAMS_ERROR";
export const SAVE_QUERY_PARAMS_SUCCESS = "SAVE_QUERY_PARAMS_SUCCESS";

export const SAVE_FILTER_REQUEST = "SAVE_FILTER_REQUEST";
export const SAVE_FILTER_ERROR = "SAVE_FILTER_ERROR";
export const SAVE_FILTER_SUCCESS = "SAVE_FILTER_SUCCESS";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_FAILURE";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";

export const GET_EQUIPMENT_REQUEST = "GET_EQUIPMENT_REQUEST";
export const GET_EQUIPMENT_ERROR = "GET_EQUIPMENT_ERROR";
export const GET_EQUIPMENT_SUCCESS = "GET_EQUIPMENT_SUCCESS";

export const DELETE_EQUIPMENT_REQUEST = "DELETE_EQUIPMENT_REQUEST";
export const DELETE_EQUIPMENT_ERROR = "DELETE_EQUIPMENT_ERROR";
export const DELETE_EQUIPMENT_SUCCESS = "DELETE_EQUIPMENT_SUCCESS";

export const UPDATE_EQUIPMENT_REQUEST = "UPDATE_EQUIPMENT_REQUEST";
export const UPDATE_EQUIPMENT_ERROR = "UPDATE_EQUIPMENT_ERROR";
export const UPDATE_EQUIPMENT_SUCCESS = "UPDATE_EQUIPMENT_SUCCESS";

export const CREATE_EQUIPMENT_REQUEST = "CREATE_EQUIPMENT_REQUEST";
export const CREATE_EQUIPMENT_ERROR = "CREATE_EQUIPMENT_ERROR";
export const CREATE_EQUIPMENT_SUCCESS = "CREATE_EQUIPMENT_SUCCESS";

export const GET_ORGANISATIONS_REQUEST = "GET_ORGANISATIONS_REQUEST";
export const GET_ORGANISATIONS_ERROR = "GET_ORGANISATIONS_ERROR";
export const GET_ORGANISATIONS_SUCCESS = "GET_ORGANISATIONS_SUCCESS";

export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";

export const DELETE_CUSTOMER_REQUEST = "DELETE_CUSTOMER_REQUEST";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";

export const CREATE_CUSTOMER_REQUEST = "CREATE_CUSTOMER_REQUEST";
export const CREATE_CUSTOMER_ERROR = "CREATE_CUSTOMER_ERROR";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";

export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";

export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_ERROR = "UPDATE_EMPLOYEE_ERROR";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";

export const CREATE_EMPLOYEE_REQUEST = "CREATE_EMPLOYEE_REQUEST";
export const CREATE_EMPLOYEE_ERROR = "CREATE_EMPLOYEE_ERROR";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";

export const GET_CREW_REQUEST = "GET_CREW_REQUEST";
export const GET_CREW_ERROR = "GET_CREW_ERROR";
export const GET_CREW_SUCCESS = "GET_CREW_SUCCESS";

export const DELETE_CREW_REQUEST = "DELETE_CREW_REQUEST";
export const DELETE_CREW_ERROR = "DELETE_CREW_ERROR";
export const DELETE_CREW_SUCCESS = "DELETE_CREW_SUCCESS";

export const UPDATE_CREW_REQUEST = "UPDATE_CREW_REQUEST";
export const UPDATE_CREW_ERROR = "UPDATE_CREW_ERROR";
export const UPDATE_CREW_SUCCESS = "UPDATE_CREW_SUCCESS";

export const CREATE_CREW_REQUEST = "CREATE_CREW_REQUEST";
export const CREATE_CREW_ERROR = "CREATE_CREW_ERROR";
export const CREATE_CREW_SUCCESS = "CREATE_CREW_SUCCESS";

export const GET_PENSUMS_REQUEST = "GET_PENSUMS_REQUEST";
export const GET_PENSUMS_ERROR = "GET_PENSUMS_ERROR";
export const GET_PENSUMS_SUCCESS = "GET_PENSUMS_SUCCESS";

export const GET_PENSUM_REQUEST = "GET_PENSUM_REQUEST";
export const GET_PENSUM_ERROR = "GET_PENSUM_ERROR";
export const GET_PENSUM_SUCCESS = "GET_PENSUM_SUCCESS";

export const UPDATE_PENSUM_REQUEST = "UPDATE_PENSUM_REQUEST";
export const UPDATE_PENSUM_ERROR = "UPDATE_PENSUM_ERROR";
export const UPDATE_PENSUM_SUCCESS = "UPDATE_PENSUM_SUCCESS";

export const UPDATE_PENSUM_OBJ = "UPDATE_PENSUM_OBJ";

export const CREATE_PENSUM_REQUEST = "CREATE_PENSUM_REQUEST";
export const CREATE_PENSUM_ERROR = "CREATE_PENSUM_ERROR";
export const CREATE_PENSUM_SUCCESS = "CREATE_PENSUM_SUCCESS";

export const CREATE_PENSUM_OBJ = "CREATE_PENSUM_OBJ";

export const DELETE_PENSUM_REQUEST = "DELETE_PENSUM_REQUEST";
export const DELETE_PENSUM_ERROR = "DELETE_PENSUM_ERROR";
export const DELETE_PENSUM_SUCCESS = "DELETE_PENSUM_SUCCESS";

export const GET_CREWS_REQUEST = "GET_CREWS_REQUEST";
export const GET_CREWS_ERROR = "GET_CREWS_ERROR";
export const GET_CREWS_SUCCESS = "GET_CREWS_SUCCESS";

export const GET_SPECIALDAY_REQUEST = "GET_SPECIALDAY_REQUEST";
export const GET_SPECIALDAY_ERROR = "GET_SPECIALDAY_ERROR";
export const GET_SPECIALDAY_SUCCESS = "GET_SPECIALDAY_SUCCESS";

export const DELETE_SPECIALDAY_REQUEST = "DELETE_SPECIALDAY_REQUEST";
export const DELETE_SPECIALDAY_ERROR = "DELETE_SPECIALDAY_ERROR";
export const DELETE_SPECIALDAY_SUCCESS = "DELETE_SPECIALDAY_SUCCESS";

export const UPDATE_SPECIALDAY_REQUEST = "UPDATE_SPECIALDAY_REQUEST";
export const UPDATE_SPECIALDAY_ERROR = "UPDATE_SPECIALDAY_ERROR";
export const UPDATE_SPECIALDAY_SUCCESS = "UPDATE_SPECIALDAY_SUCCESS";

export const CREATE_SPECIALDAY_REQUEST = "CREATE_SPECIALDAY_REQUEST";
export const CREATE_SPECIALDAY_ERROR = "CREATE_SPECIALDAY_ERROR";
export const CREATE_SPECIALDAY_SUCCESS = "CREATE_SPECIALDAY_SUCCESS";

export const GET_DRIVER_REQUEST = "GET_DRIVER_REQUEST";
export const GET_DRIVER_ERROR = "GET_DRIVER_ERROR";
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";

export const DELETE_DRIVER_REQUEST = "DELETE_DRIVER_REQUEST";
export const DELETE_DRIVER_ERROR = "DELETE_DRIVER_ERROR";
export const DELETE_DRIVER_SUCCESS = "DELETE_DRIVER_SUCCESS";

export const UPDATE_DRIVER_REQUEST = "UPDATE_DRIVER_REQUEST";
export const UPDATE_DRIVER_ERROR = "UPDATE_DRIVER_ERROR";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_SUCCESS";

export const CREATE_DRIVER_REQUEST = "CREATE_DRIVER_REQUEST";
export const CREATE_DRIVER_ERROR = "CREATE_DRIVER_ERROR";
export const CREATE_DRIVER_SUCCESS = "CREATE_DRIVER_SUCCESS";

export const GET_PROJECT_REQUEST = "GET_PROJECT_REQUEST";
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";

export const CREATE_PROJECTS_REQUEST = "CREATE_PROJECTS_REQUEST";
export const CREATE_PROJECTS_ERROR = "CREATE_PROJECTS_ERROR";
export const CREATE_PROJECTS_SUCCESS = "CREATE_PROJECTS_SUCCESS";

export const DELETE_PROJECTS_REQUEST = "DELETE_PROJECTS_REQUEST";
export const DELETE_PROJECTS_ERROR = "DELETE_PROJECTS_ERROR";
export const DELETE_PROJECTS_SUCCESS = "DELETE_PROJECTS_SUCCESS";

export const GET_PROJECT_ACTIVITY_ENTRIES_REQUEST = "GET_PROJECT_ACTIVITY_ENTRIES_REQUEST";
export const GET_PROJECT_ACTIVITY_ENTRIES_ERROR = "GET_PROJECT_ACTIVITY_ENTRIES_ERROR";
export const GET_PROJECT_ACTIVITY_ENTRIES_SUCCESS = "GET_PROJECT_ACTIVITY_ENTRIES_SUCCESS";

export const UPDATE_PROJECT_ACTIVITY_ENTRIES_REQUEST = "UPDATE_PROJECT_ACTIVITY_ENTRIES_REQUEST";
export const UPDATE_PROJECT_ACTIVITY_ENTRIES_ERROR = "UPDATE_PROJECT_ACTIVITY_ENTRIES_ERROR";
export const UPDATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS = "UPDATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS";

export const CREATE_PROJECT_ACTIVITY_ENTRIES_REQUEST = "CREATE_PROJECT_ACTIVITY_ENTRIES_REQUEST";
export const CREATE_PROJECT_ACTIVITY_ENTRIES_ERROR = "CREATE_PROJECT_ACTIVITY_ENTRIES_ERROR";
export const CREATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS = "CREATE_PROJECT_ACTIVITY_ENTRIES_SUCCESS";

export const DELETE_PROJECT_ACTIVITY_ENTRIES_REQUEST = "DELETE_PROJECT_ACTIVITY_ENTRIES_REQUEST";
export const DELETE_PROJECT_ACTIVITY_ENTRIES_ERROR = "DELETE_PROJECT_ACTIVITY_ENTRIES_ERROR";
export const DELETE_PROJECT_ACTIVITY_ENTRIES_SUCCESS = "DELETE_PROJECT_ACTIVITY_ENTRIES_SUCCESS";

export const GET_MIXTURE_ENTRIES_REQUEST = "GET_MIXTURE_ENTRIES_REQUEST";
export const GET_MIXTURE_ENTRIES_ERROR = "GET_MIXTURE_ENTRIES_ERROR";
export const GET_MIXTURE_ENTRIES_SUCCESS = "GET_MIXTURE_ENTRIES_SUCCESS";

export const CREATE_MIXTURE_ENTRIES_REQUEST = "CREATE_MIXTURE_ENTRIES_REQUEST";
export const CREATE_MIXTURE_ENTRIES_ERROR = "CREATE_MIXTURE_ENTRIES_ERROR";
export const CREATE_MIXTURE_ENTRIES_SUCCESS = "CREATE_MIXTURE_ENTRIES_SUCCESS";

export const CREATE_DRIVER_ENTRIES_REQUEST = "CREATE_DRIVER_ENTRIES_REQUEST";
export const CREATE_DRIVER_ENTRIES_ERROR = "CREATE_DRIVER_ENTRIES_ERROR";
export const CREATE_DRIVER_ENTRIES_SUCCESS = "CREATE_DRIVER_ENTRIES_SUCCESS";

export const GET_EQUIPMENT_ENTRIES_REQUEST = "GET_EQUIPMENT_ENTRIES_REQUEST";
export const GET_EQUIPMENT_ENTRIES_ERROR = "GET_EQUIPMENT_ENTRIES_ERROR";
export const GET_EQUIPMENT_ENTRIES_SUCCESS = "GET_EQUIPMENT_ENTRIES_SUCCESS";

export const UPDATE_EQUIPMENT_ENTRIES_REQUEST = "UPDATE_EQUIPMENT_ENTRIES_REQUEST";
export const UPDATE_EQUIPMENT_ENTRIES_ERROR = "UPDATE_EQUIPMENT_ENTRIES_ERROR";
export const UPDATE_EQUIPMENT_ENTRIES_SUCCESS = "UPDATE_EQUIPMENT_ENTRIES_SUCCESS";

export const CREATE_EQUIPMENT_ENTRIES_REQUEST = "CREATE_EQUIPMENT_ENTRIES_REQUEST";
export const CREATE_EQUIPMENT_ENTRIES_ERROR = "CREATE_EQUIPMENT_ENTRIES_ERROR";
export const CREATE_EQUIPMENT_ENTRIES_SUCCESS = "CREATE_EQUIPMENT_ENTRIES_SUCCESS";

export const DELETE_EQUIPMENT_ENTRIES_REQUEST = "DELETE_EQUIPMENT_ENTRIES_REQUEST";
export const DELETE_EQUIPMENT_ENTRIES_ERROR = "DELETE_EQUIPMENT_ENTRIES_ERROR";
export const DELETE_EQUIPMENT_ENTRIES_SUCCESS = "DELETE_EQUIPMENT_ENTRIES_SUCCESS";

export const GET_DRIVER_ENTRIES_REQUEST = "GET_DRIVER_ENTRIES_REQUEST";
export const GET_DRIVER_ENTRIES_ERROR = "GET_DRIVER_ENTRIES_ERROR";
export const GET_DRIVER_ENTRIES_SUCCESS = "GET_DRIVER_ENTRIES_SUCCESS";

export const GET_EMPLOYEE_ENTRIES_REQUEST = "GET_EMPLOYEE_ENTRIES_REQUEST";
export const GET_EMPLOYEE_ENTRIES_ERROR = "GET_EMPLOYEE_ENTRIES_ERROR";
export const GET_EMPLOYEE_ENTRIES_SUCCESS = "GET_EMPLOYEE_ENTRIES_SUCCESS";

export const CREATE_EMPLOYEE_ENTRIES_REQUEST = "CREATE_EMPLOYEE_ENTRIES_REQUEST";
export const CREATE_EMPLOYEE_ENTRIES_ERROR = "CREATE_EMPLOYEE_ENTRIES_ERROR";
export const CREATE_EMPLOYEE_ENTRIES_SUCCESS = "CREATE_EMPLOYEE_ENTRIES_SUCCESS";

export const UPDATE_EMPLOYEE_ENTRIES_REQUEST = "UPDATE_EMPLOYEE_ENTRIES_REQUEST";
export const UPDATE_EMPLOYEE_ENTRIES_ERROR = "UPDATE_EMPLOYEE_ENTRIES_ERROR";
export const UPDATE_EMPLOYEE_ENTRIES_SUCCESS = "UPDATE_EMPLOYEE_ENTRIES_SUCCESS";

export const DELETE_EMPLOYEE_ENTRIES_REQUEST = "DELETE_EMPLOYEE_ENTRIES_REQUEST";
export const DELETE_EMPLOYEE_ENTRIES_ERROR = "DELETE_EMPLOYEE_ENTRIES_ERROR";
export const DELETE_EMPLOYEE_ENTRIES_SUCCESS = "DELETE_EMPLOYEE_ENTRIES_SUCCESS";

export const DELETE_ENTRY_REQUEST = "DELETE_ENTRY_REQUEST";
export const DELETE_ENTRY_ERROR = "DELETE_ENTRY_ERROR";
export const DELETE_ENTRY_SUCCESS = "DELETE_ENTRY_SUCCESS";

export const EDIT_ENTRY_REQUEST = "EDIT_ENTRY_REQUEST";
export const EDIT_ENTRY_ERROR = "EDIT_ENTRY_ERROR";
export const EDIT_ENTRY_SUCCESS = "EDIT_ENTRY_SUCCESS";

export const CREATE_ENTRY_REQUEST = "CREATE_ENTRY_REQUEST";
export const CREATE_ENTRY_ERROR = "CREATE_ENTRY_ERROR";
export const CREATE_ENTRY_SUCCESS = "CREATE_ENTRY_SUCCESS";

export const GET_PROJECT_ACTIVITIES_REQUEST = "GET_PROJECT_ACTIVITIES_REQUEST";
export const GET_PROJECT_ACTIVITIES_ERROR = "GET_PROJECT_ACTIVITIES_ERROR";
export const GET_PROJECT_ACTIVITIES_SUCCESS = "GET_PROJECT_ACTIVITIES_SUCCESS";

export const GET_MIXINGPLANT_REQUEST = "GET_MIXINGPLANT_REQUEST";
export const GET_MIXINGPLANT_ERROR = "GET_MIXINGPLANT_ERROR";
export const GET_MIXINGPLANT_SUCCESS = "GET_MIXINGPLANT_SUCCESS";

export const DELETE_MIXINGPLANT_REQUEST = "DELETE_MIXINGPLANT_REQUEST";
export const DELETE_MIXINGPLANT_ERROR = "DELETE_MIXINGPLANT_ERROR";
export const DELETE_MIXINGPLANT_SUCCESS = "DELETE_MIXINGPLANT_SUCCESS";

export const UPDATE_MIXINGPLANT_REQUEST = "UPDATE_MIXINGPLANT_REQUEST";
export const UPDATE_MIXINGPLANT_ERROR = "UPDATE_MIXINGPLANT_ERROR";
export const UPDATE_MIXINGPLANT_SUCCESS = "UPDATE_MIXINGPLANT_SUCCESS";

export const CREATE_MIXINGPLANT_REQUEST = "CREATE_MIXINGPLANT_REQUEST";
export const CREATE_MIXINGPLANT_ERROR = "CREATE_MIXINGPLANT_ERROR";
export const CREATE_MIXINGPLANT_SUCCESS = "CREATE_MIXINGPLANT_SUCCESS";

export const GET_MIXTUREORDER_REQUEST = "GET_MIXTUREORDER_REQUEST";
export const GET_MIXTUREORDER_ERROR = "GET_MIXTUREORDER_ERROR";
export const GET_MIXTUREORDER_SUCCESS = "GET_MIXTUREORDER_SUCCESS";

export const DELETE_MIXTUREORDER_REQUEST = "DELETE_MIXTUREORDER_REQUEST";
export const DELETE_MIXTUREORDER_ERROR = "DELETE_MIXTUREORDER_ERROR";
export const DELETE_MIXTUREORDER_SUCCESS = "DELETE_MIXTUREORDER_SUCCESS";

export const UPDATE_MIXTUREORDER_REQUEST = "UPDATE_MIXTUREORDER_REQUEST";
export const UPDATE_MIXTUREORDER_ERROR = "UPDATE_MIXTUREORDER_ERROR";
export const UPDATE_MIXTUREORDER_SUCCESS = "UPDATE_MIXTUREORDER_SUCCESS";

export const CREATE_MIXTUREORDER_REQUEST = "CREATE_MIXTUREORDER_REQUEST";
export const CREATE_MIXTUREORDER_ERROR = "CREATE_MIXTUREORDER_ERROR";
export const CREATE_MIXTUREORDER_SUCCESS = "CREATE_MIXTUREORDER_SUCCESS";

export const GET_MIXTURE_REQUEST = "GET_MIXTURE_REQUEST";
export const GET_MIXTURE_ERROR = "GET_MIXTURE_ERROR";
export const GET_MIXTURE_SUCCESS = "GET_MIXTURE_SUCCESS";

export const DELETE_MIXTURE_REQUEST = "DELETE_MIXTURE_REQUEST";
export const DELETE_MIXTURE_ERROR = "DELETE_MIXTURE_ERROR";
export const DELETE_MIXTURE_SUCCESS = "DELETE_MIXTURE_SUCCESS";

export const UPDATE_MIXTURE_REQUEST = "UPDATE_MIXTURE_REQUEST";
export const UPDATE_MIXTURE_ERROR = "UPDATE_MIXTURE_ERROR";
export const UPDATE_MIXTURE_SUCCESS = "UPDATE_MIXTURE_SUCCESS";

export const CREATE_MIXTURE_REQUEST = "CREATE_MIXTURE_REQUEST";
export const CREATE_MIXTURE_ERROR = "CREATE_MIXTURE_ERROR";
export const CREATE_MIXTURE_SUCCESS = "CREATE_MIXTURE_SUCCESS";

export const GET_PROJECT_ACTIVITY_REQUEST = "GET_PROJECT_ACTIVITY_REQUEST";
export const GET_PROJECT_ACTIVITY_ERROR = "GET_PROJECT_ACTIVITY_ERROR";
export const GET_PROJECT_ACTIVITY_SUCCESS = "GET_PROJECT_ACTIVITY_SUCCESS";

export const DELETE_PROJECT_ACTIVITY_REQUEST = "DELETE_PROJECT_ACTIVITY_REQUEST";
export const DELETE_PROJECT_ACTIVITY_ERROR = "DELETE_PROJECT_ACTIVITY_ERROR";
export const DELETE_PROJECT_ACTIVITY_SUCCESS = "DELETE_PROJECT_ACTIVITY_SUCCESS";

export const UPDATE_PROJECT_ACTIVITY_REQUEST = "UPDATE_PROJECT_ACTIVITY_REQUEST";
export const UPDATE_PROJECT_ACTIVITY_ERROR = "UPDATE_PROJECT_ACTIVITY_ERROR";
export const UPDATE_PROJECT_ACTIVITY_SUCCESS = "UPDATE_PROJECT_ACTIVITY_SUCCESS";

export const CREATE_PROJECT_ACTIVITY_REQUEST = "CREATE_PROJECT_ACTIVITY_REQUEST";
export const CREATE_PROJECT_ACTIVITY_ERROR = "CREATE_PROJECT_ACTIVITY_ERROR";
export const CREATE_PROJECT_ACTIVITY_SUCCESS = "CREATE_PROJECT_ACTIVITY_SUCCESS";

export const IMPORT_PROJECT_OFFER_DOCUMENT_REQUEST = "IMPORT_PROJECT_OFFER_DOCUMENT_REQUEST";
export const IMPORT_PROJECT_OFFER_DOCUMENT_ERROR = "IMPORT_PROJECT_OFFER_DOCUMENT_ERROR";
export const IMPORT_PROJECT_OFFER_DOCUMENT_SUCCESS = "IMPORT_PROJECT_OFFER_DOCUMENT_SUCCESS";

export const EXPORT_PROJECT_OFFER_DOCUMENT_REQUEST = "EXPORT_PROJECT_OFFER_DOCUMENT_REQUEST";
export const EXPORT_PROJECT_OFFER_DOCUMENT_ERROR = "EXPORT_PROJECT_OFFER_DOCUMENT_ERROR";
export const EXPORT_PROJECT_OFFER_DOCUMENT_SUCCESS = "EXPORT_PROJECT_OFFER_DOCUMENT_SUCCESS";

export const DELETE_PROJECT_OFFER_DOCUMENT_REQUEST = "DELETE_PROJECT_OFFER_DOCUMENT_REQUEST";
export const DELETE_PROJECT_OFFER_DOCUMENT_ERROR = "DELETE_PROJECT_OFFER_DOCUMENT_ERROR";
export const DELETE_PROJECT_OFFER_DOCUMENT_SUCCESS = "DELETE_PROJECT_OFFER_DOCUMENT_SUCCESS";

export const IMPORT_PROJECT_TENDER_DOCUMENT_REQUEST = "IMPORT_PROJECT_TENDER_DOCUMENT_REQUEST";
export const IMPORT_PROJECT_TENDER_DOCUMENT_ERROR = "IMPORT_PROJECT_TENDER_DOCUMENT_ERROR";
export const IMPORT_PROJECT_TENDER_DOCUMENT_SUCCESS = "IMPORT_PROJECT_TENDER_DOCUMENT_SUCCESS";

export const EXPORT_PROJECT_TENDER_DOCUMENT_REQUEST = "EXPORT_PROJECT_TENDER_DOCUMENT_REQUEST";
export const EXPORT_PROJECT_TENDER_DOCUMENT_ERROR = "EXPORT_PROJECT_TENDER_DOCUMENT_ERROR";
export const EXPORT_PROJECT_TENDER_DOCUMENT_SUCCESS = "EXPORT_PROJECT_TENDER_DOCUMENT_SUCCESS";

export const DELETE_PROJECT_TENDER_DOCUMENT_REQUEST = "DELETE_PROJECT_TENDER_DOCUMENT_REQUEST";
export const DELETE_PROJECT_TENDER_DOCUMENT_ERROR = "DELETE_PROJECT_TENDER_DOCUMENT_ERROR";
export const DELETE_PROJECT_TENDER_DOCUMENT_SUCCESS = "DELETE_PROJECT_TENDER_DOCUMENT_SUCCESS";

export const IMPORT_PROJECT_DOCUMENT_REQUEST = "IMPORT_PROJECT_DOCUMENT_REQUEST";
export const IMPORT_PROJECT_DOCUMENT_ERROR = "IMPORT_PROJECT_DOCUMENT_ERROR";
export const IMPORT_PROJECT_DOCUMENT_SUCCESS = "IMPORT_PROJECT_DOCUMENT_SUCCESS";

export const DELETE_PROJECT_DOCUMENT_REQUEST = "DELETE_PROJECT_DOCUMENT_REQUEST";
export const DELETE_PROJECT_DOCUMENT_ERROR = "DELETE_PROJECT_DOCUMENT_ERROR";
export const DELETE_PROJECT_DOCUMENT_SUCCESS = "DELETE_PROJECT_DOCUMENT_SUCCESS";

export const IMPORT_PROJECT_IMAGE_REQUEST = "IMPORT_PROJECT_IMAGE_REQUEST";
export const IMPORT_PROJECT_IMAGE_ERROR = "IMPORT_PROJECT_IMAGE_ERROR";
export const IMPORT_PROJECT_IMAGE_SUCCESS = "IMPORT_PROJECT_IMAGE_SUCCESS";

export const DELETE_PROJECT_IMAGE_REQUEST = "DELETE_PROJECT_IMAGE_REQUEST";
export const DELETE_PROJECT_IMAGE_ERROR = "DELETE_PROJECT_IMAGE_ERROR";
export const DELETE_PROJECT_IMAGE_SUCCESS = "DELETE_PROJECT_IMAGE_SUCCESS";
