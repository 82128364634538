import React, { Component } from "react";
import ProjectsTable from "./ProjectsTable";
import ProjectEditForm from "./ProjectEditForm";
import Mode from "./ModeEnum";

import Loader from '../../core/components/Loader/LinearProgress'

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      mode: Mode.Uninit,
      dataRow: null,
      projects: null,
      onRowAdd: props.createProjects,
      onRowUpdate: props.updateProject,
      onRowDelete: props.deleteProjects,
      editable: true,
      onProjectActivityRowAdd: props.createProjectActivity,
      onProjectActivityRowUpdate: props.updateProjectActivity,
      onProjectActivityRowDelete: props.deleteProjectActivity,
    }

    this.onChangeMode = this.onChangeMode.bind(this)
    this.rowAddandler = this.rowAddHandler.bind(this)
    this.rowUpdateHandler = this.rowUpdateHandler.bind(this)
    this.rowDeleteHandler = this.rowDeleteHandler.bind(this)

    this.importOfferDocumentHandler = this.importOfferDocumentHandler.bind(this)
    this.exportOfferDocumentHandler = this.exportOfferDocumentHandler.bind(this)
    this.deleteOfferDocumentHandler = this.deleteOfferDocumentHandler.bind(this)

    this.importTenderDocumentHandler = this.importTenderDocumentHandler.bind(this)
    this.exportTenderDocumentHandler = this.exportTenderDocumentHandler.bind(this)
    this.deleteTenderDocumentHandler = this.deleteTenderDocumentHandler.bind(this)

    this.importDocumentHandler = this.importDocumentHandler.bind(this)
    this.deleteDocumentHandler = this.deleteDocumentHandler.bind(this)

    this.importImageHandler = this.importImageHandler.bind(this)
    this.deleteImageHandler = this.deleteImageHandler.bind(this)
  }

  async onChangeMode(newMode, newDataRow) {

    const { getProject, getProjectActivity } = this.props;

    if (newMode === Mode.Delete && newDataRow !== null) {
      this.rowDeleteHandler(newDataRow);
      newMode = Mode.Uninit;
      newDataRow = null;
    }

    if ((newMode === Mode.Uninit || newMode === Mode.Update) && (newDataRow !== undefined && newDataRow !== null)) {
      await getProject(null, null, "ID eq " + newDataRow.ID);
      await getProjectActivity(null, "Project/ID eq " + newDataRow.ID);

      const projects = this.state.projects;
      projects[projects.indexOf(newDataRow)] = this.props.project[0];
    }
    else {
      await getProject(null, null, "ID eq 0");
      await getProjectActivity(null, "Project/ID eq 0");
    }

    this.setState({ mode: newMode, dataRow: this.props.project !== null && this.props.project.length > 0 ? this.props.project[0] : null });
  }

  async componentDidMount() {

    const { getProjects, getProject, getOrganisations, getEmployee, getCustomer, getMixingPlant, getProjectActivity } = this.props;

    await getProjects();
    await getOrganisations();
    await getEmployee();
    await getCustomer();
    await getMixingPlant();
    await getProject(null, null, "ID eq 0");
    await getProjectActivity(null, "Project/ID eq 0");

    const { projects, project } = this.props

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }

    this.setState({ isMounted: true, projects: projects, dataRow: project });
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.Name === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.Name);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  rowAddHandler = newData =>
    new Promise(resolve => {
      this.setState({
        newData: newData
      });
      setTimeout(async () => {
        const errorOrResult = await this.state.onRowAdd({
          ...newData,
          Organisation: { ID: newData.Organisation.ID },
          Supervisor: { ID: newData.Supervisor.ID },
          Customer: { ID: newData.Customer.ID },
          DefaultMixingPlant: newData.DefaultMixingPlant !== undefined && newData.DefaultMixingPlant !== null ? { ID: newData.DefaultMixingPlant.ID } : null,
          DefaultMixtureOrder: newData.DefaultMixtureOrder !== undefined && newData.DefaultMixtureOrder !== null ? { ID: newData.DefaultMixtureOrder.ID } : null,
        });
        if (!(errorOrResult instanceof Error)) {
          this.setState(prevState => {
            const projects = [...prevState.projects];
            newData.ID = errorOrResult.ID;
            projects.push(newData);
            return { ...prevState, projects: projects, dataRow: newData };
          });
          await this.onChangeMode(Mode.Update, newData);
        }
        resolve(errorOrResult);
      }, 100);
    })

  rowDeleteHandler = oldData =>
    new Promise(resolve => {
      setTimeout(async () => {
        const errorOrResult = await this.state.onRowDelete(oldData);
        if (!(errorOrResult instanceof Error)) {
          this.setState(prevState => {
            const projects = [...prevState.projects];
            if (projects.indexOf(oldData) >= 0)
              projects.splice(projects.indexOf(oldData), 1);
            return { ...prevState, projects };
          });
        }
        resolve();
      }, 100);
    })

  rowUpdateHandler = (newData, oldData) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (oldData) {
          const errorOrResult = await this.state.onRowUpdate({
            ...newData,
            Organisation: { ID: newData.Organisation.ID },
            Supervisor: { ID: newData.Supervisor.ID },
            Customer: { ID: newData.Customer.ID },
            DefaultMixingPlant: newData.DefaultMixingPlant !== undefined && newData.DefaultMixingPlant !== null ? { ID: newData.DefaultMixingPlant.ID } : null,
            DefaultMixtureOrder: newData.DefaultMixtureOrder !== undefined && newData.DefaultMixtureOrder !== null ? { ID: newData.DefaultMixtureOrder.ID } : null,
            OfferDocument: newData.OfferDocument !== undefined && newData.OfferDocument !== null ? { ID: newData.OfferDocument.ID } : null,
            Images: null,
            Documents: null,
          });
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              projects[projects.indexOf(oldData)] = newData;
              return { ...prevState, projects: projects, dataRow: newData };
            });
            await this.onChangeMode(Mode.Update, newData);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  importOfferDocumentHandler = (project, file) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.importOfferDocument(project, file);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              project.OfferDocument = errorOrResult;
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  exportOfferDocumentHandler = (attachment) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (attachment) {
          const errorOrResult = await this.props.exportOfferDocument(attachment);
          resolve(errorOrResult);
        }
      }, 100);
    })

  deleteOfferDocumentHandler = (project) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.deleteOfferDocument(project);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              project.OfferDocument = null;
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  importTenderDocumentHandler = async (project, file) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.importTenderDocument(project, file);
          if (!(errorOrResult instanceof Error)) {
            await this.props.getProjectActivity(null, "Project/ID eq " + project.ID);
            this.setState(prevState => {
              const projects = [...prevState.projects];
              project.TenderDocument = errorOrResult;
              project.projectActivities = this.props.projectActivity;
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
          }

          resolve(errorOrResult);
        }
      }, 100);
    })

  exportTenderDocumentHandler = (attachment) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (attachment) {
          const errorOrResult = await this.props.exportTenderDocument(attachment);
          resolve(errorOrResult);
        }
      }, 100);
    })

  deleteTenderDocumentHandler = (project) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.deleteTenderDocument(project);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              project.TenderDocument = null;
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  importDocumentHandler = (project, files) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.importProjectDocument(project, files);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              if (project.Documents !== null)
                project.Documents.concat(errorOrResult.value);
              else
                project.Documents = errorOrResult.value;
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
            this.onChangeMode(Mode.Update, project);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  deleteDocumentHandler = (project, document) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.deleteProjectDocument(project, document);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              if (project.Documents.indexOf(document) >= 0)
                project.Documents.splice(project.Documents.indexOf(document), 1);
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
            this.onChangeMode(Mode.Update, project);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  importImageHandler = (project, files) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.importProjectImage(project, files);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              if (project.Images !== null)
                project.Images.concat(errorOrResult.value);
              else
                project.Images = errorOrResult.value;
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
            this.onChangeMode(Mode.Update, project);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  deleteImageHandler = (project, image) =>
    new Promise(resolve => {
      setTimeout(async () => {
        if (project) {
          const errorOrResult = await this.props.deleteProjectImage(project, image);
          if (!(errorOrResult instanceof Error)) {
            this.setState(prevState => {
              const projects = [...prevState.projects];
              if (project.Images.indexOf(image) >= 0)
                project.Images.splice(project.Images.indexOf(image), 1);
              projects[projects.indexOf(project)] = project;
              return { ...prevState, projects: projects, dataRow: project };
            });
            this.onChangeMode(Mode.Update, project);
          }
          resolve(errorOrResult);
        }
      }, 100);
    })

  render() {
    const { isMounted, mode, dataRow, projects, ...rest } = this.state;
    const { organisations, employee, customer, mixingPlant, lng, t, user, projectActivity, isFetching } = this.props;

    return (
      <div
        style={{
          margin: "0 auto",
          width: isFetching && !isMounted ? "100%" : "75%",
          marginTop: isFetching && !isMounted ? 0 : 30,
          pointerEvents: "inherit"
        }}
      >
        {
          isMounted ? (
            <div>
              {
                projects !== undefined && projects !== null && projects.length >= 0 && mode === Mode.Uninit ?
                  <ProjectsTable
                    title={t('projects')}
                    data={projects}
                    organisations={organisations}
                    onChangeMode={this.onChangeMode}
                    t={t}
                    {...rest}
                  />
                  :
                  <ProjectEditForm
                    {...rest}
                    title={t('projects')}
                    dataRow={dataRow}
                    projectActivities={projectActivity}
                    mode={mode}
                    organisations={organisations}
                    employees={employee}
                    customers={customer}
                    mixingPlants={mixingPlant}
                    onChangeMode={this.onChangeMode}
                    rowAddHandler={this.rowAddHandler}
                    rowUpdateHandler={this.rowUpdateHandler}
                    //rowDeleteHandler={this.rowDeleteHandler}
                    importOfferDocumentHandler={this.importOfferDocumentHandler}
                    exportOfferDocumentHandler={this.exportOfferDocumentHandler}
                    deleteOfferDocumentHandler={this.deleteOfferDocumentHandler}
                    importTenderDocumentHandler={this.importTenderDocumentHandler}
                    exportTenderDocumentHandler={this.exportTenderDocumentHandler}
                    deleteTenderDocumentHandler={this.deleteTenderDocumentHandler}
                    importDocumentHandler={this.importDocumentHandler}
                    deleteDocumentHandler={this.deleteDocumentHandler}
                    importImageHandler={this.importImageHandler}
                    deleteImageHandler={this.deleteImageHandler}
                    t={t}
                    lng={lng}
                    user={user}
                  />
              }
            </div>
          ) : <Loader />
        }

      </div>
    );
  }
}

export default Projects;