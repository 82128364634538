import { IAS_DEV_SERVICE_URI, IAS_DEV_URI } from './config/constants';
import { store } from './App';
import packageJSON from '../package.json';

const isDevelopmentSystem = () => window.location.hostname === "localhost" && (window.location.port == 3000 || window.location.port == 3001);

export const getServiceUrl = () => {
  if (isDevelopmentSystem()) {
    return IAS_DEV_SERVICE_URI;
  } else {
    return `${window.location.origin}/odata/`;
  }
}

export const getWebUrl = (url) => {
  if (url && (url.toLowerCase().startsWith('https://') || url.toLowerCase().startsWith('http://'))) {
    return url;
  } else if (isDevelopmentSystem()) {
    return IAS_DEV_URI + url;
  } else {
    return window.location.origin + url;
  }
}

export const getDefaultHeader = () => {
  const state = store.getState();

  return {
    "Accept-Language": state.user.userLanguage,
    "X-IAS-ClientApp": `${packageJSON.name} ${packageJSON.version}`
  }
}

export const rgbToHex = (rgb) => {
  if (rgb === null || rgb === "null" || rgb === "")
    rgb = 0x00FFFFFF;

  var hex = (0x00FFFFFF & Number(rgb)).toString(16).padStart(6, '0');
  return "#" + hex;
}

export const getNotificationMessageLocale = (message) => {
  if (message === undefined || message === null || (message && message === "")) {
    return
  }

  switch (message.trim()) {
    case "LoginOrganisationNotFound":
      return {
        content: 'login-organisation-not-found'
      }

    case "LoginBaseOrganisationNotFound":
      return {
        content: 'login-base-organisation-not-found'
      }

    case "UNAUTHORIZED_ERROR":
      return {
        content: 'invalid-credentials'
      }

    case "LOGOUT_REQUEST_FAILED":
      return {
        content: 'logout-request-failed'
      }

    case "The Name field is required.":
      return {
        content: 'name-field-required'
      }

    case "The OrderNumber field is required.":
      return {
        content: 'order-number-field-required'
      }

    case "Invalid date: DateTo may not be before DateFrom.":
      return {
        content: 'dateto-before-datefrom'
      }

    case "The input was not valid.":
      return {
        content: 'input-was-invalid'
      }

    case "Database_ForeignKeyConstraintViolation":
    case "ForeignKeyConstraintViolation":
    case "Database server error occurred: ForeignKeyConstraintViolation.":
      return {
        content: 'foreign-key-constraint-violation'
      }

    case "NO_MORE_SYNC":
      return {
        content: 'no-more-sync'
      }

    case "SUCCESSFUL_SAVE":
      return {
        content: 'successful-save'
      }

    case "SUCCESSFUL_SAVE_PASSWORD":
      return {
        content: 'successful-save-password'
      }

    case "SUCCESSFUL_DELETE":
      return {
        content: 'successful-delete'
      }

    case "SUCCESSFUL_SAVE_employee":
      return {
        content: 'successful-save-employee'
      }

    case "SUCCESSFUL_SAVE_equipment":
      return {
        content: 'successful-save-equipment'
      }

    case "SUCCESSFUL_SAVE_projectactivity":
      return {
        content: 'successful-save-projectactivity'
      }

    case "SUCCESSFUL_SAVE_mixture":
      return {
        content: 'successful-save-mixture'
      }

    case "UNSUCCESSFUL_UPDATE_PENSUM":
      return {
        content: 'unsuccessful-update-mixture'
      }

    case "SUCCESSFUL_IMPORT_OFFER_DOCUMENT":
      return {
        content: 'successful-import-offer-document'
      }

    case "SUCCESSFUL_EXPORT_OFFER_DOCUMENT":
      return {
        content: 'successful-export-offer-document'
      }

    case "SUCCESSFUL_DELETE_OFFER_DOCUMENT":
      return {
        content: 'successful-delete-offer-document'
      }

    case "SUCCESSFUL_IMPORT_TENDER_DOCUMENT":
      return {
        content: 'successful-import-tender-document'
      }

    case "SUCCESSFUL_EXPORT_TENDER_DOCUMENT":
      return {
        content: 'successful-export-tender-document'
      }

    case "SUCCESSFUL_DELETE_TENDER_DOCUMENT":
      return {
        content: 'successful-delete-tender-document'
      }

    case "SUCCESSFUL_IMPORT_DOCUMENT":
      return {
        content: 'successful-import-document'
      }

    case "SUCCESSFUL_IMPORT_IMAGE":
      return {
        content: 'successful-import-image'
      }

    case "SUCCESSFUL_DELETE_DOCUMENT":
      return {
        content: 'successful-delete-document'
      }

    case "SUCCESSFUL_DELETE_IMAGE":
      return {
        content: 'successful-delete-image'
      }

    case "ProjectHasProjectActivities":
      return {
        content: 'project-has-project-activities'
      }

    case "InvalidFileType":
      return {
        content: 'invalid-file-type'
      }

    case "GENERAL_SERVER_ERROR":
      return {
        content: 'general-server-error'
      }

    default:
      return {
        content: message.trim(),
        notFoundLocale: true
      }
  }
}

export const isStateFetching = stateArray => stateArray
  .some(({ isFetching }) => isFetching === true);


export const sendFileRequest = (url, files, headers) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    const fd = new FormData();

    xhr.open("POST", url, true);

    files.forEach(file => fd.append('File', file));

    xhr.onload = resolve;
    xhr.onerror = reject;

    // Setup our listener to process compeleted requests
    xhr.onreadystatechange = function () {
      // Only run if the request is complete
      if (xhr.readyState !== 4) return;
      // Process the response
      if (xhr.status >= 200 && xhr.status < 300) {
        // If successful
        resolve(xhr);
      } else {
        // If failed
        reject(xhr);
      }
    };

    if (headers !== undefined && headers !== null) {
      headers.forEach((v, k) => {
        xhr.setRequestHeader(k, v)
      });
    }

    // Initiate a multipart/form-data upload
    xhr.send(fd);
  });
};

export const downloadFileRequest = (url, headers) => {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.responseType = 'blob';
    xhr.open("GET", url, true);
    xhr.onload = resolve;
    xhr.onerror = reject;

    // Setup our listener to process compeleted requests
    xhr.onreadystatechange = function () {
      // Only run if the request is complete
      if (xhr.readyState !== 4) return;
      // Process the response
      if (xhr.status >= 200 && xhr.status < 300) {
        // If successful
        resolve(xhr);
      } else {
        // If failed
        reject(xhr);
      }
    };

    if (headers !== undefined && headers !== null) {
      headers.forEach((v, k) => {
        xhr.setRequestHeader(k, v)
      });
    }

    xhr.send(fd);
  });
};

export const obj_to_map = (obj => {
  const mp = new Map;
  Object.keys(obj).forEach(k => { mp.set(k, obj[k]) });
  return mp;
});

export const sort = (unordered) => {
  const ordered = {};
  Object.keys(unordered).sort().forEach(function (key) {
    ordered[key] = unordered[key];
  });
  return ordered;
};