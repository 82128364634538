import { connect } from 'react-redux'; 
import { withNamespaces } from 'react-i18next';

import FilterContainer from "./FilterContainer";

const mapStateToProps = ({
    crew, customer, mixingPlant, employee
  }) => ({
    customers: customer.customer,
    mixingPlants: mixingPlant.mixingPlant,
    employees: employee.employee,
    crews: crew.crews
  });
  
  const mapDispatchToProps = {
    
  };

const connected = connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
export default withNamespaces(['common'])(connected);
