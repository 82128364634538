import React from "react";

const Main = ({ children }) => {
  return (
    <main className="page-main" role="main">
      {children}
    </main>
  );
};

export default props => <Main {...props} />;
