import React, { Component } from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';

function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

export default class TimeLeft extends Component {
    constructor(props) {
        super(props)

        this.state = {
            timeLeft: ''
        }
    }

    componentDidMount() {
        const { expiresAt, setTokenExpired } = this.props
        const ms = moment(expiresAt).diff(moment())
        new Promise(resolve => {
            setTimeout(async () => {
            resolve();
            setTokenExpired()
            }, ms);
        })
        this.startInterval()
    }

    tick = () => {
        const { expiresAt } = this.props
        const ms = moment(expiresAt).diff(moment())

        this.setState({ timeLeft: ms })
        if (ms <= 0) {
            this.stopInterval()
        }
    }

    startInterval = () => {
        this.inertvalHandle = setInterval(this.tick, 1000)
    }

    stopInterval = () => {
        clearInterval(this.inertvalHandle)
    }

    render() {
        const { timeLeft } = this.state
        return (
            <Skeleton variant="text" animation="false" style={{ marginRight: 10 }}>{millisToMinutesAndSeconds(timeLeft)}</Skeleton>
        )
    }
}
