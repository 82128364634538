import {
    GET_APP_SETTINGS_REQUEST,
    GET_APP_SETTINGS_ERROR,
    GET_APP_SETTINGS_SUCCESS,
  } from "../../actions/types";
  
  const initialState = {};
  
  export default (state = initialState, action) => {
    switch (action.type) {
  
      case GET_APP_SETTINGS_REQUEST:
        return {
          ...state,
          isFetching: true,
          error: undefined
        };
  
      case GET_APP_SETTINGS_SUCCESS:
        return {
          settings: action.payload,
          isFetching: false,
          error: undefined
        };
  
      case GET_APP_SETTINGS_ERROR:
        return {
          settings: {},
          error: `${action.error}`
        };
  
      default:
        return state;
    }
  };
  