import { CREATE_PENSUM_OBJ } from '../types';

export const createPensumObj = payload => ({
    type: CREATE_PENSUM_OBJ,
    payload
});

export default (entity) => (dispatch) => {
    if (entity === null || entity === undefined) {
        return
    }
    dispatch(createPensumObj(entity));
};