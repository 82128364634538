import {
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATION
} from '../../actions/types';

const initialState = {
  notification: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        notification: {
            message: action.message,
            severity: action.severity
        }
      };

    case CLEAR_NOTIFICATION:
      return {};

    default:
      return state;
  }
};
