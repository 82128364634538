import {
  CREATE_MIXTURE_REQUEST,
  CREATE_MIXTURE_ERROR,
  CREATE_MIXTURE_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/createEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default entity => (state = initialState, action) => {

  context.init(context);

  context.CREATE_ENTITY_REQUEST = CREATE_MIXTURE_REQUEST;
  context.CREATE_ENTITY_ERROR = CREATE_MIXTURE_ERROR;
  context.CREATE_ENTITY_SUCCESS = CREATE_MIXTURE_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "mixture";
  context.entityLocation = RESOURCES.Mixtures;
  context.hasOrganisation = true;

  return context.execute(context)(entity)(state, action);
};