import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import mixingPlantActions from "../../actions/mixingPlant";
import mixtureOrderActions from "../../actions/mixtureOrder";
import mixtureActions from "../../actions/mixture";
import organisationActions from "../../actions/organisation";
import MixingPlant from "./MixingPlant";

import { isStateFetching } from "../../utils";

const mapStateToProps = ({ mixingPlant, organisation, mixtureOrder, mixture }) => ({
  mixingPlant: mixingPlant.mixingPlant,
  organisations: organisation.organisations,
  mixtureOrder: mixtureOrder.mixtureOrder,
  mixture: mixture.mixture,
  isFetching: isStateFetching([
    mixingPlant, organisation, mixtureOrder, mixture
  ])
});

const mapDispatchToProps = {
  ...mixingPlantActions,
  ...organisationActions,
  ...mixtureOrderActions,
  ...mixtureActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(MixingPlant);
export default withNamespaces(["common"])(connected);
