import {
  GET_PROJECT_REQUEST,
  GET_PROJECT_ERROR,
  GET_PROJECT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (selectQuery = null, expandQuery = null, filterQuery = null) => async (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_PROJECT_REQUEST;
  context.GET_ENTITY_ERROR = GET_PROJECT_ERROR;
  context.GET_ENTITY_SUCCESS = GET_PROJECT_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "project";
  context.entityLocation = RESOURCES.Projects;
  context.selectQuery = "ID,Name,CostCenter,ExternalReferenceNumber,WorkExecutionEstimatePercentage,OrderTotalAmount,ActualInvoicedAmount,FinalInvoiceTimeUtc,RemainingAmountToInvoice,IsClosed,HasPartialInvoice";
  context.expandQuery = `Organisation($select=ID, ShortName),Customer($select=*),Supervisor($select=*),DefaultMixingPlant($select=*),DefaultMixtureOrder($select=*),
    ProjectActivities($select=*),OfferDocument($select=*),TenderDocument($select=*),Images($select=*;$expand=Thumbnail($select=*)), Documents($select=*;$expand=Thumbnail($select=*))`;

  if (selectQuery !== undefined && selectQuery !== null)
    context.selectQuery = selectQuery;

  if (expandQuery !== undefined && expandQuery !== null)
    context.expandQuery = expandQuery;

  if (filterQuery !== undefined && filterQuery !== null)
    context.filterQuery = filterQuery;

  return context.execute(context)()(state, action);
};