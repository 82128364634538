import React from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";

import AppRouter from "./core/containers/AppRouter";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["settings", "filter", "queryParams"]
};

const persistedReducer = persistReducer(persistConfig, reducers);

/*const extraThunk = thunk.withExtraArgument({ api });*/

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

const Loading = () => <div>Loading...</div>;

export default () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <AppRouter />
    </PersistGate>
  </Provider>
);
