import React, { Component } from "react";
import EquipmentTable from "../Equipment/EquipmentTable";

import Loader from '../../core/components/Loader/LinearProgress'

class Equipment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      onRowAdd: props.createEquipment,
      onRowUpdate: props.updateEquipment,
      onRowDelete: props.deleteEquipment,
      editable: true
    }
  }

  async componentDidMount() {
    const { getEquipment, getOrganisations } = this.props;
    await getEquipment()
    await getOrganisations();
    this.setState({ isMounted: true });

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    const tableHeader = document.getElementsByClassName("MuiToolbar-gutters")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }
    if (tableHeader !== undefined) {
      tableHeader.style.marginBottom = "35px";
    }
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
          if (orgObj.Level === topLevel) {
            orgText.style.marginLeft = "0px";
          } else if (orgObj.Level > topLevel) {
            const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
            orgText.style.paddingLeft = `${margin}px`;
          }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  render() {
    const { isMounted, ...rest } = this.state;
    const { equipment, organisations, t, isFetching } = this.props;
    
    return (
      <div
        style={{
          margin: "0 auto",
          width: isFetching && !isMounted ? "100%" : "620px",
          marginTop: isFetching && !isMounted ? 0 : 30,
        }}
      >
      {
        isMounted ? (
          <EquipmentTable
            title={t('equipment')}
            data={equipment && equipment}
            organisations={organisations}
            t={t}
            {...rest}
          />
        ) : <Loader />
      }
        
      </div>
    );
  }
}

export default Equipment;
