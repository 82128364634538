import React, { Component } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";

import Main from "../../components/Main";
import Header from "../../components/Header";
import Navigation from "../../containers/Navigation";
import Snackbar from '../../components/Snackbar';

export default class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: window.location.pathname,
      userLanguage: null,
      notification: undefined,
    }
  }

  async componentDidMount() {
    const { getAppSettings } = this.props

    await getAppSettings()

    const { settings } = this.props
    const FavIconUrl = settings.FavIconUrl
    const favicon = document.getElementById("favicon")
    favicon.href = FavIconUrl

    this.setState({ isMounted: true })
  }

  componentWillReceiveProps(nextProps) {
    const { notification, userLanguage, setLanguage } = nextProps

    if (userLanguage !== this.state.userLanguage) {
      setLanguage(window.localStorage.i18nextLng);
      this.setState({ userLanguage: window.localStorage.i18nextLng })
    }

    if (
      notification !== undefined
      && Object.entries(notification).length !== 0
      && notification.constructor === Object
      && notification !== this.state.notification
    ) {
      this.setState({ notification });
    } else {
      this.setState({ notification: undefined });
    }
  }

  render() {
    const {
      user,
      children,
      isLoggedIn,
      isPasswordExpired,
      isSetNewPassword,
      history: { location: { pathname } = {} } = {}
    } = this.props;
    const { isMounted, notification } = this.state
    const checkedIsLoggedIn = Boolean(isLoggedIn)

    let cn;

    if (!checkedIsLoggedIn && (pathname === "/login" || pathname === "/")) {
      cn = "page-content";
    } else if (checkedIsLoggedIn && pathname === '/planning') {
      cn = "page-container bgcolor-override"
    } else if (checkedIsLoggedIn) {
      cn = "page-container";
    }

    return (
      <>
      {isMounted &&
        <>
          <Helmet>
            <title>Strabag - IAS</title>
          </Helmet>
          <div className={cn}>
            {
              (checkedIsLoggedIn || (!checkedIsLoggedIn && (Boolean(isSetNewPassword) || Boolean(isPasswordExpired)))) && <Header />
            }
            {
              (checkedIsLoggedIn || (!checkedIsLoggedIn && (Boolean(isSetNewPassword) || Boolean(isPasswordExpired)))) && <Navigation pathname={pathname} />
            }
            <Main>{children}</Main>
            {notification && <Snackbar notification={notification} {...this.props} /> }
          </div>
        </>}
      </>
    );
  }
}
