import {
  GET_MIXTURE_REQUEST,
  GET_MIXTURE_ERROR,
  GET_MIXTURE_SUCCESS,
  CREATE_MIXTURE_REQUEST,
  CREATE_MIXTURE_ERROR,
  CREATE_MIXTURE_SUCCESS,
  UPDATE_MIXTURE_REQUEST,
  UPDATE_MIXTURE_ERROR,
  UPDATE_MIXTURE_SUCCESS,
  DELETE_MIXTURE_REQUEST,
  DELETE_MIXTURE_ERROR,
  DELETE_MIXTURE_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_MIXTURE_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_MIXTURE_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_MIXTURE_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_MIXTURE_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_MIXTURE_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_MIXTURE_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_MIXTURE_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_MIXTURE_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_MIXTURE_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_MIXTURE_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_MIXTURE_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_MIXTURE_SUCCESS;
  reducers.entityName = "mixture";

  return reducers.entity(reducers)(state, action);
};
