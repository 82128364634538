import React from "react";
import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './index.css';

class MixtureOrderTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      editable: props.editable ? props.editable : '',
      mixingPlantRow: props.mixingPlantRow,
      newData: null,
      error: null,
      stateIsUpdated: false
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (this.state.data !== this.props.data) {
  //     this.setState({ data: this.props.data })
  //   }
  //}

  componentWillReceiveProps(props) {
    if (this.props.data !== props.data) {
      if (!this.state.stateIsUpdated)
        this.setState({ data: props.data, selectedRow: null })
    }
  }

  render() {
    const { t } = this.props;

    const columns = [
      { title: t('order-number'), field: "OrderNumber", defaultSort: "asc", editComponent: props => (
        <FormControl>
          <InputLabel className="trans-label-name" id="org-label">*</InputLabel>
          <TextField
            id="name-input"
            value={props.value}
            type="text"
            error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
            inputProps={{maxLength: 255}}
            onChange={e => props.onChange(e.target.value)}
          />
        </FormControl>
      ), filtering: false },
      { title: t('order-description'), field: "Description", filtering: false, editComponent: props => (
        <FormControl>
          <TextField
            id="description-input"
            value={props.value}
            type="text"
            inputProps={{maxLength: 255}}
            onChange={e => props.onChange(e.target.value)}
          />
        </FormControl>
      )},
      {
        title: t("is-deleted"), field: "IsDeleted", type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={this.state.data}
        onRowClick={((evt, rowData) => { this.setState({ selectedRow: rowData }); this.props.onMixtureOrderRowChanged(rowData); })}
        options={{
          pageSize: 10,
          pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20] : [5],
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true,
          rowStyle: rowData => ({
            backgroundColor: (this.state.selectedRow && this.state.selectedRow.tableData.id === rowData.tableData.id) ? '#EEE' : '#FFF'
          })
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          }
        }}
        editable={this.state.editable && {
          onRowAdd: newData =>
            new Promise(resolve => {
              this.setState({
                newData: newData
              });
              setTimeout(async () => {
                resolve();
                this.setState({ stateIsUpdated: true });
                const errorOrResult = await this.props.onMixtureOrderRowAdd({
                  OrderNumber: newData.OrderNumber,
                  Description: newData.Description,
                  IsDeleted: newData.IsDeleted,
                  MixingPlant: {
                    ID: this.props.mixingPlantRow.ID
                  }
                });
                this.setState({ stateIsUpdated: false });
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    newData.ID = errorOrResult.ID;
                    data.push(newData);
                    return { ...prevState, data };
                  });
                }

              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                if (oldData) {
                  this.setState({ stateIsUpdated: true });
                  const errorOrResult = await this.props.onMixtureOrderRowUpdate({
                    ...newData,
                    // MixingPlant: {
                    //   ID: org.ID
                    // }
                  }, oldData.tableData);
                  this.setState({ stateIsUpdated: false });
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                const errorOrResult = await this.props.onMixtureOrderRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    if (data.indexOf(oldData) >= 0)
                      data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data, selectedRow: (prevState.selectedRow !== undefined && prevState.selectedRow !== null && prevState.selectedRow.ID !== oldData.ID ? prevState.selectedRow : null) };
                  });
                }
              }, 100);
            })
        }}
      />
    );
  }
}

export default MixtureOrderTable;