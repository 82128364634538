import odata from 'odata-client';
import {
    DELETE_ENTRY_REQUEST,
    DELETE_ENTRY_ERROR,
    DELETE_ENTRY_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import { showNotification } from '../notification';

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const deleteEntryRequest = () => ({
    type: DELETE_ENTRY_REQUEST,
});

export const deleteEntryError = errorOrResults => ({
    type: DELETE_ENTRY_ERROR,
    error: errorOrResults.message,
});

export const deleteEntrySuccess = payload => ({
    type: DELETE_ENTRY_SUCCESS,
    payload,
});

export default (entityType, entryId) => async (dispatch, getState) => {
    dispatch(deleteEntryRequest());

    const query = odata({service: getServiceUrl()}).resource(entityType, entryId);

    const errorOrResult = await query.delete({
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('delete entry api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            } else {
                return true;
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(deleteEntryError(errorOrResult));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return errorOrResult;
    } else {
        dispatch(showNotification("SUCCESSFUL_DELETE", "success"))
    }

    dispatch(deleteEntrySuccess(errorOrResult));

    return errorOrResult;
};