import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import driverActions from "../../actions/driver";
import organisationActions from "../../actions/organisation";
import Driver from "./Driver";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ driver, organisation }) => ({
  driver: driver.driver,
  organisations: organisation.organisations,
  isFetching: isStateFetching([
    driver,
    organisation
  ])
});

const mapDispatchToProps = {
  ...driverActions,
  ...organisationActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Driver);
export default withNamespaces(["common"])(connected);
