import {
  UPDATE_SPECIALDAY_REQUEST,
  UPDATE_SPECIALDAY_ERROR,
  UPDATE_SPECIALDAY_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/updateEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (entity, entityTableData) => (state = initialState, action) => {

  context.init(context);

  context.UPDATE_ENTITY_REQUEST = UPDATE_SPECIALDAY_REQUEST;
  context.UPDATE_ENTITY_ERROR = UPDATE_SPECIALDAY_ERROR;
  context.UPDATE_ENTITY_SUCCESS = UPDATE_SPECIALDAY_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "specialday";
  context.entityLocation = RESOURCES.SpecialDays;

  return context.execute(context)(entity, entityTableData)(state, action);
};