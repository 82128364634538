import React from 'react';
import _ from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import styled from 'styled-components';
import moment from 'moment';

import PensumsContainer from './components/PensumsContainer/';
import CrewContainer from './components/CrewContainer/';
import FilterContainer from './components/FilterContainer/';
import Loader from '../../core/components/Loader/LinearProgress'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import MixtureIcon from '@material-ui/icons/LineStyle';
import DriverIcon from '@material-ui/icons/LocalShipping';

import './index.css'

const DriverMixtureIcon = require("../../images/truck.jpeg");

const Container = styled.div`
  display: flex;
  padding-right: 6px;
`

const FirstColumnContainer = styled.div`
  width: 105px;
  background-color: #fdfdfd;
  margin-top: 3px;
  margin-right: 6px;
  margin-left: 6px;
  margin-bottom: 4px;
  padding-bottom: 3px;
  border-radius: 4px;
  border: thin solid rgb(0, 128, 192);
  position: -webkit-sticky;
  position: sticky;
  left: -1px;
  z-index: 4;
`

const DateColumn = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 4px 4px 0px 0px;
  font-family: "Open Sans";
  font-size: 0.86rem;
  text-align: center;
  color: ${props => parseInt(props.day) === 6 || parseInt(props.day) === 0 ? '#868686' : '#868686' };
`

export default class Planning extends React.PureComponent {
  constructor(props) {
    super(props);

    const now = moment()
    const startOfWeek = now.startOf('isoWeek').format("YYYY-MM-DD")
    const endOfWeek = moment(startOfWeek).add(13, "days")

    this.state = {
      selectedFromDate: startOfWeek,
      selectedToDate: endOfWeek.format("YYYY-MM-DD"),
      customer: null,
      supervisor: null,
      selectedCrew: null,
      mixingPlant: null,
      history: {
        currentStep: -1,
        chain: []
      },
      view: null,
      dates: [],
      columns: {},
      crews: [],
      pensums: [],
      isMounted: false,
    }
  }

  async componentDidMount() {
    const {
      userRoles,
      filters,
      getPensums,
      getCrews,
      getDriver,
      getMixingPlant,
      getProjectActivities,
      getMixture,
      getMixtureOrder,
      getProjects,
      getEmployee,
      getCustomer,
      getEquipment,
      getSpecialDay,
      setChangeMessageToken
    } = this.props;
    const { fromDate, toDate, customer, supervisor, crew, mixingPlant, view } = filters
    const { selectedFromDate, selectedToDate } = this.state
    
    await getCrews();
    await getPensums(fromDate ? new Date(fromDate) : new Date(selectedFromDate), toDate ? new Date(toDate) : new Date(selectedToDate));
    await getCustomer("IsDeleted eq false");
    await getEmployee("IsDeleted eq false");
    await getMixingPlant(null, null, "IsDeleted eq false");
    await getProjects();
    await getProjectActivities();
    await getDriver("IsDeleted eq false");
    await getEquipment("IsDeleted eq false");
    await getMixtureOrder(null, null, "IsDeleted eq false");
    await getMixture();
    await getSpecialDay();
    
    let userView
    if (!view) {
      if (userRoles.some(role => role === "Supervisor")) {
        userView = { id: 1 }
      } else if (userRoles.some(role => role === "CrewLeader")) {
        userView = { id: 2 }
      } else if (userRoles.some(role => role === "Dispatcher")) {
        userView = { id: 3 }
      } else if (userRoles.some(role => role === "User")) {
        userView = { id: 1 }
      } else {
        userView = { id: 1 }
      }
    } else {
      userView = { id: view }
    }

    const dates = []
    const fDate = fromDate ? moment(fromDate) : moment(selectedFromDate)
    const eDate = toDate ? moment(toDate) : moment(selectedToDate)
    dates.push(`${fDate.format("YYYY-MM-DD")}`)
    while (fDate.dayOfYear() < eDate.dayOfYear()) {
      fDate.add(1, 'days')
      dates.push(`${fDate.format("YYYY-MM-DD")}`)
    }

    this.setState({
      selectedFromDate: fromDate ? fromDate : selectedFromDate,
      selectedToDate: toDate ? toDate : selectedToDate,
      dates: dates,
      customer: customer,
      supervisor: supervisor,
      selectedCrew: crew,
      mixingPlant: mixingPlant,
      view: userView,
      isMounted: true
    })

    setChangeMessageToken()
    this.waitForMessages(null)
  }
  
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!(_.isEqual(nextState, this.state))) {
      return true
    }
    return false
  }

  componentWillReceiveProps(nextProps) {
    const { userCrewName, userRoles } = this.props
    let view = null

    if (userRoles.some(role => role === "Supervisor")) {
      view = { id: 1 }
    } else if (userRoles.some(role => role === "CrewLeader")) {
      view = { id: 2 }
    } else if (userRoles.some(role => role === "Dispatcher")) {
      view = { id: 3 }
    } else if (userRoles.some(role => role === "User")) {
      view = { id: 1 }
    } else {
      view = { id: 1 }
    }

    if (JSON.stringify(nextProps.crews) !== JSON.stringify(this.state.crews)) {
      this.setState({ crews: nextProps.crews });
    }

    if (nextProps.isFetching !== this.state.isFetching) {
      this.setState({ isFetching: nextProps.isFetching })
    }

    /*
      when loading page at the first time, only update columns, when:
      mixtures comes last in loading page, becase inside componentDidMount getMixtures is the last.

      after the first load and having mixtures loaded, only pensums will arrive in props (componentWillReceiveProps)

      !! if there is NO MIXTURES loaded, there is NO PENSUMS inserted into the columns
    */

   if (
     nextProps.pensums !== undefined
     && nextProps.pensums !== this.state.pensums
     && nextProps.mixtures !== undefined
     && Object.keys(nextProps.mixtures).length !== 0
    ) {
      const { filters } = this.props
      const { datesInFilter } = filters
      const { dates } = this.state
      const crews = this.state.crews.length > 0 ? this.state.crews : nextProps.crews !== undefined && Array.from(nextProps.crews).length > 0 ? Array.from(nextProps.crews) : []
      const pensums = nextProps.pensums
      let filteredCrews = []
      let dateRange = dates

      if (datesInFilter) {
        dateRange = datesInFilter
      }

      if (view.id === 2 && userCrewName !== null && crews !== undefined && crews !== null && crews.length > 0) {
        filteredCrews = crews.filter(crew => crew.Name.toLowerCase() === userCrewName.toLowerCase())
      } else {
        filteredCrews = crews
      }

      this.calculatePensumsInColumns(dateRange, filteredCrews, pensums)

      this.setState({ pensums, crews: filteredCrews });
    }
  }

  waitForMessages = async (lastChangeMessageID, fromDate, toDate, filteredCrews) => {
    /*
      "Note: In case of invalid (e.g. too old) LastChangeMessageID
      then status code 400 with error code 'InvalidLastChangeMessageID' is returned.
      In such case synchronisation cannot be guaranteed so the client needs to completely reload the current view."
      
      solution -> needToReload
      no need to reload current view, only need to update pensums to the latest, and call waitForMessages
    */
    const { waitForMessages, getPensums, updatePensumObj, createPensumObj, showNotification } = this.props
    const { crews, pensums, selectedFromDate, selectedToDate } = this.state
    const isSideEffect = true
    const isAutoRequest = true
    const crewIDs = []
    
    if (filteredCrews) {
      filteredCrews.forEach(crew => {
        crewIDs.push(crew.ID)
      })
    } else {
      crews.forEach(crew => {
        crewIDs.push(crew.ID)
      })
    }

    const errorOrMessageObject = await waitForMessages(
      {
        "Filter": {
          "DayFrom": fromDate ? moment(fromDate).format("YYYY-MM-DD") : moment(selectedFromDate).format("YYYY-MM-DD"),
          "DayTo": toDate ? moment(toDate).format("YYYY-MM-DD") : moment(selectedToDate).format("YYYY-MM-DD"),
          "CrewIDs": crewIDs
        },
      "LastChangeMessageID": lastChangeMessageID
      },
      pensums
    )

    if (errorOrMessageObject === undefined) {
      return
    }

    const { error, needToReload, cancel } = errorOrMessageObject

    if (cancel) {
      return
    }

    if (!error) {
      const { latestChangeMessageID, objectsToChange } = errorOrMessageObject
      const entitiesToUpdate = []
      const entitiesToCreate = []

      if (objectsToChange !== undefined && objectsToChange !== null && objectsToChange.length > 0) {
        for (let i = objectsToChange.length; i-- > 0;) {
          const entity = objectsToChange[i]
          const entityType = entity.ChangedEntityTypeName
          const { ChangeTypeCode } = entity
          const pensum = pensums.find(pensum => pensum.ID === entity.ChangedEntityID)

          switch (entityType) {
            case "Pensum":
              if ((Number(ChangeTypeCode) === 2 || Number(ChangeTypeCode) === 3) && pensum && entity.ChangedEntityID === pensum.ID) {
                entitiesToUpdate.push({
                  ...pensum, ...entity.Pensum, Project: pensum.Project
                })
              }
              if (Number(ChangeTypeCode) === 1 && !pensum) {
                const { projects } = this.props
                const { Pensum, Pensum: { Project: { ID } }} = entity
                let project

                /*
                  this is a solution for:
                  if a user clicks fast between planning and projects menus
                  we loose projects request or just projects somewhere,
                  therefore we need to request it again, before further operations.
                */
                if (projects !== null && projects !== undefined) {
                  project = projects.find(obj => obj.ID === ID)
                } else {
                  const { getProjects } = this.props
                  await getProjects()
                  const { projects } = this.props
                  project = projects.find(obj => obj.ID === ID)
                }

                entitiesToCreate.push({
                  ...Pensum,
                  Project: project,
                  ProjectActivityEntries: Pensum.ProjectActivityEntry ? Pensum.ProjectActivityEntry : [],
                  EmployeeEntries: Pensum.EmployeeEntry ? Pensum.EmployeeEntry : [],
                  EquipmentEntries: Pensum.EquipmentEntry ? Pensum.EquipmentEntry : [],
                  MixtureEntries: Pensum.MixtureEntry ? Pensum.MixtureEntry : [],
                  DriverEntries: Pensum.DriverEntry ? Pensum.DriverEntry : []
                })
              }
              break;
          
            default:
              getPensums(new Date(selectedFromDate), new Date(selectedToDate), isSideEffect, isAutoRequest)
              break;
          }
        }
      }

      if (entitiesToUpdate.length > 0) {
        entitiesToUpdate.forEach(entity => {
          updatePensumObj(entity)
        })
      }
      if (entitiesToCreate.length > 0) {
        entitiesToCreate.forEach(entity => {
          createPensumObj(entity)
        })
      }

      this.waitForMessages(latestChangeMessageID)
    } else if (needToReload) {
      await getPensums(new Date(selectedFromDate), new Date(selectedToDate), isSideEffect)
      this.waitForMessages(null)
    } else {
      showNotification("NO_MORE_SYNC", "error")
    }
  }


  calculatePensumsInColumns = (dates, filteredCrews, pensums) => {
    dates.forEach(day => {
      filteredCrews.forEach(crew => {
        const pensumsInColumn = []
        const taskIdsInColumn = []
        const columnId = `${crew.ID}/${day}`

        pensums.forEach(pensum => {
          if (
            moment(day).format("YYYY-MM-DD") === moment(pensum.Day).format("YYYY-MM-DD")
            && pensum.Crew.ID === crew.ID
          ) {
            pensumsInColumn.push(pensum)
          }
        })

        const sortedPensums = pensumsInColumn.sort((a, b) => { return a.SortOrder - b.SortOrder })

        sortedPensums.forEach(pensum => taskIdsInColumn.push(`pensum-${pensum.ID}`))
          
        const { columns } = this.state
        columns[columnId] = {
          id: columnId,
          taskIds: taskIdsInColumn,
          pensums: sortedPensums
        }

        this.setState({ columns })
      })
    })
  }

  handleFromDateChange = async (date) => {
    const { selectedFromDate, selectedToDate } = this.state
    const { getPensums, saveFilter } = this.props
    const dates = []
    
    const fromDate = moment(date)
    const endDate = moment(selectedToDate)

    if (moment(selectedFromDate).format("YYYY-MM-DD") !== fromDate.format("YYYY-MM-DD")) {
      dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      endDate.add(fromDate.diff(moment(selectedFromDate), "days", true), "days")
      while (fromDate.dayOfYear() < endDate.dayOfYear()) {
        fromDate.add(1, 'days')
        dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      }

      this.setState({ dates, selectedFromDate: date, selectedToDate: endDate.diff(fromDate, "days") < 0 ? date : endDate.format("YYYY-MM-DD") })
      await saveFilter({ id: "fromDate", value: moment(date).format("YYYY-MM-DD") });
      await saveFilter({ id: "toDate", value: endDate.format("YYYY-MM-DD") });
      await saveFilter({ id: "datesInFilter", value: dates })
      await getPensums(new Date(date), new Date(endDate.format("YYYY-MM-DD")))
      this.waitForMessages(null, date, endDate.format("YYYY-MM-DD"))
    }
  }

  handleToDateChange = async (date) => {
    const { selectedFromDate, selectedToDate } = this.state
    const { getPensums, saveFilter } = this.props
    const dates = []
    
    const fromDate = moment(selectedFromDate)
    const endDate = moment(date)

    if (moment(selectedToDate).format("YYYY-MM-DD") !== endDate.format("YYYY-MM-DD")) {
      dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      while (fromDate.dayOfYear() < endDate.dayOfYear()) {
        fromDate.add(1, 'days')
        dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      }
        
      this.setState({ dates, selectedToDate: endDate.diff(fromDate) < 0 ? fromDate : date })
      await saveFilter({ id: "toDate", value: endDate.diff(fromDate) < 0 ? fromDate.format("YYYY-MM-DD") : endDate.format("YYYY-MM-DD") })
      await saveFilter({ id: "datesInFilter", value: dates })
      await getPensums(new Date(selectedFromDate), endDate.diff(fromDate) < 0 ? new Date(fromDate) : new Date(date))
      this.waitForMessages(null, selectedFromDate, endDate.diff(fromDate) < 0 ? fromDate : date)
    }
  }

  handleCustomerChange = async (event) => {
    const { saveFilter } = this.props
    const { id, value } = event.target
    this.setState({ [id]: value === "all" ? null : value })
    await saveFilter({ id: "customer", value: value === "all" ? null : Number(value) });
  }

  handleSupervisorChange = async (event) => {
    const { saveFilter } = this.props
    const { id, value } = event.target;
    this.setState({ [id]: value === "all" ? null : value })
    await saveFilter({ id: "supervisor", value: value === "all" ? null : Number(value) });
  }

  handleCrewChange = async (event) => {
    const { saveFilter } = this.props
    const { value } = event.target;
    this.setState({ selectedCrew: value === "all" ? null : value })
    this.waitForMessages(null, null, null, value === "all" ? null : [{ ID: value }])
    await saveFilter({ id: "crew", value: value === "all" ? null : Number(value) });
  }

  handleMixingPlantChange = async (event) => {
    const { saveFilter } = this.props
    const { id, value } = event.target;
    this.setState({ [id]: value === "all" ? null : value })
    await saveFilter({ id: "mixingPlant", value: value === "all" ? null : Number(value) });
  }

  handleViewChange = async (event) => {
    const { dates, crews, selectedFromDate } = this.state
    const { getPensums, saveFilter, userCrewName } = this.props
    const { value } = event.target
    let filteredDates = []
    let filteredCrews = []
    
    if (Number(value) === 3) {
      filteredDates = dates.splice(0,2)
    } else if (dates.length > 2) {
      if (Number(value) === 1) {
        const fromDate = moment(selectedFromDate)
        const endDate = moment(selectedFromDate)
        filteredDates = dates

        while (fromDate.diff(endDate, "days") < 13) {
          fromDate.add(1, 'days')
          filteredDates.push(`${fromDate.format("YYYY-MM-DD")}`)
        }
      } else {
        filteredDates = dates
      }
    } else {
      const fromDate = moment(selectedFromDate)
      const endDate = moment(selectedFromDate)
      filteredDates = dates

      while (fromDate.diff(endDate, "days") < 13) {
        fromDate.add(1, 'days')
        if (!filteredDates.some(date => date === fromDate.format("YYYY-MM-DD"))) {
          filteredDates.push(`${fromDate.format("YYYY-MM-DD")}`)
        }
      }
    }

    if (Number(value) === 2 && userCrewName !== null) {
      filteredCrews = crews.filter(crew => crew.Name.toLowerCase() === userCrewName.toLowerCase())
    } else {
      filteredCrews = this.props.crews
    }

    this.setState({
      view: {
        id: Number(value)
      },
      dates: filteredDates,
      crews: filteredCrews,
      selectedFromDate: Number(value) === 3 ? filteredDates[0] : selectedFromDate,
      selectedToDate: filteredDates[filteredDates.length - 1]
    })

    await saveFilter({ id: "view", value: Number(value) });
    await saveFilter({ id: "fromDate", value: Number(value) === 3 ? filteredDates[0] : selectedFromDate });
    await saveFilter({ id: "toDate", value: filteredDates[filteredDates.length - 1] });
    await saveFilter({ id: "datesInFilter", value: filteredDates });
    await getPensums(new Date(filteredDates[0]), new Date(filteredDates[filteredDates.length - 1]))
    this.waitForMessages(null, filteredDates[0], filteredDates[filteredDates.length - 1], filteredCrews)
  }

  handleCopy = (pensum) => {
    this.setState({ copiedPensum: pensum })
  }

  handleHistoryChange = async (step, direction, newChain) => {
    const { updatePensum, showNotification } = this.props
    const { pensums, history, history: { chain, currentStep } } = this.state
    const {
      pensumId,
      objectBefore,
      objectAfter,
      changedEntryType,
      changedEntry,
      changeTypeOfEntry,
      entityID,
      isDeleted
    } = chain[step]
    const isBackward = direction && direction === "back"
    const objToUpdate = direction ? isBackward ? objectBefore : objectAfter : null
    let canDoChange = false
    
    if (objectAfter && newChain === null) {
      const pensumInPresent = !isDeleted && pensums.find(pensum => pensum.ID === pensumId)

      /*
        need more information about SortOrder changing.
        question: if only the SortOrder changes, and the SortOrder which we want to update to is reserved, what should be the new SortOrder?
      */

      if (!isDeleted &&
        (isBackward
          && pensumInPresent.Crew.ID === objectAfter.Crew.ID
          && pensumInPresent.Day === objectAfter.Day
          && pensumInPresent.SortOrder === objectAfter.SortOrder)
        || (direction === "forward"
          && pensumInPresent.Crew.ID === objectBefore.Crew.ID
          && pensumInPresent.Day === objectBefore.Day
          && pensumInPresent.SortOrder === objectBefore.SortOrder)
      ) {
        canDoChange = true
      } else {
        /*
          handle problem when moving same object and remove all same entries in the chain.
          or there is no need to remove all, because if the user goes back/forward again,
          he removes it one by one
        */
        if (!isDeleted) {
          const indexOfHistoryPensum = chain.indexOf(chain[step])
          chain.splice(indexOfHistoryPensum, 1)
  
          this.setState({ history: { ...history, chain, currentStep: isBackward ? currentStep - 1 : currentStep }})
          showNotification(`PENSUM_HAS_BEEN_CHANGED`, "error")
        }
      }
    }

    if (isDeleted) {
      /*
        (!) missing SortOrder check (!)
            check and if necessary calculate new SortOrder
      */

      if (isBackward) {
        const { createPensum } = this.props

        const errorOrResult = await createPensum({
          CreationTimeUtc: moment().utc().format(),
          Day: objectBefore.Day,
          Project: { ID: objectBefore.Project.ID },
          Crew: { ID: objectBefore.Crew.ID },
          SortOrder: objectBefore.SortOrder,
          Comment: objectBefore.Comment
        })

        if (errorOrResult && errorOrResult.ID) {
          const { createEntry } = this.props
          const {
            MixtureEntries,
            DriverEntries,
            ProjectActivityEntries,
            EmployeeEntries,
            EquipmentEntries
          } = objectBefore
          
          if (MixtureEntries.length > 0) {
            MixtureEntries.forEach(async mix => {
              await createEntry("MixtureEntries", {
                Mixture: { ID: mix.Mixture.ID },
                Pensum: { ID: errorOrResult.ID },
                MixtureAmount: mix.MixtureAmount,
                Comment: mix.Comment,
                SortOrder: mix.SortOrder
              })
            })
          }
          if (DriverEntries.length > 0) {
            DriverEntries.forEach(async mix => {
              await createEntry("DriverEntries", {
                Driver: { ID: mix.Driver.ID },
                Pensum: { ID: errorOrResult.ID },
                Payload: mix.Payload,
                StartTime: mix.StartTime,
                Comment: mix.Comment,
                SortOrder: mix.SortOrder
              })
            })
          }
          if (ProjectActivityEntries.length > 0) {
            ProjectActivityEntries.forEach(async mix => {
              await createEntry("ProjectActivityEntries", {
                ProjectActivity: { ID: mix.ProjectActivity.ID },
                Pensum: { ID: errorOrResult.ID },
                SortOrder: mix.SortOrder
              })
            })
          }
          if (EmployeeEntries.length > 0) {
            EmployeeEntries.forEach(async mix => {
              await createEntry("EmployeeEntries", {
                Employee: { ID: mix.Employee.ID },
                Pensum: { ID: errorOrResult.ID },
                SortOrder: mix.SortOrder
              })
            })
          }
          if (EquipmentEntries.length > 0) {
            EquipmentEntries.forEach(async mix => {
              await createEntry("EquipmentEntries", {
                Equipment: { ID: mix.Equipment.ID },
                Pensum: { ID: errorOrResult.ID },
                SortOrder: mix.SortOrder
              })
            })
          }
        }

        this.setState((prevState) => {
          return {
            history: {
              ...prevState.history,
              currentStep: step - 1,
              chain: prevState.history.chain.map(obj => (
                obj === chain[step]
                ? Object.assign(obj, { pensumId: errorOrResult instanceof Error ? obj.pensumId : errorOrResult.ID })
                : obj
              ))
            }
          }
        })
      } else {
        const { deletePensum } = this.props
        const errorOrResult = await deletePensum({ ID: pensumId })

        if (!(errorOrResult instanceof Error)) {
          this.setState((prevState) => {
            return {
              history: {
                ...prevState.history,
                currentStep: step
              }
            }
          })
        }
      }
    }

    if (objToUpdate && canDoChange) {
      const cleanObjToUpdate = Object.assign({}, objToUpdate)

      /*
        delete unchanged properties to prevent Duplicate entry for Primary keys
      */
      delete cleanObjToUpdate.ID
      delete cleanObjToUpdate.EmployeeEntries
      delete cleanObjToUpdate.EquipmentEntries
      delete cleanObjToUpdate.MixtureEntries
      delete cleanObjToUpdate.DriverEntries
      delete cleanObjToUpdate.ProjectActivityEntries
      delete cleanObjToUpdate.TotalMixtureAmount
      delete cleanObjToUpdate.WorkDuration
      delete cleanObjToUpdate.Project

      if (
        objectBefore.Crew.ID === objectAfter.Crew.ID
        && objectBefore.Day === objectAfter.Day
        && objectBefore.SortOrder === objectAfter.SortOrder
      ) {
        const {
          createProjectActivityEntry,
          updateProjectActivityEntry,
          deleteProjectActivityEntry,
          createEmployeeEntry,
          updateEmployeeEntry,
          deleteEmployeeEntry,
          createEquipmentEntry,
          updateEquipmentEntry,
          deleteEquipmentEntry,
          deleteEntry
        } = this.props

        if (changedEntry && changeTypeOfEntry && changedEntryType === "ProjectActivityEntry") {
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteProjectActivityEntry(changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                }
              } else {
                const errorOrResult = await createProjectActivityEntry({
                  ProjectActivity: { ID: entityID },
                  Pensum: { ID: objToUpdate.ID },
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                        ? Object.assign(obj, {
                          changedEntry: {
                            ...obj.changedEntry,
                            ID: errorOrResult.ID
                          }
                        })
                        : obj
                      ))
                    }
                  }));
                }
              }
              break;
              
            /* Modify */
            case 2:
              updateProjectActivityEntry(changedEntry)
              break;
                
            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createProjectActivityEntry({
                    ProjectActivity: { ID: entityID },
                    Pensum: { ID: objToUpdate.ID },
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                        ))
                      }
                    }));
                  }
                } else {
                  deleteProjectActivityEntry(changedEntry.ID)
                }
              }
              break;
          
            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "EmployeeEntry") {
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEmployeeEntry(changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                }
              } else {
                const errorOrResult = await createEmployeeEntry({
                  Employee: { ID: entityID },
                  Pensum: { ID: objToUpdate.ID },
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                        ? Object.assign(obj, {
                          changedEntry: {
                            ...obj.changedEntry,
                            ID: errorOrResult.ID
                          }
                        })
                        : obj
                      ))
                    }
                  }));
                }
              }
              break;
              
            /* Modify */
            case 2:
              updateEmployeeEntry(changedEntry)
              break;
                
            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEmployeeEntry({
                    Employee: { ID: entityID },
                    Pensum: { ID: objToUpdate.ID },
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                        ))
                      }
                    }));
                  }
                } else {
                  deleteEmployeeEntry(changedEntry.ID)
                }
              }
              break;
          
            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "EquipmentEntry") {
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEquipmentEntry(changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                }
              } else {
                const errorOrResult = await createEquipmentEntry({
                  Equipment: { ID: entityID },
                  Pensum: { ID: objToUpdate.ID },
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                        ? Object.assign(obj, {
                          changedEntry: {
                            ...obj.changedEntry,
                            ID: errorOrResult.ID
                          }
                        })
                        : obj
                      ))
                    }
                  }));
                }
              }
              break;
              
            /* Modify */
            case 2:
              updateEquipmentEntry(changedEntry)
              break;
                
            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEquipmentEntry({
                    Equipment: { ID: entityID },
                    Pensum: { ID: objToUpdate.ID },
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                        ))
                      }
                    }));
                  }
                } else {
                  deleteEquipmentEntry(changedEntry.ID)
                }
              }
              break;
          
            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "MixtureEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEntry("MixtureEntries", changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                }
              } else {
                const errorOrResult = await createEntry("MixtureEntries", {
                  Mixture: { ID: entityID },
                  Pensum: { ID: pensumId },
                  MixtureAmount: changedEntry.MixtureAmount,
                  Comment: changedEntry.Comment,
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                        ? Object.assign(obj, {
                          changedEntry: {
                            ...obj.changedEntry,
                            ID: errorOrResult.ID
                          }
                        })
                        : obj
                      ))
                    }
                  }));
                }
              }
              break;
              
            /* Modify */
            case 2:
              updateEquipmentEntry(changedEntry)
              break;
                
            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEntry("MixtureEntries", {
                    Mixture: { ID: entityID },
                    Pensum: { ID: pensumId },
                    MixtureAmount: changedEntry.MixtureAmount,
                    Comment: changedEntry.Comment,
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                    this.setState((prevState) => {
                      return {
                        history: {
                          ...prevState.history,
                          currentStep: step - 1,
                        }
                      }
                    })
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                        ))
                      }
                    }));
                  }
                } else {
                  await deleteEntry("MixtureEntries", changedEntry.ID)
                }
              }
              break;
          
            default:
              break;
          }
        }
        if (changedEntry && changeTypeOfEntry && changedEntryType === "DriverEntry") {
          const { createEntry } = this.props
          switch (Number(changeTypeOfEntry)) {
            /* Add */
            case 1:
              if (isBackward) {
                const errorOrResult = await deleteEntry("DriverEntries", changedEntry.ID)
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                }
              } else {
                const errorOrResult = await createEntry("DriverEntries", {
                  Driver: { ID: entityID },
                  Pensum: { ID: pensumId },
                  MixingPlant: { ID: changedEntry.MixingPlant.ID },
                  Payload: changedEntry.Payload,
                  StartTime: changedEntry.StartTime,
                  Comment: changedEntry.Comment,
                  SortOrder: changedEntry.SortOrder
                })
                if (errorOrResult instanceof Error) {
                  const indexOfHistoryPensum = chain.indexOf(chain[step])
                  chain.splice(indexOfHistoryPensum, 1)
                  this.setState((prevState) => { return { history: { ...prevState.history, currentStep: step - 1 } } })
                } else {
                  this.setState(prevState => ({
                    history: {
                      ...prevState.history,
                      chain: prevState.history.chain.map(obj => (
                        obj === chain[step]
                        ? Object.assign(obj, {
                          changedEntry: {
                            ...obj.changedEntry,
                            ID: errorOrResult.ID
                          }
                        })
                        : obj
                      ))
                    }
                  }));
                }
              }
              break;
              
            /* Modify */
            case 2:
              updateEquipmentEntry(changedEntry)
              break;
                
            /* Delete */
            case 3:
              if (changedEntry.ID) {
                if (isBackward) {
                  const errorOrResult = await createEntry("DriverEntries", {
                    Driver: { ID: entityID },
                    Pensum: { ID: pensumId },
                    MixingPlant: { ID: changedEntry.MixingPlant.ID },
                    Payload: changedEntry.Payload,
                    StartTime: changedEntry.StartTime,
                    Comment: changedEntry.Comment,
                    SortOrder: changedEntry.SortOrder
                  })
                  if (errorOrResult instanceof Error) {
                    const indexOfHistoryPensum = chain.indexOf(chain[step])
                    chain.splice(indexOfHistoryPensum, 1)
                    this.setState((prevState) => {
                      return {
                        history: {
                          ...prevState.history,
                          currentStep: step - 1,
                        }
                      }
                    })
                  } else {
                    this.setState(prevState => ({
                      history: {
                        ...prevState.history,
                        chain: prevState.history.chain.map(obj => (
                          obj === chain[step]
                          ? Object.assign(obj, {
                            changedEntry: {
                              ...obj.changedEntry,
                              ID: errorOrResult.ID
                            }
                          })
                          : obj
                        ))
                      }
                    }));
                  }
                } else {
                  await deleteEntry("DriverEntries", changedEntry.ID)
                }
              }
              break;
          
            default:
              break;
          }
        }
      } else {
        updatePensum({ [objToUpdate.ID]: cleanObjToUpdate })
      }

      this.setState((prevState) => {
        return {
          history: {
            ...prevState.history,
            currentStep: direction ? isBackward ? step - 1 : step : null,
            chain: newChain !== null ? newChain : chain
          }
        }
      })
    }
  }

  handleNewObjectInHistory = (pensumBefore, pensumAfter, changedEntryType, changedEntry, entityID, changeTypeOfEntry, isDeleted) => {
    const { history, history: { chain, currentStep } } = this.state

    const newChain = chain.slice(0, currentStep + 1)

    newChain.push({
      pensumId: pensumBefore.ID,
      objectBefore: pensumBefore,
      objectAfter: pensumAfter,
      changedEntryType: changedEntryType,
      changedEntry: changedEntry,
      changeTypeOfEntry: changeTypeOfEntry,
      entityID: entityID,
      isDeleted: isDeleted
    })

    /*
      handle problem when:
      delete entry, step back (revert 1),
      delete entry => bad currentstep calculation
    */
    this.setState({
      history: {
        ...history,
        chain: newChain,
        currentStep: newChain.length === 1 ? 0 : currentStep + 1
      }
    })
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const { pensums, columns } = this.state
    const { updatePensum, showNotification } = this.props
    const movingPensum = pensums.find(pensum => pensum.ID === parseInt(draggableId.split("-")[1]))
    const movingPensumStartCopy = Object.assign({}, movingPensum)
    const start = columns[source.droppableId]
    const finish = columns[destination.droppableId]
    const pensumsInStartColumn = start.pensums
    let objectsToUpdate = {}
    let crewToAssign
    
    this.state.crews.forEach(crew => {
      if (crew.ID === parseInt(destination.droppableId.split("/")[0])) {
        crewToAssign = crew
      }
    })

    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)

      newTaskIds.splice((source.index === 0 ? 0 : source.index), 1)
      newTaskIds.splice((destination.index === 0 ? 0 : destination.index), 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      }

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      }

      if (newTaskIds.length > 0) {
        let queryData = {}
        let newSortOrder
        let recalculated = false

        if (newTaskIds.length === 1) {
          newSortOrder = 0
        } else {
          const indexOfNewTaskId = newTaskIds.indexOf(`pensum-${draggableId.split("-")[1]}`)
          
          if (indexOfNewTaskId === 0) {
            const firstOldPensum = pensumsInStartColumn.find(pensum => pensum.ID === parseInt(start.taskIds[0].split("-")[1]))
            newSortOrder = firstOldPensum.SortOrder - 16
          } else if (indexOfNewTaskId === newTaskIds.length - 1) {
            const lastOldPensum = pensumsInStartColumn.find(pensum => pensum.ID === parseInt(start.taskIds[start.taskIds.length - 1].split("-")[1]))
            newSortOrder = lastOldPensum.SortOrder + 16
          } else {
            const indexOfPreviousPensum = indexOfNewTaskId - 1
            const indexOfNextPensum = indexOfNewTaskId + 1
            const previousPensumSortOrder = pensumsInStartColumn.find(pensum => pensum.ID === parseInt(newTaskIds[indexOfPreviousPensum].split("-")[1])).SortOrder
            const nextPensumSortOrder = pensumsInStartColumn.find(pensum => pensum.ID === parseInt(newTaskIds[indexOfNextPensum].split("-")[1])).SortOrder

            newSortOrder = previousPensumSortOrder + (nextPensumSortOrder - previousPensumSortOrder) / 2

            if (newSortOrder <= previousPensumSortOrder || newSortOrder >= nextPensumSortOrder) {
              newSortOrder = -16

              newTaskIds.forEach(ID => {
                const id = Number(ID.split("-")[1])
                const ModificationTimeUtc = moment().utc().format()

                newSortOrder += 16

                pensumsInStartColumn.find(pensum => pensum.ID === id).SortOrder = newSortOrder

                queryData = { SortOrder: newSortOrder, ModificationTimeUtc }
                
                if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
                  objectsToUpdate[Number(ID.split("-")[1])] = {
                    ...objectsToUpdate[Number(ID.split("-")[1])], ...queryData
                  }
                }
              })

              recalculated = true
            }
          }

          if (!recalculated) {
            movingPensum.SortOrder = newSortOrder

            queryData = { ...queryData, SortOrder: newSortOrder, ModificationTimeUtc: moment().utc().format() }

            if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
              objectsToUpdate[draggableId.split("-")[1]] = {
                ...objectsToUpdate[draggableId.split("-")[1]], ...queryData
              }
            }
          }
        }
      }

      let errorOrResult

      if (Object.keys(objectsToUpdate).length !== 0 && objectsToUpdate.constructor === Object) {
        Object.keys(objectsToUpdate).forEach(async key => {
          errorOrResult = await updatePensum({ [key]: objectsToUpdate[key] })
        })
      }

      if (errorOrResult instanceof Error) {
        showNotification("UNSUCCESSFUL_UPDATE_PENSUM", "error")
      } else {
        const { history, history: { chain, currentStep } } = this.state
        const movingPensumFinishCopy = Object.assign({}, movingPensum)

        chain.slice(0, currentStep + 1)

        chain.push({
          pensumId: movingPensumStartCopy.ID,
          objectBefore: movingPensumStartCopy,
          objectAfter: movingPensumFinishCopy
        })

        this.setState({
          ...newState,
          history: {
            ...history,
            currentStep: chain.length === 1 ? 0 : currentStep + 1
          }
        })
      }

      return
    }

    // Moving from one column to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice((source.index === 0 ? 0 : source.index), 1)
    
    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)

    const newStart = {
      ...start,
      taskIds: startTaskIds
    }

    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    }

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }

    let queryData = {}
    const draggedPensum = movingPensum
    const columnDay = destination.droppableId.split("/")[1]
    if (crewToAssign !== undefined && JSON.stringify(crewToAssign) !== JSON.stringify(draggedPensum.Crew)) {
      draggedPensum.Crew = crewToAssign
      queryData = { ...queryData, Crew: crewToAssign, ModificationTimeUtc: moment().utc().format() }
    }
    if (columnDay !== undefined && moment(columnDay).format("YYYY-MM-DD") !== moment(draggedPensum.Day).format("YYYY-MM-DD")) {
      draggedPensum.Day = columnDay
      queryData = { ...queryData, Day: columnDay, ModificationTimeUtc: moment().utc().format() }
    }
    draggedPensum.ModificationTimeUtc = moment().utc().format()
    
    if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
      objectsToUpdate[draggedPensum.ID] = {
        ...objectsToUpdate[draggedPensum.ID], ...queryData
      }
    }

    if (finishTaskIds.length > 0) {
      let queryData = {}
      let newSortOrder
      let recalculated = false

      if (finishTaskIds.length === 1) {
        newSortOrder = 0
      } else {
        const indexOfNewTaskId = finishTaskIds.indexOf(`pensum-${draggableId.split("-")[1]}`)
        
        if (indexOfNewTaskId === 0) {
          const firstOldPensum = pensums.find(pensum => pensum.ID === parseInt(finish.taskIds[0].split("-")[1]))
          newSortOrder = firstOldPensum.SortOrder - 16
        } else if (indexOfNewTaskId === finishTaskIds.length - 1) {
          const lastOldPensum = pensums.find(pensum => pensum.ID === parseInt(finish.taskIds[finish.taskIds.length - 1].split("-")[1]))
          newSortOrder = lastOldPensum.SortOrder + 16
        } else {
          const indexOfPreviousPensum = indexOfNewTaskId - 1
          const indexOfNextPensum = indexOfNewTaskId + 1
          const previousPensumSortOrder = pensums.find(pensum => pensum.ID === parseInt(finishTaskIds[indexOfPreviousPensum].split("-")[1])).SortOrder
          const nextPensumSortOrder = pensums.find(pensum => pensum.ID === parseInt(finishTaskIds[indexOfNextPensum].split("-")[1])).SortOrder

          newSortOrder = previousPensumSortOrder + (nextPensumSortOrder - previousPensumSortOrder) / 2

          if (newSortOrder <= previousPensumSortOrder || newSortOrder >= nextPensumSortOrder) {
            newSortOrder = -16

            finishTaskIds.forEach(ID => {
              const id = Number(ID.split("-")[1])
              const ModificationTimeUtc = moment().utc().format()

              newSortOrder += 16

              pensumsInStartColumn.find(pensum => pensum.ID === id).SortOrder = newSortOrder

              queryData = { SortOrder: newSortOrder, ModificationTimeUtc }
              
              if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
                objectsToUpdate[Number(ID.split("-")[1])] = {
                  ...objectsToUpdate[Number(ID.split("-")[1])], ...queryData
                }
              }
            })

            recalculated = true
          }
        }
      }

      if (!recalculated) {
        movingPensum.SortOrder = newSortOrder

        queryData = { ...queryData, SortOrder: newSortOrder, ModificationTimeUtc: moment().utc().format() }

        if (Object.keys(queryData).length !== 0 && queryData.constructor === Object) {
          objectsToUpdate[draggableId.split("-")[1]] = {
            ...objectsToUpdate[draggableId.split("-")[1]], ...queryData
          }
        }
      }
    }

    let errorOrResult
    
    if (Object.keys(objectsToUpdate).length !== 0 && objectsToUpdate.constructor === Object) {
      Object.keys(objectsToUpdate).forEach(async key => {
        errorOrResult = await updatePensum({ [key]: objectsToUpdate[key] })
      })
    }

    if (errorOrResult instanceof Error) {
      showNotification("UNSUCCESSFUL_UPDATE_PENSUM", "error")
    } else {
      const { history, history: { chain, currentStep } } = this.state
      const movingPensumFinishCopy = Object.assign({}, movingPensum)

      const newChain = Array.from(chain.slice(0, currentStep + 1))

      newChain.push({
        pensumId: movingPensumStartCopy.ID,
        objectBefore: movingPensumStartCopy,
        objectAfter: movingPensumFinishCopy
      })

      this.setState({
        ...newState,
        history: {
          ...history,
          currentStep: newChain.length === 1 ? 0 : currentStep + 1,
          chain: newChain
        }
      })
    }
  }

  render() {
    const {
      isMounted,
      isFetching,
      popupIsOpen,
      crews,
      pensums,
      dates,
      selectedFromDate,
      selectedToDate,
      customer,
      supervisor,
      selectedCrew,
      mixingPlant,
      history,
      view
    } = this.state;
    const { projects, specialDays, t, userCrewName } = this.props
    let mixtureAmountSumArray = []
    let driverPayloadSumArray = []
    let totalMixtureAmountSumArray = []
    let filteredCrews = []
    let filteredPensums = pensums

    if (isMounted) {
      filteredPensums = filteredPensums
        .filter(pensum => customer ? pensum.Project.Customer.ID === Number(customer) : pensum)
        .filter(pensum => customer ? pensum.Project.Customer.ID === Number(customer) : pensum)
        .filter(pensum => supervisor ? pensum.Project.Supervisor.ID === Number(supervisor) : pensum)
        .filter(pensum => selectedCrew ? pensum.Crew.ID === Number(selectedCrew) : pensum)
        .filter(pensum => mixingPlant ? pensum.MixtureEntries.some(({ Mixture: { Order: { MixingPlant: { ID } } } }) => Number(mixingPlant) === ID) : pensum)

      if (view.id === 2 && userCrewName !== null) {
        filteredCrews = crews.filter(crew => crew.Name.toLowerCase() === userCrewName.toLowerCase())
      } else {
        filteredCrews = crews
      }

      dates.forEach(day => {
        let mixtureAmountSum = 0
        let driverPayloadSum = 0
        let mixtreAmountSum = 0
        filteredPensums.forEach(pensum => {
          if (moment(day).format("YYYY-MM-DD") === moment(pensum.Day).format("YYYY-MM-DD")) {
            if (pensum.MixtureEntries) {
              pensum.MixtureEntries.forEach(mix => {
                mixtureAmountSum += mix.MixtureAmount
              })
            }
            if (pensum.DriverEntries) {
              pensum.DriverEntries.forEach(driver => {
                driverPayloadSum += driver.Payload
              })
            }

            mixtreAmountSum += pensum.TotalMixtureAmount
          }
        })
        mixtureAmountSumArray.push(mixtureAmountSum)
        driverPayloadSumArray.push(driverPayloadSum)
        totalMixtureAmountSumArray.push(mixtreAmountSum)
      })
    }

    if (dates.length === 0 && selectedFromDate && selectedToDate) {
      const fromDate = moment(selectedFromDate)
      const endDate = moment(selectedToDate)
      dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      while (fromDate.dayOfYear() < endDate.dayOfYear()) {
        fromDate.add(1, 'days')
        dates.push(`${fromDate.format("YYYY-MM-DD")}`)
      }
    }

    return (
      <>
        {
          isMounted ? (
            <div className="planning-container">
              <Backdrop style={{ zIndex: 9 }} open={isFetching}>
                <CircularProgress color="secondary" />
              </Backdrop>
              <FilterContainer
                handleFromDateChange={this.handleFromDateChange}
                handleToDateChange={this.handleToDateChange}
                handleCustomerChange={this.handleCustomerChange}
                handleSupervisorChange={this.handleSupervisorChange}
                handleCrewChange={this.handleCrewChange}
                handleMixingPlantChange={this.handleMixingPlantChange}
                handleViewChange={this.handleViewChange}
                userCrewName={userCrewName}
                {...this.state}
              />
              <div style={{ margin: filteredCrews.length === 1 ? "0 auto" : null, width: view.id === 2 && filteredCrews.length === 1 ? 420 : null }}>
                <CrewContainer
                  crews={filteredCrews}
                  isFetching={isFetching}
                  handleHistoryChange={this.handleHistoryChange}
                  history={history}
                />
                <DragDropContext onDragEnd={this.onDragEnd}>
                  {dates.map((day, x) => (
                    <Container key={x}>
                      <FirstColumnContainer isFirst={x===0}>
                        <DateColumn day={moment(day).day()}>
                          <div style={{
                            paddingTop: 4,
                            paddingBottom: 2,
                            textShadow: "0px 0px 1px rgb(170, 170, 170)",
                            color: "#0080C0",
                            fontSize: 16
                          }}>
                            {moment(day).format("YYYY.MM.DD")}
                          </div>
                          <div style={{
                            backgroundColor: moment(day).day() === 6 || moment(day).day() === 0 ? "rgb(62, 62, 62)" : "#0080C0",
                            fontFamily: "Open Sans",
                            textShadow: "0px 2px 2px rgba(0, 0, 0)",
                            color: "#ffffff",
                            paddingTop: 5,
                            paddingBottom: 8,
                            fontSize: 26
                          }}>
                            {t(`day-${moment(day).day()}`)}
                          </div>
                          <div style={{
                            width: 72,
                            margin: "0 auto",
                            fontSize: 14,
                            textShadow: "0px 0px 1px rgba(0, 0, 0, 0.35)",
                            padding: 4,
                          }}>
                            {t('week')} {moment(day).day() === 0 ? moment(day).week() - 1 : moment(day).week()}
                          </div>
                        </DateColumn>
                        {driverPayloadSumArray[x] > 0 ? (<div style={{
                          width: 95,
                          marginTop: 5,
                          marginBottom: 4,
                          marginLeft:
                            String(totalMixtureAmountSumArray[x]).length < 2 ?
                            21 : String(totalMixtureAmountSumArray[x]).length < 4 ?
                            15 : String(totalMixtureAmountSumArray[x]).length < 5 ?
                            10 : String(totalMixtureAmountSumArray[x]).length > 5 ? 4 : 7,
                          color: "#f7f7f7"
                        }}>
                          <div style={{
                            float: "left",
                            width: 23,
                            height: 20,
                            borderBottom: "thin solid rgba(100, 100, 100, 0.73)",
                            display: "inline-block"
                          }}>
                            <DriverIcon style={{ color: "#fca623" }}/>
                          </div>
                          <div id="driverPayloadSum" style={{
                            display: "inline-block",
                            height: 15,
                            minWidth: 30,
                            textAlign: "center",
                            borderRadius: 2,
                            backgroundColor: "rgba(100, 100, 100, 0.73)",
                            boxShadow: "0px 0px 0px 2px rgba(100, 100, 100, 0.73)",
                            fontFamily: "Oswald, sans-serif",
                            letterSpacing: 0.5,
                            fontSize: 14,
                            marginLeft: 4,
                            marginTop: 5
                          }}>
                            <div style={{ marginTop: -3, paddingLeft: 3, paddingRight: 3 }}>
                              {driverPayloadSumArray[x]} to
                            </div>
                          </div>
                        </div>) : null}
                        {totalMixtureAmountSumArray[x] > 0 ? (<div style={{
                          width: 95,
                          marginTop: 5,
                          marginBottom: 4,
                          marginLeft:
                            String(mixtureAmountSumArray[x]).length < 2 ?
                            21 : String(mixtureAmountSumArray[x]).length < 4 ?
                            15 : String(mixtureAmountSumArray[x]).length < 5 ?
                            10 : String(mixtureAmountSumArray[x]).length > 5 ? 4 : 7,
                          color: "#f7f7f7"
                        }}>
                          <div style={{
                            float: "left",
                            width: 24,
                            height: 20,
                            display: "inline-block"
                          }}>
                            <img src={DriverMixtureIcon} style={{ width: 24, height: 24 }} alt="" />
                          </div>
                          <div id="driverPayloadSum" style={{
                            display: "inline-block",
                            height: 15,
                            minWidth: 30,
                            textAlign: "center",
                            borderRadius: 2,
                            backgroundColor: "rgba(100, 100, 100, 0.73)",
                            boxShadow: "0px 0px 0px 2px rgba(100, 100, 100, 0.73)",
                            fontFamily: "Oswald, sans-serif",
                            letterSpacing: 0.5,
                            fontSize: 14,
                            marginLeft: 4,
                            marginTop: 5
                          }}>
                            <div style={{ marginTop: -3, paddingLeft: 3, paddingRight: 3 }}>
                              {totalMixtureAmountSumArray[x]} to
                            </div>
                          </div>
                        </div>) : null}
                        {mixtureAmountSumArray[x] > 0 ? (<div style={{
                          width: 95,
                          height: 24,
                          marginTop: 6,
                          color: "#f7f7f7",
                          marginLeft:
                            String(mixtureAmountSumArray[x]).length < 2 ?
                            21 : String(mixtureAmountSumArray[x]).length < 4 ?
                            15 : String(mixtureAmountSumArray[x]).length < 5 ?
                            10 : String(mixtureAmountSumArray[x]).length > 5 ? 4 : 7,
                        }}>
                          <div style={{
                            float: "left",
                            width: 23,
                            height: 20,
                            display: "inline-block"
                          }}>
                            <MixtureIcon style={{ color: "#fca623" }}/>
                          </div>
                          <div id="mixtureAmountSum" style={{
                            display: "inline-block",
                            height: 15,
                            minWidth: 30,
                            textAlign: "center",
                            borderRadius: 2,
                            backgroundColor: "rgba(100, 100, 100, 0.73)",
                            boxShadow: "0px 0px 0px 2px rgba(100, 100, 100, 0.73)",
                            fontFamily: "Oswald, sans-serif",
                            letterSpacing: 0.5,
                            fontSize: 14,
                            marginLeft: 4,
                            marginTop: 4
                          }}>
                            <div style={{ marginTop: -3, paddingLeft: 3, paddingRight: 3 }}>
                              {mixtureAmountSumArray[x]} to
                            </div>
                          </div>
                        </div>) : null}
                      </FirstColumnContainer>
                      {filteredCrews.map((crew, i) => {
                        const columnId = `${crew.ID}/${day}`
                        const pensumsInColumn = [];
                        let column = this.state.columns[columnId];
                        
                        if (column === undefined) {
                          column = {
                            id: columnId,
                            taskIds: []
                          }
                        }

                        const taskIds = column.taskIds;
                        let workdurationInMinutes = 0
                        taskIds.forEach(id => {
                          const taskId = id.split("-")[1];

                          filteredPensums.forEach(pensum => {
                            let CostCenter
                            projects.forEach(project => {
                              if (pensum.Project && project.ID === pensum.Project.ID) CostCenter = project.CostCenter
                            })
                            if (
                                `${pensum.ID}` === `${taskId}`
                                && moment(day).format("YYYY-MM-DD") === moment(pensum.Day).format("YYYY-MM-DD")
                                && pensum.Crew.ID === crew.ID
                              ) {
                              const { WorkDuration } = pensum
                              if (WorkDuration) {
                                if (!(WorkDuration.includes("H"))) {
                                  workdurationInMinutes += Number(WorkDuration.replace("PT","").replace("M","").replace("S",""))
                                } else {
                                  const workDuration = WorkDuration.replace("PT","").replace("H",":").replace("M","").replace("S","")
                                  workdurationInMinutes += Number(workDuration.split(":")[0] * 60)
                                  workdurationInMinutes += Number(workDuration.split(":")[1])
                                }
                              }
                              pensumsInColumn.push({ pensum: pensum, CostCenter: CostCenter });
                            }
                          })
                        })

                        const today = moment(day)

                        const specialDaysInColumn = []
                        let publicHolidayName
                        specialDays.forEach(sDay => {
                          const dateFrom = moment(sDay.DateFrom)
                          const dateTo = moment(sDay.DateTo)
                          let specialDayObj = {}
                          if (today.diff(dateFrom) >= 0 && today.diff(dateTo) <= 0) {
                            specialDayObj.name = sDay.Name
                            if (sDay.IsPublicHoliday) {
                              specialDayObj.isPublicHoliday = true
                            } else {
                              specialDayObj.isPublicHoliday = false
                            }
                            specialDaysInColumn.push(specialDayObj)
                          }
                        })

                        let workloadIndication = "grey"
                        if (
                          today.day() === 1
                          || today.day() === 2
                          || today.day() === 3
                          || today.day() === 4
                        ) {
                          /* 0-7 hours */
                          if (workdurationInMinutes >= 0 && workdurationInMinutes <= 420) {
                            workloadIndication = "red"
                          }
                          /* 7-9 hours */
                          if (workdurationInMinutes >= 420 && workdurationInMinutes <= 540) {
                            workloadIndication = "yellow"
                          }
                          /* > 9 hours */
                          if (workdurationInMinutes > 540) {
                            workloadIndication = "green"
                          }
                        }
                        if (today.day() === 5) {
                          /* 0-5 hours */
                          if (workdurationInMinutes >= 0 && workdurationInMinutes <= 300) {
                            workloadIndication = "red"
                          }
                          /* > 5 hours */
                          if (workdurationInMinutes > 300) {
                            workloadIndication = "green"
                          }
                        }

                        const sortedPensums = pensumsInColumn.sort((a, b) => {
                          if (a.pensum.SortOrder - b.pensum.SortOrder !== 0) {
                            return a.pensum.SortOrder - b.pensum.SortOrder
                          } else {
                            return a.pensum.ID - b.pensum.ID
                          }
                        })

                      return (
                        <PensumsContainer
                          key={columnId}
                          columnId={columnId}
                          tasks={sortedPensums}
                          day={moment(day).day()}
                          handleCopy={this.handleCopy}
                          popupIsOpen={popupIsOpen}
                          handleNewObjectInHistory={this.handleNewObjectInHistory}
                          specialDaysInColumn={specialDaysInColumn}
                          publicHolidayName={publicHolidayName}
                          workloadIndication={workloadIndication}
                          {...this.state}
                          {...this.props}
                        />
                      )
                    })}
                  </Container>
                ))}
              </DragDropContext>
            </div>
          </div>
          ) : <div><Loader /></div>
        }
      </>
    )
  }
}
