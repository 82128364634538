import React from "react";
import ReactDOM from "react-dom";
import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import './index.css';

class MixtureTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      editable: props.editable ? props.editable : '',
      mixtureOrderRow: props.mixtureOrderRow,
      newData: null,
      error: null,
      stateIsUpdated: false
    };
  }

  componentDidMount() {

    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      var child = node.querySelector('.MuiTableBody-root');
      if (child !== undefined) {
        child.children[0].style.height = "43.5px";
      }
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.data !== props.data) {
      if (!this.state.stateIsUpdated)
        this.setState({ data: props.data, selectedRow: null })
    }
  }

  render() {
    const { t } = this.props;

    const columns = [
      { title: t('name'), field: "Name", defaultSort: "asc", editComponent: props => (
        <FormControl>
          <InputLabel className="trans-label-name" id="org-label">*</InputLabel>
          <TextField
            id="name-input"
            value={props.value}
            type="text"
            error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
            inputProps={{maxLength: 255}}
            onChange={e => props.onChange(e.target.value)}
          />
        </FormControl>
      ), filtering: false },
      { title: t('article-number'), field: "ArticleNumber", filtering: false, editComponent: props => (
        <FormControl>
          <TextField
            id="article-number-input"
            value={props.value}
            type="text"
            inputProps={{maxLength: 255}}
            onChange={e => props.onChange(e.target.value)}
          />
        </FormControl>
      ) },
    ];

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={this.state.data}
        options={{
          pageSize: 10,
          pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20] : [5],
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true,
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          }
        }}
        editable={this.state.editable && {
          onRowAdd: newData =>
            new Promise(resolve => {
              this.setState({
                newData: newData
              });
              setTimeout(async () => {
                resolve();
                this.setState({ stateIsUpdated: true });
                const errorOrResult = await this.props.onMixtureRowAdd({
                  Name: newData.Name,
                  ArticleNumber: newData.ArticleNumber,
                  Order: {
                    ID: this.props.mixtureOrderRow.ID
                  }
                });
                this.setState({ stateIsUpdated: false });
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    newData.ID = errorOrResult.ID;
                    data.push(newData);
                    return { ...prevState, data };
                  });
                }

              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                if (oldData) {
                  this.setState({ stateIsUpdated: true });
                  const errorOrResult = await this.props.onMixtureRowUpdate({
                    ...newData,
                  }, oldData.tableData);
                  this.setState({ stateIsUpdated: false });
                  if (!(errorOrResult instanceof Error)) {
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                const errorOrResult = await this.props.onMixtureRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    if (data.indexOf(oldData) >= 0)
                      data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }
              }, 100);
            })
        }}
      />
    );
  }
}

export default MixtureTable;