import {
  GET_MIXTUREORDER_REQUEST,
  GET_MIXTUREORDER_ERROR,
  GET_MIXTUREORDER_SUCCESS,
  CREATE_MIXTUREORDER_REQUEST,
  CREATE_MIXTUREORDER_ERROR,
  CREATE_MIXTUREORDER_SUCCESS,
  UPDATE_MIXTUREORDER_REQUEST,
  UPDATE_MIXTUREORDER_ERROR,
  UPDATE_MIXTUREORDER_SUCCESS,
  DELETE_MIXTUREORDER_REQUEST,
  DELETE_MIXTUREORDER_ERROR,
  DELETE_MIXTUREORDER_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_MIXTUREORDER_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_MIXTUREORDER_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_MIXTUREORDER_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_MIXTUREORDER_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_MIXTUREORDER_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_MIXTUREORDER_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_MIXTUREORDER_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_MIXTUREORDER_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_MIXTUREORDER_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_MIXTUREORDER_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_MIXTUREORDER_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_MIXTUREORDER_SUCCESS;
  reducers.entityName = "mixtureOrder";

  return reducers.entity(reducers)(state, action);
};
