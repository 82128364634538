import odata from 'odata-client';
import {
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_ERROR,
    CHANGE_PASSWORD_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';

const LoginStatus_LoggedIn = 2**2;
const LoginStatus_SetNewPassword = 2**3;
const LoginStatus_PasswordExpired = 2**4;

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const changePasswordRequest = () => ({
    type: CHANGE_PASSWORD_REQUEST,
});

export const changePasswordError = errorOrResults => ({
    type: CHANGE_PASSWORD_ERROR,
    error: errorOrResults.message,
});

export const changePasswordSuccess = payload => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload,
});

export default (queryData, environmentDisplayName) => async (dispatch, getState) => {
    dispatch(changePasswordRequest());

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.LoggedInUsers).resource(RESOURCES.ChangePassword);
    const errorOrResult = await query.post(queryData, {
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            ...getDefaultHeader()
        }
    })
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('change password api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return { error: new Error(errorKey) };
            }
            if (ok) {
                const body = JSON.parse(res.body);
                const { "@odata.context": omit, ...payload } = body;
                const isLoggedIn = payload.LoginStatusCode & LoginStatus_LoggedIn
                const isPasswordExpired = payload.LoginStatusCode & LoginStatus_PasswordExpired
                const isSetNewPassword = payload.LoginStatusCode & LoginStatus_SetNewPassword

                payload.isLoggedIn = isLoggedIn
                payload.isPasswordExpired = isPasswordExpired
                payload.isSetNewPassword = isSetNewPassword
                payload.environmentDisplayName = environmentDisplayName
                payload.companyLogoUrl = getState().user.companyLogoUrl

                sessionStorage.setItem("user", JSON.stringify(payload))

                return payload
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult && errorOrResult.error) {
        dispatch(changePasswordError(errorOrResult));
        if (errorOrResult.error.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.error.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.error.message);
        dispatch(showNotification(errorOrResult.error.message, "error"))
        return errorOrResult;
    } else {
        dispatch(showNotification("SUCCESSFUL_SAVE_PASSWORD", "success"))
    }

    dispatch(changePasswordSuccess(errorOrResult));

    return errorOrResult;
};