import {
  GET_SPECIALDAY_REQUEST,
  GET_SPECIALDAY_ERROR,
  GET_SPECIALDAY_SUCCESS,
  CREATE_SPECIALDAY_REQUEST,
  CREATE_SPECIALDAY_ERROR,
  CREATE_SPECIALDAY_SUCCESS,
  UPDATE_SPECIALDAY_REQUEST,
  UPDATE_SPECIALDAY_ERROR,
  UPDATE_SPECIALDAY_SUCCESS,
  DELETE_SPECIALDAY_REQUEST,
  DELETE_SPECIALDAY_ERROR,
  DELETE_SPECIALDAY_SUCCESS
} from "../../actions/types";
import reducers from "../common"

const initialState = {};
export default (state = initialState, action) => {

  reducers.init(reducers);

  reducers.GET_ENTITY_REQUEST = GET_SPECIALDAY_REQUEST;
  reducers.GET_ENTITY_ERROR = GET_SPECIALDAY_ERROR;
  reducers.GET_ENTITY_SUCCESS = GET_SPECIALDAY_SUCCESS;
  reducers.CREATE_ENTITY_REQUEST = CREATE_SPECIALDAY_REQUEST;
  reducers.CREATE_ENTITY_ERROR = CREATE_SPECIALDAY_ERROR;
  reducers.CREATE_ENTITY_SUCCESS = CREATE_SPECIALDAY_SUCCESS;
  reducers.UPDATE_ENTITY_REQUEST = UPDATE_SPECIALDAY_REQUEST;
  reducers.UPDATE_ENTITY_ERROR = UPDATE_SPECIALDAY_ERROR;
  reducers.UPDATE_ENTITY_SUCCESS = UPDATE_SPECIALDAY_SUCCESS;
  reducers.DELETE_ENTITY_REQUEST = DELETE_SPECIALDAY_REQUEST;
  reducers.DELETE_ENTITY_ERROR = DELETE_SPECIALDAY_ERROR;
  reducers.DELETE_ENTITY_SUCCESS = DELETE_SPECIALDAY_SUCCESS;
  reducers.entityName = "specialday";

  reducers.entity = (reducers.entity).bind(null, reducers);
  return reducers.entity(reducers)(state, action);
};