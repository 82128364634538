import odata from 'odata-client';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import {
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_ERROR,
  TOKEN_EXPIRED
} from "../types";
import { showNotification } from '../notification';
import { getAppSettings } from '../settings';

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const acRequest = type => ({
  type
});

export const acError = (error, type) => ({
  type,
  error
});

export const acSuccess = (type) => ({
  type
});

export default () => async (dispatch, getState) => {
  dispatch(acRequest(USER_LOGOUT_REQUEST));
  
  const query = odata({service: getServiceUrl()}).resource(RESOURCES.LoggedInUsers).resource(RESOURCES.Logout);

  const errorOrResult = query.post({}, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
        const ok = res.statusCode <= 299 && res.statusCode >= 200;

        if (res.statusCode === 500) {
          const errorKey = "GENERAL_SERVER_ERROR";
          return new Error(errorKey);
        } else if (!ok) {
          console.log('login api error', res.toJSON());
          const body = JSON.parse(res.body);
          const { Error: { Message } } = body
          const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
          return new Error(errorKey);
        } else {
          const body = JSON.parse(res.body);
          const payload = body.value;

          sessionStorage.removeItem("user")

          return payload;
        }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    })
  
  if (errorOrResult instanceof Error) {
    dispatch(acError(errorOrResult, USER_LOGOUT_ERROR));
    if (errorOrResult.error.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    if (errorOrResult) {
      dispatch(
        acSuccess(
          USER_LOGOUT_SUCCESS
        )
      );
      await getAppSettings()
    } else {
      dispatch(acError(new Error("logout request returned with value: false"), USER_LOGOUT_ERROR));
      dispatch(showNotification("LOGOUT_REQUEST_FAILED", "error"))
    }
  }

  return errorOrResult;
};
