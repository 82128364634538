import {
  DELETE_SPECIALDAY_REQUEST,
  DELETE_SPECIALDAY_ERROR,
  DELETE_SPECIALDAY_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/deleteEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default entity => (state = initialState, action) => {

  context.init(context);

  context.DELETE_ENTITY_REQUEST = DELETE_SPECIALDAY_REQUEST;
  context.DELETE_ENTITY_ERROR = DELETE_SPECIALDAY_ERROR;
  context.DELETE_ENTITY_SUCCESS = DELETE_SPECIALDAY_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "specialday";
  context.entityLocation = RESOURCES.SpecialDays;

  return context.execute(context)(entity)(state, action);
};