import React from "react";
import MaterialTable from 'material-table';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import './index.css';

class EmployeeTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      organisations: props.organisations ? props.organisations : '',
      crews: props.crews ? props.crews : '',
      settings: props.settings ? props.settings : '',
      editable: props.editable ? props.editable : '',
      selectedFilters: [],
      newData: null,
      error: null,
    };
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }
  }

  rgbToHex(rgb) {
    if (rgb === null || rgb === "null" || rgb === "")
      rgb = 0x00FFFFFF;

    var hex = (0x00FFFFFF & Number(rgb)).toString(16).padStart(6, '0');
    return "#" + hex;
  }

  render() {
    const { organisations, crews, settings, selectedFilters } = this.state;
    const { t } = this.props;
    const lookupOrgs = {};
    const lookupCrews = {};
    const lookupColors = {};

    const columns = [
      {
        title: t('organisation'), field: 'Organisation.Name', lookup: lookupOrgs, defaultFilter: this.state.selectedFilters,
        initialEditValue: selectedFilters.length > 0 ? selectedFilters : null,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        ),
        editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-organisation" id="org-label">*</InputLabel>
            <Select
              labelId="org-label"
              id="organisation"
              className="organisationSelect"
              value={props.value}
              onChange={e => props.onChange(e.target.value)}
              autoFocus
              error={props.value === null || props.value === undefined}
              onClick={async () => {
                await new Promise(resolve => setTimeout(() => resolve(), 100));
                const { organisations } = this.state;
                const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
                /*const waitUntilPopupIsMounted = async () => {
                  await new Promise(resolve => setTimeout(() => resolve(), 100));
                  if (!organisationPopup) {
                    await waitUntilPopupIsMounted();
                  }
                };*/

                if (organisationPopup) {
                  const orgsList = organisationPopup.childNodes[0].childNodes;
                  if (orgsList) {
                    const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
                    let i = 0;
                    orgsList.forEach(orgElement => {
                      const orgObj = organisations.find(item => item.Name === orgElement.getAttribute("data-value"));
                      const orgText = document.getElementsByClassName("MuiListItem-button")[i];
                      if (orgObj.Level === topLevel) {
                        orgText.style.paddingLeft = "10px";
                      } else if (orgObj.Level > topLevel) {
                        const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 15;
                        orgText.style.paddingLeft = `${margin}px`;
                      }
                      i++;
                    })
                  }
                } /*else if (!organisationPopup) {
                    await waitUntilPopupIsMounted();
                  }*/
              }}
            >
              {Object.keys(props.columnDef.lookup).map(key => (
                selectedFilters.length === 0 || selectedFilters.includes(key) ?
                  <MenuItem
                    value={key}
                  >
                    <em>{props.columnDef.lookup[key]}</em>
                  </MenuItem>
                  : null
              ))}
            </Select>
          </FormControl>
        )
      },
      {
        title: t('construction-crew'), field: 'Crew.Name', filtering: true, lookup: lookupCrews,
        customFilterAndSearch: (term, rowData) =>
          //search
          (
            (typeof term) === "string" &&
            (
              term === '' ||
              (rowData.Crew != null && rowData.Crew.Name.includes(term))
            )
          ) ||
          //filter
          (
            (typeof term) !== "string" &&
            (
              term.length === 0 ||
              (rowData.Crew == null && term.includes('null')) ||
              (rowData.Crew != null && term.includes(rowData.Crew.Name))
            )
          ),
        editComponent: props => (
          <Select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          >
            {Object.keys(props.columnDef.lookup).map(key => (
              <MenuItem
                value={key}
              >
                <em>{props.columnDef.lookup[key]}</em>
              </MenuItem>
            ))}
          </Select>
        )
      },
      {
        title: t('construction-supervisor-color'), field: 'ColorRgb', sorting: false, filtering: false, lookup: lookupColors,
        render: rowData => (rowData.IsSupervisor ? <div style={{ backgroundColor: this.rgbToHex(rowData.ColorRgb), width: '75%' }}>&nbsp;</div> : null),
        editComponent: props => (
          <FormControl>
            {
              props.rowData.IsSupervisor ?
                <Select
                  value={props.value}
                  onChange={e => props.onChange(e.target.value)}
                  renderValue={value => <div style={{ backgroundColor: this.rgbToHex(value), width: '75%' }}>{lookupColors[value]}</div>}
                >
                  {Object.keys(props.columnDef.lookup).map(key => (
                    <MenuItem style={{ paddingLeft: '6px', paddingRight: '6px', paddingBottom: '3px', paddingTop: '3px' }}
                      value={key}
                    >
                      <div style={{ backgroundColor: this.rgbToHex(key), height: '100%', width: '110px', paddingLeft: '6px' }}>
                        <em>{props.columnDef.lookup[key]}</em>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                : null
            }
          </FormControl>
        )
      },
      {
        title: t('last-name'), field: 'LastName', defaultSort: "asc", sorting: true, editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-name" id="org-label">*</InputLabel>
            <TextField
              id="last-name-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('first-names'), field: 'FirstNames', defaultSort: "asc", sorting: true, editComponent: props => (
          <FormControl>
            <InputLabel className="trans-label-name" id="org-label">*</InputLabel>
            <TextField
              id="first-names-input"
              value={props.value}
              type="text"
              error={props.value === null || props.value === undefined || props.value.length < 1 || props.value.trim() === ""}
              inputProps={{ maxLength: 255 }}
              onChange={e => props.onChange(e.target.value)}
            />
          </FormControl>
        ), filtering: false
      },
      {
        title: t('construction-supervisor'), field: 'IsSupervisor', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsSupervisor} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      },
      {
        title: t('is-deleted'), field: 'IsDeleted', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsDeleted} disabled={true} />
        ),
        editComponent: props => (
          <Checkbox
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
            value={props.value}
          />
        ),
        filtering: true
      }
    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
    }

    lookupCrews[null] = '\xA0';
    if (crews.length > 0) {
      crews.forEach(crew => {
        lookupCrews[crew.Name] = crew.Name;
      })
    }

    lookupColors[""] = t('select-option-no-selection');
    if (settings !== "" && settings.SupervisorColors !== undefined && settings.SupervisorColors.length > 0) {
      var colors = settings.SupervisorColors.replace('[', '').replace(']', '').split(',')
      if (colors.length > 0) {
        colors.forEach(color => {
          var c = parseInt(color);
          lookupColors[c] = '\xA0';
        })
      }
    }

    return (
      <MaterialTable
        title={this.state.title}
        columns={columns}
        data={this.state.data}
        options={{
          pageSize: this.state.data.length > 5 ? 10 : 5,
          pageSizeOptions: this.state.data.length > 5 ? [5, 10, 20] : [5],
          paginationType: "normal",
          addRowPosition: "first",
          draggable: false,
          filtering: true
        }}
        localization={{
          header: {
            actions: t('actions')
          },
          toolbar: {
            searchTooltip: t('search'),
            searchPlaceholder: t('search')
          },
          body: {
            emptyDataSourceMessage: t('no-records-to-display'),
            addTooltip: t('add'),
            deleteTooltip: t('delete'),
            editTooltip: t('edit'),
            editRow: {
              saveTooltip: t('save'),
              cancelTooltip: t('cancel'),
              deleteText: t('deleteText')
            }
          },
          pagination: {
            firstTooltip: t('first-page'),
            previousTooltip: t('previous-page'),
            nextTooltip: t('next-page'),
            lastTooltip: t('last-page')
          }
        }}
        editable={this.state.editable && {
          onRowAdd: newData =>
            new Promise(resolve => {
              this.setState({
                newData: newData
              });
              setTimeout(async () => {
                resolve();
                const { organisations, crews } = this.state;
                if (organisations) {
                  const crew = crews ? crews.find(c => newData.Crew != null && c.Name === newData.Crew.Name) : null;
                  const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                  const errorOrResult = await this.props.onRowAdd({
                    LastName: newData.LastName,
                    FirstNames: newData.FirstNames,
                    IsDeleted: newData.IsDeleted,
                    IsSupervisor: newData.IsSupervisor,
                    ColorRgb: (newData.ColorRgb ? (0xFF000000 | newData.ColorRgb) : newData.ColorRgb),
                    Organisation: {
                      ID: org ? org.ID : null
                    },
                    Crew: crew != null && crew !== undefined ? { ID: crew.ID } : null
                  });
                  if (!(errorOrResult instanceof Error)) {
                    if (org) {
                      newData.Organisation = org;
                    }
                    this.setState(prevState => {
                      const data = [...prevState.data];
                      newData.ID = errorOrResult.ID;
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }
                }
              }, 100);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                if (oldData) {
                  const { organisations, crews } = this.state;
                  if (organisations) {
                    const crew = crews ? crews.find(c => newData.Crew != null && c.Name === newData.Crew.Name) : null;
                    const org = newData.Organisation ? organisations.find(org => org.Name === newData.Organisation.Name) : null;
                    const errorOrResult = await this.props.onRowUpdate({
                      ...newData,
                      ColorRgb: (newData.ColorRgb ? (0xFF000000 | newData.ColorRgb) : newData.ColorRgb),
                      Organisation: {
                        ID: org ? org.ID : null
                      },
                      Crew: crew != null && crew !== undefined ? { ID: crew.ID } : null
                    }, oldData.tableData);
                    if (!(errorOrResult instanceof Error)) {
                      if (org) {
                        newData.Organisation = org;
                      }
                      this.setState(prevState => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }
                }
              }, 100);
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              setTimeout(async () => {
                resolve();
                const errorOrResult = await this.props.onRowDelete(oldData);
                if (!(errorOrResult instanceof Error)) {
                  this.setState(prevState => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }
              }, 100);
            })
        }}
      />
    );
  }
}

export default EmployeeTable;