import React from "react";
import ProjectActivityTable from "./ProjectActivityTable";

import Modal from '@material-ui/core/Modal';
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import InputAdornment from '@material-ui/core/InputAdornment';
import NumberInput from '../../core/components/Inputs/NumberInput/numberInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { enGB, enUS, de } from 'date-fns/locale'

import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";

import Mode from "./ModeEnum";
import RESOURCES from '../../api/resources';
import { getServiceUrl } from '../../utils';

import './index.css';

const styles = theme => ({
  formControl: {
    '& .MuiInput-formControl': {
      width: '100% !important',
    },
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
    width: "50%",
  },
  label: {
    fontSize: "1em",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    display: "flex",
    alignItems: "center",
    width: "300px"
  },
  label2: {
    fontSize: "1em",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    width: "150px",
    height: "170px",
    margin: "5px",
    display: "inline-block",
  },
  thumbnailImage: {
    width: "auto",
    height: "auto",
    maxWidth: "120px",
    maxHeight: "120px",
    border: "1px solid #ddd", /* Gray border */
    boxShadow: "3px 5px 5px #888888",
    cursor: "pointer",
  },
  thumbnailLabel: {
    height: "30px",
    width: "140px",
    fontSize: "15px",
    marginBlockStart: "0px",
    marginBlockEnd: "0px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  attachmentDeleteButton: {
    width: 17,
    marginLeft: 4,
  },
});

// const input = React.forwardRef((props, ref) => (
//   <input ref={ref} className="input">
//     {props.children}
//   </input>
// ));

class ProjectEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',

      projects: props.projects ? props.projects.filter(e => !e.IsDeleted) : null,
      organisations: props.organisations ? props.organisations.filter(e => !e.IsDeleted) : null,
      supervisors: props.employees ? props.employees.filter(e => e.IsSupervisor && !e.IsDeleted) : null,
      customers: props.customers ? props.customers.filter(e => !e.IsDeleted) : null,
      mixingPlants: props.mixingPlants ? props.mixingPlants.filter(e => !e.IsDeleted) : null,

      editable: props.editable ? props.editable : '',
      mode: props.mode,
      selectedFilters: [],
      error: null,

      dataRow: props.dataRow ? props.dataRow : null,
      name: props.dataRow ? props.dataRow.Name : '',
      costCenter: props.dataRow ? props.dataRow.CostCenter : '',
      externalReferenceNumber: props.dataRow ? props.dataRow.ExternalReferenceNumber : '',
      workExecutionEstimatePercentage: props.dataRow && props.dataRow.WorkExecutionEstimatePercentage !== null ? props.dataRow.WorkExecutionEstimatePercentage : 0,
      orderTotalAmount: props.dataRow ? props.dataRow.OrderTotalAmount : '',
      actualInvoicedAmount: props.dataRow ? props.dataRow.ActualInvoicedAmount : '',
      finalInvoiceTimeUtc: props.dataRow ? props.dataRow.FinalInvoiceTimeUtc : '',
      isClosed: props.dataRow ? props.dataRow.IsClosed : '',
      hasPartialInvoice: props.dataRow ? props.dataRow.HasPartialInvoice : '',

      organisation: props.dataRow ? props.dataRow.Organisation : null,
      supervisor: props.dataRow ? props.dataRow.Supervisor : null,
      customer: props.dataRow ? props.dataRow.Customer : null,
      mixingPlant: props.dataRow && props.dataRow.DefaultMixingPlant !== undefined ? props.dataRow.DefaultMixingPlant : null,
      mixtureOrder: props.dataRow && props.dataRow.DefaultMixtureOrder !== undefined ? props.dataRow.DefaultMixtureOrder : null,
      offerDocument: props.dataRow && props.dataRow.OfferDocument !== undefined ? props.dataRow.OfferDocument : null,
      tenderDocument: props.dataRow && props.dataRow.TenderDocument !== undefined ? props.dataRow.TenderDocument : null,
      images: props.dataRow && props.dataRow.Images !== undefined ? props.dataRow.Images : null,
      documents: props.dataRow && props.dataRow.Documents !== undefined ? props.dataRow.Documents : null,

      offerDocumentFileName: props.dataRow && props.dataRow.OfferDocument ? props.dataRow.OfferDocument.FileName : null,
      tenderDocumentFileName: props.dataRow && props.dataRow.TenderDocument ? props.dataRow.TenderDocument.FileName : null,
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);
    this.onFileUploadClickHandler = this.onFileUploadClickHandler.bind(this);
    this.onFileDownloadClickHandler = this.onFileDownloadClickHandler.bind(this);
    this.onDocumentChangeHandler = this.onDocumentChangeHandler.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.onDeleteClickHandler = this.onDeleteClickHandler.bind(this);
    this.fileDowloadLink = this.fileDowloadLink.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dataRow) {
      if (nextProps.dataRow.Documents !== this.state.documents)
        this.setState({ documents: nextProps.dataRow.Documents && nextProps.dataRow.Documents !== undefined ? nextProps.dataRow.Documents : null });
      if (nextProps.dataRow.Images !== this.state.images)
        this.setState({ images: nextProps.dataRow.Images && nextProps.dataRow.Images !== undefined ? nextProps.dataRow.Images : null });
    }
    this.setState({ dataRow: nextProps.dataRow ? nextProps.dataRow : null });
  }

  componentWillMount() {
    if (this.state.mixingPlant !== null) {
      var mp = this.props.mixingPlants.find(e => e.ID === this.state.mixingPlant.ID);
      if (mp !== null)
        this.setState(state => ({ mixingPlant: mp }));
    }
  }

  componentDidMount() {

    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }

  }

  async handleSaveClick(e) {
    e.preventDefault();

    const { dataRow, ...rest } = this.state;
    var newDataRow = {}
    var errorOrResult = null;
    if (this.state.mode === Mode.Add) {
      newDataRow = {
        Name: rest.name,
        CostCenter: this.convertToNullIfEmptyString(rest.costCenter),
        ExternalReferenceNumber: this.convertToNullIfEmptyString(rest.externalReferenceNumber),
        WorkExecutionEstimatePercentage: this.convertToFloat(rest.workExecutionEstimatePercentage),
        OrderTotalAmount: this.convertToFloat(rest.orderTotalAmount),
        ActualInvoicedAmount: this.convertToFloat(rest.actualInvoicedAmount),
        FinalInvoiceTimeUtc: this.convertToNullIfEmptyString(rest.finalInvoiceTimeUtc),
        IsClosed: this.convertToNullIfEmptyString(rest.isClosed) || false,
        Organisation: rest.organisation,
        Supervisor: rest.supervisor,
        Customer: rest.customer,
        DefaultMixingPlant: rest.mixingPlant,
        DefaultMixtureOrder: rest.mixtureOrder,
      };
      errorOrResult = await this.props.rowAddHandler(newDataRow);
      if (!(errorOrResult instanceof Error)) {
        errorOrResult.OfferDocument = null;
        errorOrResult.TenderDocument = null;
        errorOrResult.Images = null;
        errorOrResult.Documents = null;
        this.setState({ mode: Mode.Update, dataRow: errorOrResult });
      }
    } else if (this.state.mode === Mode.Update) {
      newDataRow = {
        ID: dataRow.ID,
        Name: rest.name,
        CostCenter: this.convertToNullIfEmptyString(rest.costCenter),
        ExternalReferenceNumber: this.convertToNullIfEmptyString(rest.externalReferenceNumber),
        WorkExecutionEstimatePercentage: this.convertToFloat(rest.workExecutionEstimatePercentage),
        OrderTotalAmount: this.convertToFloat(rest.orderTotalAmount),
        ActualInvoicedAmount: this.convertToFloat(rest.actualInvoicedAmount),
        FinalInvoiceTimeUtc: this.convertToNullIfEmptyString(rest.finalInvoiceTimeUtc),
        IsClosed: this.convertToNullIfEmptyString(rest.isClosed) || false,
        Organisation: rest.organisation,
        Supervisor: rest.supervisor,
        Customer: rest.customer,
        DefaultMixingPlant: rest.mixingPlant,
        DefaultMixtureOrder: rest.mixtureOrder,
        OfferDocument: rest.offerDocument,
        TenderDocument: rest.tenderDocument,
        Images: rest.images,
        Documents: rest.documents,
      };
      errorOrResult = await this.props.rowUpdateHandler(newDataRow, dataRow);
      if (!(errorOrResult instanceof Error))
        this.setState({ mode: Mode.Update, dataRow: errorOrResult });
    }
  }

  convertToFloat(value) {
    return value !== null && value !== '' ? parseFloat(value) : null;
  }

  convertToNullIfEmptyString(value) {
    return value === '' ? null : value;
  }

  handleBackClick(e) {
    e.preventDefault();
    this.props.onChangeMode(Mode.Uninit);
  }

  fileDowloadLink = linkId => (e) => {
    e.preventDefault();
    const link = document.getElementById(linkId);
    link.click();
  }

  handleChange = prop => evt => {
    const value = evt.target.value === '' ? null : evt.target.value;
    this.setState(state => {
      return { ...state, [prop]: value };
    }
    );
  }

  handleChangeNumericInput = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleChangeNumericInputWithValidate = validate => event => {
    const { name, value } = event.target;
    var newValue = value;
    if (value !== "") {
      newValue = validate(value);
      event.target.value = newValue;
    }
    this.setState({
      [name]: newValue
    });
  }

  validateMinMax = (min, max) => value => {
    var res = parseFloat(value)
    return res < min ? min : (res > max ? max : res);
  }

  handleChangeWithAdjust = (props, adjust) => value => {
    const newValue = adjust(value);
    this.setState({
      [props]: newValue
    });
  }

  handleCheckBoxChange = prop => evt => {
    const value = evt.target.checked;
    this.setState(state => {
      return { ...state, [prop]: value };
    }
    );
  }

  handleMixingPlantChange = evt => {
    const value = evt.target.value === '' ? null : evt.target.value;
    this.setState(state => {
      return { ...state, mixingPlant: value, mixtureOrder: null };
    }
    );
  }

  fillLookup(entries, lookup) {
    if (entries.length > 0) {
      entries.forEach(entry => {
        lookup[entry.ID] = entry;
      })
    }
  }

  selectLocale(language) {

    let languages = new Map();
    languages.set("de-DE", de);
    languages.set("en-GB", enGB);
    languages.set("en-US", enUS);

    let res = languages.get(language);
    return res != null ? res : languages["de-DE"];
  }

  dateRemoveTimePart(date) {
    if (date !== null)
      date.setUTCHours(0, 0, 0, 0);
    return date;
  }

  onDocumentChangeHandler = isOfferDocument => async (event) => {
    var files = event.target.files
    //if(this.maxSelectFile(event) && this.checkMimeType(event) &&  this.checkFileSize(event)) { 
    // if return true allow to setState
    if (files !== undefined && files.length > 0) {
      if (isOfferDocument)
        this.setState({
          offerDocumentFileName: files[0].name,
          selectedFile: files,
          loaded: 0
        })
      else
        this.setState({
          tenderDocumentFileName: files[0].name,
          selectedFile: files,
          loaded: 0
        })
    }
    //}
  }

  onFileUploadClickHandler = isOfferDocument => async (e) => {

    const { importOfferDocumentHandler, importTenderDocumentHandler } = this.props;
    var importDocumentHandler = isOfferDocument ? importOfferDocumentHandler : importTenderDocumentHandler;

    const { dataRow, selectedFile } = this.state;
    const errorOrResult = await importDocumentHandler(dataRow, selectedFile[0])
      .then(res => {
        return res;
      }, res => {
        return res;
      }
      );

    if (!(errorOrResult instanceof Error)) {
      if (isOfferDocument)
        this.setState({ offerDocument: errorOrResult, offerDocumentFileName: errorOrResult.FileName });
      else
        this.setState({ tenderDocument: errorOrResult, tenderDocumentFileName: errorOrResult.FileName });
    }
  }

  onFileDownloadClickHandler = isOfferDocument => async e => {

    const { exportOfferDocumentHandler, exportTenderDocumentHandler } = this.props;
    const { offerDocument, tenderDocument } = this.state;

    var doc = isOfferDocument ? offerDocument : tenderDocument;
    var exportDocumentHandler = isOfferDocument ? exportOfferDocumentHandler : exportTenderDocumentHandler;

    const errorOrResult = await exportDocumentHandler(doc)
      .then(res => {
        return res;
      }, res => {
        return res;
      }
      );

    if (!(errorOrResult instanceof Error)) {
      var fileName = doc.FileName;
      var tempEl = document.createElement("a");
      document.body.appendChild(tempEl);
      tempEl.style = "display: none";
      var url = window.URL.createObjectURL(errorOrResult);
      tempEl.href = url;
      tempEl.download = fileName;
      tempEl.click();
      window.URL.revokeObjectURL(url);
    }
  }

  onDeleteClickHandler = (isDocument, isTenderOrderDocument, entity) => async e => {
    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: entity, isDocument, isTenderOrderDocument });
  }

  handleAddClick = (isDocument) => (e) => {
    e.preventDefault();

    if (this.state.dataRow == null)
      return;

    const input = isDocument ? document.getElementById("inputDocuments") : document.getElementById("inputImages");
    input.click();
  }

  onDocumentAddHandler = isDocument => async (event) => {
    var files = event.target.files
    //if(this.maxSelectFile(event) && this.checkMimeType(event) &&  this.checkFileSize(event)) { 
    // if return true allow to setState
    if (files !== undefined && files.length > 0) {

      const { importDocumentHandler, importImageHandler } = this.props;
      var importHandler = isDocument ? importDocumentHandler : importImageHandler;

      const { dataRow } = this.state;
      const errorOrResult = await importHandler(dataRow, Array.from(files))
        .then(res => {
          return res;
        }, res => {
          return res;
        }
        );

      if (!(errorOrResult instanceof Error)) {

      }
    }
    //}
  }

  isValid = () => {

    const { organisation, supervisor, customer, name, costCenter, orderTotalAmount, workExecutionEstimatePercentage } = this.state;

    return !((name === null || name === undefined || name === '') ||
      (organisation === null || organisation === undefined || organisation === '') ||
      (supervisor === null || supervisor === undefined || supervisor === '') ||
      (customer === null || customer === undefined || customer === '') ||
      (orderTotalAmount === null || orderTotalAmount === undefined || orderTotalAmount === '') ||
      (costCenter === null || costCenter === undefined || costCenter === '') ||
      (workExecutionEstimatePercentage === null || workExecutionEstimatePercentage === undefined || workExecutionEstimatePercentage === ''));

  }

  closeDeleteEntryPopup = () => {
    this.setState({ openDeleteEntry: false, deleteEntryObj: null })
  }

  handleDeleteEntry = async () => {

    const { dataRow, deleteEntryObj, isDocument, isTenderOrderDocument } = this.state;
    const { deleteDocumentHandler, deleteImageHandler, deleteTenderDocumentHandler, deleteOfferDocumentHandler } = this.props;
    var deleteHandler = isTenderOrderDocument ?
      (isDocument/*isOfferDocument*/ ? deleteOfferDocumentHandler : deleteTenderDocumentHandler) :
      (isDocument ? deleteDocumentHandler : deleteImageHandler);

    if (deleteEntryObj !== null) {
      const errorOrResult = await deleteHandler(dataRow, deleteEntryObj)
        .then(res => {
          return res;
        }, res => {
          return res;
        }
        );

      if (isTenderOrderDocument && !(errorOrResult instanceof Error)) {
        if (isDocument)
          this.setState({ offerDocument: null, offerDocumentFileName: null });
        else
          this.setState({ tenderDocument: null, tenderDocumentFileName: null });
      }
    }

    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: null });
  }

  render() {

    const {
      organisations, supervisors, customers, mixingPlants,
      organisation, supervisor, customer, mixingPlant, mixtureOrder,
      offerDocument, tenderDocument, offerDocumentFileName, tenderDocumentFileName,
      name,
      costCenter,
      externalReferenceNumber,
      workExecutionEstimatePercentage,
      orderTotalAmount,
      actualInvoicedAmount,
      finalInvoiceTimeUtc,
      isClosed,
      hasPartialInvoice,
      selectedFilters,
      mode,
      openDeleteEntry
    } = this.state;

    const { t, lng } = this.props;

    var locale = this.selectLocale(lng);

    const lookupOrgs = {};
    const lookupMixingPlants = {};
    const lookupMixtureOrders = {};

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org;
      })
    }

    this.fillLookup(mixingPlants, lookupMixingPlants);

    if (mixingPlant !== null && mixingPlant.Orders !== undefined && mixingPlant.Orders !== null)
      this.fillLookup(mixingPlant.Orders, lookupMixtureOrders);

    const { classes, ...rest } = this.props;

    return (
      <div>
        <div className="rowC">
          <Button onClick={this.handleSaveClick}
            style={{ padding: "5px", width: "120px", margin: "5px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submitButton"
            disabled={!this.isValid()}
          >
            {t('save')}
          </Button>
          <Button onClick={this.handleBackClick}
            style={{ padding: "5px", width: "120px", margin: "5px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submitButton"
          >
            {t('back')}
          </Button>
        </div>

        <div className="rowC" style={{ width: "100%" }}>

          <FormControl style={{ padding: "5px", width: "50%", minWidth: "500px", margin: "5px" }}>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <TextField
                required
                id="name-input"
                value={name}
                type="text"
                inputProps={{ maxLength: 255 }}
                label={t('name')}
                error={name === null || name === undefined || name === ''}
                onChange={this.handleChange('name')}
              />
            </FormControl>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <InputLabel className="org-label" id="org-label">{t('organisation')}*</InputLabel>
              <Select
                labelId="org-label"
                id="organisation"
                value={organisation}
                onChange={this.handleChange('organisation')}
                error={organisation === null || organisation === undefined || organisation === ''}
                renderValue={selected => selected.ShortName ? selected.ShortName : selected.Name}
                onClick={async () => {
                  await new Promise(resolve => setTimeout(() => resolve(), 100));
                  const { organisations } = this.state;
                  const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
                  /*const waitUntilPopupIsMounted = async () => {
                    await new Promise(resolve => setTimeout(() => resolve(), 100));
                    if (!organisationPopup) {
                      await waitUntilPopupIsMounted();
                    }
                  };*/

                  if (organisationPopup) {
                    const orgsList = organisationPopup.childNodes[0].childNodes;
                    if (orgsList) {
                      const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
                      let i = 0;
                      orgsList.forEach(orgElement => {
                        const orgObj = organisations.find(item => item.ID === JSON.parse(orgElement.getAttribute("data")).ID);
                        const orgText = document.getElementsByClassName("MuiListItem-button")[i];
                        if (orgObj.Level === topLevel) {
                          orgText.style.paddingLeft = "10px";
                        } else if (orgObj.Level > topLevel) {
                          const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 15;
                          orgText.style.paddingLeft = `${margin}px`;
                        }
                        i++;
                      })
                    }
                  } /*else if (!organisationPopup) {
                    await waitUntilPopupIsMounted();
                  }*/
                }}
              >
                {Object.keys(lookupOrgs).map(key => (
                  selectedFilters.length === 0 || selectedFilters.includes(key) ?
                    <MenuItem
                      value={lookupOrgs[key]}
                      data={JSON.stringify(lookupOrgs[key])}
                    >
                      <em>{lookupOrgs[key].ShortName ? lookupOrgs[key].ShortName : lookupOrgs[key].Name}</em>
                    </MenuItem>
                    : null
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <InputLabel className="org-label" id="org-label">{t('construction-supervisor')}*</InputLabel>
              <Select
                labelId="org-label"
                id="supervisor"
                value={supervisor}
                onChange={this.handleChange('supervisor')}
                error={supervisor === null || supervisor === undefined || supervisor === ''}
                renderValue={selected => `${selected.FirstNames} ${selected.LastName}`}
              >
                {supervisors.map((supervisor, i) => (
                  <MenuItem key={i} value={supervisor}>
                    <em>{`${supervisor.LastName} ${supervisor.FirstNames}`}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl} style={{ padding: "5px", width: "47%", margin: "5px" }}>
              <InputLabel className="org-label" id="org-label">{t('customer')}*</InputLabel>
              <Select
                labelId="org-label"
                id="customer"
                value={customer}
                onChange={this.handleChange('customer')}
                error={customer === null || customer === undefined || customer === ''}
                renderValue={selected => selected.Name}
              >
                {customers.map((customer, i) => (
                  <MenuItem key={i} value={customer}>
                    <em>{`${customer.Name}`}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className="rowC" style={{ width: "100%" }}>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <InputLabel className="org-label" id="org-label">{t('asphalt-mixing-plant')}</InputLabel>
                <Select
                  labelId="org-label"
                  id="mixingPlant"
                  value={mixingPlant || ''}
                  onChange={this.handleMixingPlantChange}
                  renderValue={selected => selected.Name}
                >
                  <MenuItem value=''>&nbsp;</MenuItem>
                  {Object.keys(lookupMixingPlants).map(key => (
                    <MenuItem value={lookupMixingPlants[key]}>
                      <em>{lookupMixingPlants[key].Name}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <InputLabel className="org-label" id="mixtureOrder-label">{t('asphalt-mixture-order')}</InputLabel>
                <Select
                  labelId="mixtureOrder-label"
                  id="mixtureOrderId"
                  value={mixtureOrder || ''}
                  onChange={this.handleChange('mixtureOrder')}
                  renderValue={selected => selected.OrderNumber}
                >
                  <MenuItem value=''>&nbsp;</MenuItem>
                  {Object.keys(lookupMixtureOrders).map(key => (
                    <MenuItem value={lookupMixtureOrders[key]}>
                      <em>{lookupMixtureOrders[key].OrderNumber}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%" }}>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  required
                  id="orderTotalAmount"
                  value={orderTotalAmount}
                  type="number"
                  label={t('order-total-amount')}
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' } }}
                  error={orderTotalAmount === null || orderTotalAmount === undefined || orderTotalAmount === ''}
                  handleChange={this.handleChangeNumericInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput
                  style={{ width: "100%" }}
                  id="actualInvoicedAmount"
                  value={actualInvoicedAmount}
                  type="number"
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' } }}
                  label={t('actual-invoiced-amount')}
                  handleChange={this.handleChangeNumericInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    )
                  }}
                />
              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%" }}>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <TextField
                  required
                  id="name-input"
                  value={costCenter}
                  type="text"
                  inputProps={{ maxLength: 255 }}
                  label={t('cost-center')}
                  error={costCenter === null || costCenter === undefined || costCenter === ''}
                  onChange={this.handleChange('costCenter')}
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <NumberInput style={{ width: "100%" }}
                  required
                  inputProps={{ maxLength: 15, style: { textAlign: 'right' } }}
                  id="workExecutionEstimatePercentage"
                  value={workExecutionEstimatePercentage}
                  label={t('work-execution-estimate-percentage')}
                  error={workExecutionEstimatePercentage === null || workExecutionEstimatePercentage === undefined || workExecutionEstimatePercentage === ''}
                  handleChange={this.handleChangeNumericInputWithValidate(value => this.validateMinMax(-100, 100)(value))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    )
                  }}
                />
              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%" }}>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox-input"
                      checked={isClosed || false}
                      onChange={this.handleCheckBoxChange('isClosed')}
                      value={isClosed || false}
                    />
                  }
                  label={t('is-closed')}
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }} disabled>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="checkbox-input"
                      checked={hasPartialInvoice || false}
                      value={hasPartialInvoice || false}
                    />
                  }
                  label={t('has-partial-invoice')}
                />
              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%" }}>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <TextField
                  id="name-input"
                  value={externalReferenceNumber}
                  type="text"
                  inputProps={{ maxLength: 255 }}
                  label={t('external-reference-number')}
                  onChange={this.handleChange('externalReferenceNumber')}
                />
              </FormControl>

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "50%", margin: "5px" }}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={locale}>
                  <DatePicker
                    label={t('final-invoice-time')}
                    id="finalInvoiceTimeUtc"
                    format="dd.MM.yyyy"
                    value={finalInvoiceTimeUtc || null}
                    onChange={this.handleChangeWithAdjust('finalInvoiceTimeUtc', value => this.dateRemoveTimePart(value))}
                    locale={locale}
                    okLabel={t('date-picker-label-ok')}
                    cancelLabel={t('date-picker-label-cancel')}
                    clearLabel={t('date-picker-label-clear')}
                    invalidDateMessage={t('date-picker-label-invalid-date-format')}
                    clearable={true}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>

            </div>

          </FormControl>

          <div>

            <div className="rowC" style={{ width: "100%", pointerEvents: mode === Mode.Add ? "none" : "inherit", opacity: mode === Mode.Add ? "0.4" : "inherit" }} >

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "100%", margin: "5px" }}>
                <div style={{ marginBottom: "5px" }} >
                  <label className={classes.label} id="labelOfferDocument">{`${t('offer-document')}:`}</label>
                </div>

                <div className="rowC">
                  <input accept=".pdf" className={classes.input} id="offerDocument" type="file" onChange={this.onDocumentChangeHandler(true)} />
                  <label className={classes.label} style={{ fontSize: "0.8em" }} for="offerDocument">
                    {(offerDocument == null && offerDocumentFileName !== null) || (offerDocument != null && offerDocumentFileName !== offerDocument.FileName) ? offerDocumentFileName : t('select-file-to-upload')}
                  </label>
                  <div style={{ margin: "2px", marginLeft: "10px" }}>
                    <Button style={{ textTransform: "none", width: "125px" }} disabled={!(offerDocumentFileName !== null && (offerDocument === null || offerDocumentFileName !== offerDocument.FileName))} variant="contained" color="primary"
                      onClick={this.onFileUploadClickHandler(true)}>{t('upload')}</Button>
                  </div>
                </div>

                <div className="rowC">
                  {
                    offerDocument !== null ?
                      <div className={classes.thumbnail} style={{ width: "auto" }}>
                        <div>
                          <img className={classes.thumbnailImage}
                            onClick={this.fileDowloadLink("offerDocumentDownloadLink")}
                            src={`${getServiceUrl() + RESOURCES.Attachments + "(" + offerDocument.ID + ")" + "/ShowThumbnail?allowFallback=1&token=" + this.props.user.token}`}
                          />
                          <a hidden id="offerDocumentDownloadLink" href={`${getServiceUrl() + RESOURCES.Attachments + "(" + offerDocument.ID + ")" + "/DownloadFile?token=" + this.props.user.token}`} />
                          <DeleteIcon
                            className="entry-button {classes.attachmentDeleteButton}"
                            onClick={this.onDeleteClickHandler(true, true, offerDocument)}
                          />
                        </div>
                        <p className={classes.thumbnailLabel} style={{ width: "auto" }} title={`${offerDocument.FileName}`}>{offerDocument.FileName}</p>
                      </div> : null
                  }
                </div>

              </FormControl>

            </div>

            <div className="rowC" style={{ width: "100%", pointerEvents: mode === Mode.Add ? "none" : "inherit", opacity: mode === Mode.Add ? "0.4" : "inherit" }} >

              <FormControl className={classes.formControl} style={{ padding: "5px", width: "100%", margin: "5px" }}>
                <div style={{ marginBottom: "5px" }} >
                  <label className={classes.label} id="labelTenderDocument">{`${t('tender-document')}:`}</label>
                </div>

                <div className="rowC" style={{}}>
                  <input accept=".D83" className={classes.input} id="tenderDocument" type="file" onChange={this.onDocumentChangeHandler(false)} />
                  <label className={classes.label} style={{ fontSize: "0.8em" }} for="tenderDocument">
                    {(tenderDocument == null && tenderDocumentFileName !== null) || (tenderDocument != null && tenderDocumentFileName !== tenderDocument.FileName) ? tenderDocumentFileName : t('select-file-to-upload')}
                  </label>
                  <div style={{ margin: "2px", marginLeft: "10px" }}>
                    <Button style={{ textTransform: "none", width: "125px" }} disabled={!(tenderDocumentFileName !== null && (tenderDocument === null || tenderDocumentFileName !== tenderDocument.FileName))} variant="contained" color="primary"
                      onClick={this.onFileUploadClickHandler(false)}>{t('upload')}</Button>
                  </div>
                </div>

                <div className="rowC">
                  {
                    tenderDocument !== null ?
                      <div className={classes.thumbnail} style={{ width: "auto" }}>
                        <div>
                          <img className={classes.thumbnailImage}
                            onClick={this.fileDowloadLink("tenderDocumentDownloadLink")}
                            src={`${getServiceUrl() + RESOURCES.Attachments + "(" + tenderDocument.ID + ")" + "/ShowThumbnail?allowFallback=1&token=" + this.props.user.token}`}
                          />
                          <a hidden id="tenderDocumentDownloadLink" href={`${getServiceUrl() + RESOURCES.Attachments + "(" + tenderDocument.ID + ")" + "/DownloadFile?token=" + this.props.user.token}`} />
                          <DeleteIcon
                            className="entry-button {classes.attachmentDeleteButton}"
                            onClick={this.onDeleteClickHandler(false, true, tenderDocument)}
                          />
                        </div>
                        <p className={classes.thumbnailLabel} style={{ width: "auto" }} title={`${tenderDocument.FileName}`}>{tenderDocument.FileName}</p>
                      </div> : null
                  }
                </div>

              </FormControl>

            </div>

          </div>

        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            < div >
              <ProjectActivityTable
                {...rest}
                editable={mode !== Mode.Add}
                title={t('project-activities')}
                data={this.props.projectActivities}
                t={t}
                projectRow={this.state.dataRow}
              />
            </div>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            <div>
              <div style={{ margin: "5px", display: "flex" }} >
                <div>
                  <label className={classes.label2} id="labelImages">{`${t('images')}:`}</label>
                </div>
                <div>
                  <AddIcon id="addIcon" color="action" aria-label="add" onClick={this.handleAddClick(false)} />
                  <input accept=".jpg,.jpeg,.jpe,*.jfif,.gif,.tif,.png" className={classes.input} id="inputImages" type="file" multiple onChange={this.onDocumentAddHandler(false)} />
                </div>
              </div>
              <div>
                {
                  this.state.dataRow !== null && this.state.images !== null ?
                    (
                      this.state.images.map((file) => {
                        return (
                          <div className={classes.thumbnail}>
                            <div >
                              <img className={classes.thumbnailImage}
                                onClick={this.fileDowloadLink("imageDownloadLink")}
                                src={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")" + "/ShowThumbnail?allowFallback=1&token=" + this.props.user.token}`}
                              />
                              <a hidden id="imageDownloadLink" href={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")" + "/DownloadFile?token=" + this.props.user.token}`} />
                              <DeleteIcon
                                className="entry-button {classes.attachmentDeleteButton}"
                                onClick={this.onDeleteClickHandler(false, false, file)}
                              />
                            </div>
                            <p className={classes.thumbnailLabel} title={`${file.FileName}`}>{file.FileName}</p>
                          </div>)
                      })
                    )
                    : null
                }
              </div>
            </div>
          </FormControl>
        </div>

        <div>
          <FormControl style={{ padding: "5px", width: "80%", margin: "5px" }}>
            <div>
              <div style={{ margin: "5px", display: "flex" }} >
                <div style={{}}>
                  <label className={classes.label2} id="labelDocuments">{`${t('documents')}:`}</label>
                </div>
                <div style={{}}>
                  <AddIcon id="addIcon" color="action" aria-label="add" onClick={this.handleAddClick(true)} />
                  <input accept=".pdf,.xls,.xlsx,.doc,.docx,.txt" className={classes.input} id="inputDocuments" type="file" multiple onChange={this.onDocumentAddHandler(true)} />
                </div>
              </div>
              <div>
                {
                  this.state.dataRow !== null && this.state.documents !== null ?
                    (
                      this.state.documents.map((file) => {
                        return (
                          <div className={classes.thumbnail}>
                            <div >
                              <img className={classes.thumbnailImage}
                                src={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")" + "/ShowThumbnail?allowFallback=1&token=" + this.props.user.token}`}
                                onClick={this.fileDowloadLink("documentDownloadLink")}
                              />
                              <a hidden id="documentDownloadLink" href={`${getServiceUrl() + RESOURCES.Attachments + "(" + file.ID + ")" + "/DownloadFile?token=" + this.props.user.token}`} />
                              <DeleteIcon
                                className="entry-button {classes.attachmentDeleteButton}"
                                onClick={this.onDeleteClickHandler(true, false, file)}
                              />
                            </div>
                            <p className={classes.thumbnailLabel} title={`${file.FileName}`}>{file.FileName}</p>
                          </div>)
                      })
                    )
                    : null
                }
              </div>
            </div>
          </FormControl>
        </div>

        <Modal open={openDeleteEntry} onClose={this.closeDeleteEntryPopup}>
          <div className="delete-popup">
            <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
              <h2>{t('deleteText')}</h2>
            </div>
            <div className="buttonContainer">
              <Button
                onClick={this.closeDeleteEntryPopup}
                style={{ marginRight: 7 }}
                variant="contained"
                color="primary"
                size="small"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={this.handleDeleteEntry}
                variant="contained"
                color="secondary"
                size="small"
              >
                {t('delete')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ProjectEditForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProjectEditForm);