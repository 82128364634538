import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import projectActions from "../../actions/project";
import organisationActions from "../../actions/organisation";
import employeeActions from "../../actions/employee";
import customerActions from "../../actions/customer";
import mixingPlantActions from "../../actions/mixingPlant";
import mixtureOrderActions from "../../actions/mixtureOrder";
import projectActivitiesActions from "../../actions/projectActivities";

import Projects from "./Projects";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ project, organisation, employee, customer, mixingPlant, mixtureOrder, projectActivity, user }) => ({
  project: project.project,
  projects: project.projects,
  organisations: organisation.organisations,
  employee: employee.employee,
  customer: customer.customer,
  mixingPlant: mixingPlant.mixingPlant,
  mixtureOrder: mixtureOrder.mixtureOrder,
  projectActivity: projectActivity.projectActivity,
  user: user,
  isFetching: isStateFetching([
    project, organisation, employee, customer, mixingPlant, mixtureOrder, projectActivity
  ])
});

const mapDispatchToProps = {
  ...projectActions,
  ...organisationActions,
  ...employeeActions,
  ...customerActions,
  ...mixingPlantActions,
  ...mixtureOrderActions,
  ...projectActivitiesActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Projects);
export default withNamespaces(["common"])(connected);
