export default {
  "ROOT": "/",
  "LOGIN": "/login",
  "PLANNING": "/planning",
  "PROJECT": "/project",
  "MASTERDATA": "/masterdata",
  "HELP": "/help",
  "EMPLOYEE": "/employee",
  "CREW": "/crew",
  "SPECIAL_DAYS": "/specialdays",
  "CUSTOMER": "/customer",
  "EQUIPMENT": "/equipment",
  "DRIVER": "/driver",
  "MIXINGPLANT": "/mixingplant",
}
