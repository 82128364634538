import {
  GET_SPECIALDAY_REQUEST,
  GET_SPECIALDAY_ERROR,
  GET_SPECIALDAY_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/getEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default (state = initialState, action) => {

  context.init(context);

  context.GET_ENTITY_REQUEST = GET_SPECIALDAY_REQUEST;
  context.GET_ENTITY_ERROR = GET_SPECIALDAY_ERROR;
  context.GET_ENTITY_SUCCESS = GET_SPECIALDAY_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "specialday";
  context.entityLocation = RESOURCES.SpecialDays;
  context.selectQuery = "ID, Name, DateFrom, DateTo, IsPublicHoliday";
  context.expandQuery = "Organisation($select=ID, ShortName, Name)";
  context.orderByQuery = "Name, ID"

  return context.execute(context)(state, action);
};
