import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import { withRouter } from "react-router-dom";

import userActions from "../../actions/user";
import settingsActions from "../../actions/settings";

import { isStateFetching } from "../../utils"

import LoginForm from "./LoginForm";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: undefined
    };
  }

  handleSubmit = async ({ organisation, userNumber, password, environment, environmentDisplayName }) => {
    // show URL in browser without query string parameters
    if (window.history.replaceState)
      window.history.replaceState(null, null, '/');

    const { login } = this.props;
    const loginErrorOrResult = await login({ organisation, userNumber, password, environment, environmentDisplayName });

    if (loginErrorOrResult instanceof Error) {
      this.setState({ error: loginErrorOrResult });
      return loginErrorOrResult
    }
  };

  render() {
    const { isLoggedIn } = this.props;

    return (
      <>
        {!isLoggedIn && (
          <LoginForm onSubmit={this.handleSubmit} {...this.props} {...this.state} />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ user, settings }) => ({
  isLoggedIn: user.isLoggedIn,
  loginError: user.loginError,
  settings: settings.settings,
  isFetching: isStateFetching([
    user,
    settings
  ])
});

const mapDispatchToProps = {
  ...userActions,
  ...settingsActions
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
export default withNamespaces(['common'])(connectedWithRouter);
