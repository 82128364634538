import odata from 'odata-client';
import {
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_ERROR,
  CREATE_CUSTOMER_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
  type: TOKEN_EXPIRED
});

export const createCustomerRequest = () => ({
  type: CREATE_CUSTOMER_REQUEST
});

export const createCustomerError = errorOrResult => ({
  type: CREATE_CUSTOMER_ERROR,
  error: errorOrResult.message
});

export const createCustomerSuccess = payload => ({
  type: CREATE_CUSTOMER_SUCCESS,
  payload
});

export default customer => async (dispatch, getState) => {
  if (!(customer.Name) || customer.Name.trim() === "" || !(customer.Organisation.ID)) {
    dispatch(showNotification("Required fields must NOT be empty", "error"))
    return new Error()
  }

  dispatch(createCustomerRequest());

  const odataObj = odata({ service: getServiceUrl() }).resource(RESOURCES.Customers);
  const errorOrResult = await odataObj.post(customer, {
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      ...getDefaultHeader()
    }
  })
    .then(resp => resp)
    .then(res => {
      const ok = res.statusCode <= 299 && res.statusCode >= 200;
      if (res.statusCode === 500) {
        const errorKey = "GENERAL_SERVER_ERROR";
        return new Error(errorKey);
      } else if (!ok) {
        const response = res.toJSON()
        const error = JSON.parse(response.body).Error
        const errorKey = res.statusCode === 401 ? `401: ${error.Details[0].Message}` : `${error.Details[0].Message}`;
        console.log('create customer api error', response);
        return new Error(errorKey);
      } else {
        const body = JSON.parse(res.body);
        const { "@odata.context": omit, ...payload } = body;
        payload.Organisation = customer.Organisation;
        return payload;
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      dispatch(showNotification("Request probably timed out.", "error"))
    });

  if (errorOrResult instanceof Error) {
    dispatch(createCustomerError(errorOrResult, CREATE_CUSTOMER_ERROR));
    console.log("CODE ERROR: ", errorOrResult.message);
    if (errorOrResult.message.split(":")[0] === "401") {
      dispatch(tokenExpired());
      dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
    }
    dispatch(showNotification(errorOrResult.message, "error"))
    return errorOrResult;
  } else {
    dispatch(showNotification("SUCCESSFUL_SAVE", "success"))
  }

  dispatch(createCustomerSuccess(errorOrResult));

  return errorOrResult;
};