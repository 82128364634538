import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';
import equipmentActions from "../../actions/equipment";
import organisationActions from "../../actions/organisation";
import Equipment from "./Equipment";

import { isStateFetching } from "../../utils"

const mapStateToProps = ({ equipment, organisation }) => ({
    equipment: equipment.equipment,
    organisations: organisation.organisations,
    isFetching: isStateFetching([
        equipment,
        organisation
    ])
});

const mapDispatchToProps = {
    ...equipmentActions,
    ...organisationActions
};

const connected = connect(mapStateToProps, mapDispatchToProps)(Equipment)
export default withNamespaces(['common'])(connected);
