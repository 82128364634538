import {
  CREATE_DRIVER_REQUEST,
  CREATE_DRIVER_ERROR,
  CREATE_DRIVER_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/createEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default entity => (state = initialState, action) => {

  context.init(context);

  context.CREATE_ENTITY_REQUEST = CREATE_DRIVER_REQUEST;
  context.CREATE_ENTITY_ERROR = CREATE_DRIVER_ERROR;
  context.CREATE_ENTITY_SUCCESS = CREATE_DRIVER_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "driver";
  context.entityLocation = RESOURCES.Drivers;
  context.hasOrganisation = true;

  return context.execute(context)(entity)(state, action);
};