import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { Droppable } from 'react-beautiful-dnd';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddListIcon from '@material-ui/icons/PlaylistAdd';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

import Pensum from '../Pensum/Pensum';

import './index.css'

/*
  -webkit-box-shadow: 0px 2px 2px 1px rgba(200,200,200);
  -moz-box-shadow: 0px 2px 2px 1px rgba(200,200,200);
  box-shadow: 0px 2px 2px 1px rgba(200,200,200);
*/

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    /*'&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },*/
  },
}))(MenuItem);

const workloadIndications = {
  "grey": "#D9D5D5",
  "red": "#B96666",
  "yellow": "#C5C548",
  "green": "#51A862"
}

const Container = styled.div`
  width: 320px;
  margin-right: 4px;
  margin-top: 3px;
  margin-bottom: 4px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`

const PensumList = styled.div`
  padding: 6px;
  padding-bottom: 0px;
  transition: background-color 0.2s ease;
  border-radius: 4px 4px 0px 0px;
  background-color: ${props => props.isDraggingOver ? props.bgColor === "#BDBD09" ? `${props.bgColor}91` : `${props.bgColor}ad` : props.bgColor };
  ${props => props.isEmpty ? 'height: 50px;' : 'min-height: 82px;' };
  flex-grow: 1;
  `

const AddContainer = styled.div`
  padding: 6px;
  padding-top: 8px;
  margin-top: -10px;
  transition: background-color 0.2s ease;
  background-color: ${props => props.bgColor};
`

const AddPensum = styled.div`
  padding: 9px;
  padding-top: 17px;
  padding-bottom: 8px;
  background-color: #fcfcfc;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 1px 1px rgba(100,100,100,0.7);
  -moz-box-shadow: 0px 1px 1px 1px rgba(100,100,100,0.7);
  box-shadow: 0px 1px 1px 1px rgba(100,100,100,0.7);
`

export default class PensumsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProjectID: null,
      isOpenAdd: false,
      anchorEl: null
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event) {
    if(event.keyCode === 27) {
      this.closePopup()
    }
  }

  closePopup = () => {
    this.setState({ isOpenAdd: false })
  }

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = () => {
    this.setState({
      isOpenAdd: !this.state.isOpenAdd,
      selectedProjectID: null,
      anchorEl: null
    })
  };

  handleProjectChange = event => {
    event.preventDefault();
    const selectedProjectID = event.target.value;

    this.setState({ selectedProjectID });
  };

  handlePaste = async () => {
    const {
      copiedPensum,
      createPensum,
      createEntry,
      columns,
      columnId,
      getPensums,
      selectedFromDate,
      selectedToDate
    } = this.props
    const pensumsInColumn = columns[columnId] && columns[columnId].pensums
    const lastPensumInColumn = pensumsInColumn && pensumsInColumn[pensumsInColumn.length - 1]
    const sortOrder = lastPensumInColumn && lastPensumInColumn.SortOrder ? lastPensumInColumn.SortOrder + 16 : 0

    const errorOrResult = await createPensum({
      CreationTimeUtc: moment().utc().format(),
      Day: columnId.split("/")[1],
      Project: { ID: copiedPensum.Project.ID },
      Crew: { ID: parseInt(columnId.split("/")[0]) },
      SortOrder: sortOrder,
      Comment: copiedPensum.Comment
    })

    if (errorOrResult.ID) {
      const {
        MixtureEntries,
        DriverEntries,
        ProjectActivityEntries,
        EmployeeEntries,
        EquipmentEntries
      } = copiedPensum
      
      if (MixtureEntries.length > 0) {
        MixtureEntries.forEach(async mix => {
          await createEntry("MixtureEntries", {
            Mixture: { ID: mix.Mixture.ID },
            Pensum: { ID: errorOrResult.ID },
            MixtureAmount: mix.MixtureAmount,
            Comment: mix.Comment,
            SortOrder: mix.SortOrder
          })
        })
      }
      if (DriverEntries.length > 0) {
        DriverEntries.forEach(async mix => {
          await createEntry("DriverEntries", {
            Driver: { ID: mix.Driver.ID },
            Pensum: { ID: errorOrResult.ID },
            Payload: mix.Payload,
            StartTime: mix.StartTime,
            Comment: mix.Comment,
            SortOrder: mix.SortOrder
          })
        })
      }
      if (ProjectActivityEntries.length > 0) {
        ProjectActivityEntries.forEach(async mix => {
          await createEntry("ProjectActivityEntries", {
            ProjectActivity: { ID: mix.ProjectActivity.ID },
            Pensum: { ID: errorOrResult.ID },
            SortOrder: mix.SortOrder
          })
        })
      }
      if (EmployeeEntries.length > 0) {
        EmployeeEntries.forEach(async mix => {
          await createEntry("EmployeeEntries", {
            Employee: { ID: mix.Employee.ID },
            Pensum: { ID: errorOrResult.ID },
            SortOrder: mix.SortOrder
          })
        })
      }
      if (EquipmentEntries.length > 0) {
        EquipmentEntries.forEach(async mix => {
          await createEntry("EquipmentEntries", {
            Equipment: { ID: mix.Equipment.ID },
            Pensum: { ID: errorOrResult.ID },
            SortOrder: mix.SortOrder
          })
        })
      }
    }

    await getPensums(new Date(selectedFromDate), new Date(selectedToDate))
  }

  handleCreatePensum = async () => {
    const { createPensum, columns, columnId, getPensums, selectedFromDate, selectedToDate } = this.props
    const { selectedProjectID } = this.state
    const pensumsInColumn = columns[columnId] && columns[columnId].pensums
    const lastPensumInColumn = pensumsInColumn && pensumsInColumn[pensumsInColumn.length - 1]
    const sortOrder = lastPensumInColumn && lastPensumInColumn.SortOrder ? lastPensumInColumn.SortOrder + 16 : 0

    if (selectedProjectID !== null && columnId.split("/")[0] && columnId.split("/")[1]) {
      await createPensum({
        CreationTimeUtc: moment().utc().format(),
        Day: columnId.split("/")[1],
        Project: { ID: selectedProjectID },
        Crew: { ID: parseInt(columnId.split("/")[0]) },
        SortOrder: sortOrder
      })

      this.handleClick()
      
      await getPensums(new Date(selectedFromDate), new Date(selectedToDate))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.anchorEl !== this.state.anchorEl
      || nextState.isOpenAdd !== this.state.isOpenAdd
      || nextState.selectedProjectID !== this.state.selectedProjectID
      || nextProps.view !== this.props.view
    ) {
      return true
    }
    if (_.isEqual(nextProps.tasks, this.props.tasks)) {
      return false
    }
    return true
  }

  render() {
    const {
      columnId,
      projects,
      tasks,
      t,
      specialDaysInColumn,
      workloadIndication,
      copiedPensum
    } = this.props
    const { selectedProjectID, isOpenAdd, anchorEl } = this.state
    const bgColor = workloadIndications[workloadIndication]
    const filteredProjects = projects.filter(project => project.IsClosed === false)

    filteredProjects.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0));
    
    return (
      <Container>
        <Droppable droppableId={columnId} type="TASK">
          {(provided, snapshot) => (
            <PensumList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
              bgColor={bgColor}
              isEmpty={tasks.length < 1}
            >
              {specialDaysInColumn.length > 0 ? (
                <div className="specialday">
                  {specialDaysInColumn.map(day => (
                    <Chip
                      label={day.name}
                      color={day.isPublicHoliday ? "primary" : "default"}
                      style={{ marginRight: 3, marginTop: 3, fontSize: 13, fontFamily: 'Open Sans' }}
                    />
                  ))}
                </div>
              ) : null}
              {tasks.map((task, i) => (
                <Pensum key={task.ID} task={task} index={i} {...this.props} />
              ))}
              {provided.placeholder}
            </PensumList>
          )}
        </Droppable>
        {
          isOpenAdd ? (
            <AddContainer bgColor={bgColor}>
              <AddPensum isEmpty={tasks.length < 1}>
                <FormControl className="narrowForm">
                  <InputLabel className="planning-label" id="project-label">{t('project')}</InputLabel>
                  <Select
                    labelId="project-label"
                    id="project"
                    value={selectedProjectID || ''}
                    onChange={this.handleProjectChange}
                    fullWidth
                  >
                    <MenuItem value={null}>&nbsp;</MenuItem>
                    {
                    filteredProjects.map(project => {
                      return <MenuItem key={project.ID} value={project.ID}>{project.Name}</MenuItem>
                    })}
                  </Select>
                </FormControl>
                <div className="popup-bottom">
                  <Tooltip title={t('close')}>
                    <Button
                      onClick={this.handleClick}
                      variant="text"
                      color="inherit"
                      size="small"
                      className="button-default"
                      startIcon={<CloseIcon style={{ marginLeft: 8 }} />}
                    />
                  </Tooltip>
                  <Tooltip title={t('save')}>
                    <Button
                      onClick={this.handleCreatePensum}
                      variant="text"
                      className="button-default"
                      color="primary"
                      size="small"
                      disabled={selectedProjectID === null}
                      startIcon={<SaveIcon style={{ marginLeft: 8 }} />}
                    />
                  </Tooltip>
                </div>
                <div className="clear" />
              </AddPensum>
            </AddContainer>
          ) : null
        }

        <div className="bottom-container">
          <div id="addIconContainer" className="bottom-container-addButton" style={{ backgroundColor: bgColor }}>
            <AddIcon
              id="addIcon"
              color="action"
              className="pensumButton"
              aria-label="add"
              onClick={(event) => { event.stopPropagation(); this.handleOpen(event); }}
            />
            <div className="clear" />
            <StyledMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={(event) => { event.stopPropagation(); this.handleClose() }}
            >
              <StyledMenuItem
                onClick={(event) => { event.stopPropagation(); this.handleClick() }}
              >
                <ListItemIcon style={{ minWidth: 25 }}>
                  <AddIcon fontSize="small" />
                </ListItemIcon>
                {t('add-pensum')}
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  event.stopPropagation();
                  this.handlePaste();
                  this.handleClose();
                }}
                disabled={!copiedPensum}
              >
                <ListItemIcon style={{ minWidth: 25 }}>
                  <AddListIcon fontSize="small" />
                </ListItemIcon>
                {t('paste-pensum')}
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </div>
      </Container>
    )
  }
}
