import React, { Component } from "react";
import CustomerTable from "../Customer/CustomerTable";

import Loader from '../../core/components/Loader/LinearProgress'

class Customer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMounted: false,
      onRowAdd: props.createCustomer,
      onRowUpdate: props.updateCustomer,
      onRowDelete: props.deleteCustomer,
      editable: true
    };
  }

  async componentDidMount() {
    const { getCustomer, getOrganisations } = this.props;
    await getCustomer();
    await getOrganisations();
    this.setState({ isMounted: true });

    const organisationFilter = document.getElementsByClassName("MuiSelect-selectMenu")[0];
    const organisationSelect = document.getElementsByClassName("MuiSelect-selectMenu")[1];
    const organisationFilterContainer = document.getElementsByClassName("MuiInputBase-formControl")[1];
    const tableHeader = document.getElementsByClassName("MuiToolbar-gutters")[1];
    if (organisationFilter !== undefined) {
      organisationFilter.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationSelect !== undefined) {
      organisationSelect.addEventListener("click", this.expandPopup.bind(this));
    }
    if (organisationFilterContainer !== undefined) {
      organisationFilterContainer.setAttribute("id", "org-select")
    }
    if (tableHeader !== undefined) {
      tableHeader.style.marginBottom = "35px";
    }
  }

  componentWillUnmount() {
    if (document.getElementsByClassName("MuiSelect-selectMenu")[0] !== undefined) {
      document.getElementsByClassName("MuiSelect-selectMenu")[0].removeEventListener("click", this.expandPopup);
    }
  }

  expandPopup = async () => {
    await new Promise(resolve => setTimeout(() => resolve(), 100));
    const { organisations } = this.props;
    const organisationPopup = document.getElementsByClassName("MuiPopover-paper")[0];
    /*const waitUntilPopupIsMounted = async () => {
      await new Promise(resolve => setTimeout(() => resolve(), 100));
      if (!organisationPopup) {
        await waitUntilPopupIsMounted();
      }
    };*/

    if (organisationPopup) {
      const orgsList = organisationPopup.childNodes[0].childNodes;
      if (orgsList) {
        const topLevel = Math.min.apply(Math, organisations.map(org => { return org.Level; }));
        let i = 0;
        orgsList.forEach(orgElement => {
          const orgObj = organisations.find(item => item.ShortName === orgElement.getAttribute("data-value"));
          const orgText = document.getElementsByClassName("MuiTypography-body1")[i];

          if (orgObj) {
            orgText.setAttribute("data-value", orgObj.ShortName);
            if (orgObj.Level === topLevel) {
              orgText.style.marginLeft = "0px";
            } else if (orgObj.Level > topLevel) {
              const margin = (Math.abs(orgObj.Level - topLevel) * 10) + 5;
              orgText.style.paddingLeft = `${margin}px`;
            }
          }

          i++;
        })
      }
      organisationPopup.setAttribute("style", organisationPopup.getAttribute("style").replace(" width: 250px;", ""));
    } /*else if (!organisationPopup) {
      await waitUntilPopupIsMounted();
    }*/
  }

  render() {
    const { isMounted, ...rest } = this.state;
    const { customer, organisations, t, isFetching } = this.props;

    return (
      <div
        style={{
          width: isFetching && !isMounted ? "100%" : "820px",
          margin: "0 auto",
          marginTop: isFetching && !isMounted ? 0 : 30,
          justifyContent: "center"
        }}
      >
        {
          isMounted ? (
            <CustomerTable
              title={t('customer')}
              data={customer && customer}
              organisations={organisations}
              t={t}
              {...rest}
            />
          ) : <Loader />
        }

      </div>
    );
  }
}

export default Customer;
