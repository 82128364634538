import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import packageJSON from '../../../../package.json';

import Tooltip from '@material-ui/core/Tooltip';
import PasswordIcon from '@material-ui/icons/LockOpen';

import { getWebUrl } from '../../../utils';

import TimeLeft from "./TimeLeft"

const fallbackLogo = require("../../../images/fallbacklogo.png");
const PCDLogo = require("../../../images/PCD_Logo.png");

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: "15px",
    marginLeft: "-24px",
    paddingRight: "15px",
    paddingLeft: "12px",
    paddingTop: "17px",
    height: "67px",
    backgroundColor: "white",
    borderBottom: "1px solid #b6b6b6"
  },
  title: {
    flexGrow: 1,
    fontSize: "17px",
    fontFamily: "Open Sans",
    marginLeft: "20px",
    paddingBottom: "1px",
    paddingLeft: "5px",
  },
  toolbar: {},
  logoutButton: {
    marginRight: 20,
    color: "white",
    backgroundColor: "#52a7d1"
  }
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 0 : 0,
    /*style: trigger ? { zIndex: 4, } : {}*/
  });
}

export default function Header(props) {
  const classes = useStyles();
  const { t, headerColor, logoUrl } = props;

  const handleLogout = async () => {
    const { logout } = props;
    await logout();

    props.history.push("/");
  }

  return (
    <div className={classes.root}>
      <ElevationScroll {...props}>
        <AppBar style={{ backgroundColor: `${headerColor}` }}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.menuButton}>
              <img src={logoUrl ? `${getWebUrl(logoUrl)}` : fallbackLogo} style={{ maxWidth: 166, maxHeight: 50 }} alt="" />
            </div>
            <div style={{ fontFamily: 'Open Sans', fontSize: 15, marginLeft: 25 }}>
              v{packageJSON.version}
            </div>
            <Typography variant="h4" className={classes.title}>
              &nbsp;&nbsp;{props.username} &nbsp;&nbsp;&nbsp; {props.organisationName} &nbsp;&nbsp;&nbsp; Environment: {props.environmentDisplayName}
            </Typography>
            {/*<TimeLeft {...props} />*/}
            <Tooltip title={t('changePasswordTitle')}>
              <Button
                href="/changepassword"
                size="small"
                color="primary"
                className="edit-popup-button"
                style={{ marginRight: 10 }}
                startIcon={<PasswordIcon style={{ marginLeft: 10, color: "rgb(45, 45, 45)" }} />}
              />
            </Tooltip>
            <Button
              variant="outlined"
              size="small"
              className={classes.logoutButton}
              onClick={handleLogout}
            >
              {t('logout')}
            </Button>
            <img
              alt="logo"
              src={PCDLogo}
              style={{ maxWidth: "166px !important" }}
            />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}
