import { connect } from "react-redux";
import { withNamespaces } from 'react-i18next';

import Snackbar from "./Snackbar";

import notiActions from "../../../actions/notification";

const mapDispatchToProps = {
    ...notiActions
};

const connected = connect(null, mapDispatchToProps)(Snackbar);
export default withNamespaces(['common'])(connected);
