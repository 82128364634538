import odata from 'odata-client';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import { showNotification } from '../notification';

var createEntity = {

  tokenExpired: context => () => ({
    type: context.TOKEN_EXPIRED
  }),
  createEntityRequest: context => () => ({
    type: context.CREATE_ENTITY_REQUEST
  }),
  createEntityError: context => errorOrResult => ({
    type: context.CREATE_ENTITY_ERROR,
    error: errorOrResult.message
  }),
  createEntitySuccess: context => payload => ({
    type: context.CREATE_ENTITY_SUCCESS,
    payload
  }),

  init: context => {
    context.CREATE_ENTITY_REQUEST = null;
    context.CREATE_ENTITY_ERROR = null;
    context.CREATE_ENTITY_SUCCESS = null;
    context.TOKEN_EXPIRED = null;
    context.entityName = null;
    context.entityLocation = null;
    context.hasOrganisation = false;
  },
  execute: context => entity => async (dispatch, getState) => {
    if (context.entityName === "crew") {
      if (!(entity.Name) || entity.Name.trim() === "" || !(entity.Organisation.ID)) {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }
    if (context.entityName === "specialday") {
      if (!(entity.Name) || entity.Name.trim() === "" || !(entity.Organisation.ID) || !(entity.DateFrom) || !(entity.DateTo)) {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }
    if (context.entityName === "customer") {
      if (!(entity.Name) || entity.Name.trim() === "" || !(entity.Organisation.ID)) {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }
    if (context.entityName === "driver") {
      if (!(entity.Name) || entity.Name.trim() === "" || !(entity.Organisation.ID) || !(entity.CompanyName) || entity.CompanyName.trim() === "") {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }
    if (context.entityName === "mixingPlant") {
      if (!(entity.Name) || entity.Name.trim() === "" || !(entity.Organisation.ID)) {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }
    if (context.entityName === "mixtureOrder") {
      if (!(entity.OrderNumber) || entity.OrderNumber.trim() === "") {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }
    if (context.entityName === "mixture") {
      if (!(entity.Name) || entity.Name.trim() === "") {
        dispatch(showNotification("Required fields must NOT be empty", "error"))
        return new Error()
      }
    }

    dispatch(context.createEntityRequest(context)());

    const odataObj = odata({ service: getServiceUrl() }).resource(context.entityLocation);
    const errorOrResult = await odataObj.post(entity, {
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        ...getDefaultHeader()
      }
    })
      .then(res => {
        const ok = res.statusCode <= 299 && res.statusCode >= 200;
        if (res.statusCode === 500) {
          const errorKey = "GENERAL_SERVER_ERROR";
          return new Error(errorKey);
        } else if (!ok) {
          const response = res.toJSON()
          const error = JSON.parse(response.body).Error
          const errorMessage = context.entityLocation === "SpecialDays" ? error.Message : error.Details[0].Message
          const errorKey = res.statusCode === 401 ? `401: ${errorMessage}` : `${errorMessage}`;
          console.log(`create ${context.entityName} api error`, response);
          return new Error(errorKey);
        } else {
          const body = JSON.parse(res.body);
          const { "@odata.context": omit, ...payload } = body;
          if (context.hasOrganisation !== undefined && context.hasOrganisation)
            payload.Organisation = entity.Organisation;
          return payload;
        }
      }, res => {
        // error
        const errorKey = `Error: ${res.status}, ${res.statusText}`;
        console.log('export project tender document api error', res.responseText);
        return new Error(errorKey);
      }
      )
      .catch((error) => {
        console.error('Error:', error);
        dispatch(showNotification("Request probably timed out.", "error"))
      });

    if (errorOrResult instanceof Error) {
      dispatch(context.createEntityError(context)(errorOrResult, context.CREATE_ENTITY_ERROR));
      console.log("CODE ERROR: ", errorOrResult.message);
      if (errorOrResult.message.split(":")[0] === "401") {
        dispatch(context.tokenExpired(context)());
        dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
      }
      if (errorOrResult.message.includes(","))
        dispatch(showNotification(errorOrResult.message.split(",")[1], "error"))
      else
        dispatch(showNotification(errorOrResult.message, "error"))
      return errorOrResult;
    } else {
      dispatch(showNotification("SUCCESSFUL_SAVE", "success"))
    }

    dispatch(context.createEntitySuccess(context)(errorOrResult));

    return errorOrResult;
  }
};

export default createEntity;