import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

import Header from "./Header";

import userActions from "../../../actions/user";
import setTokenExpired from "../../../actions/user/setTokenExpired";

const mapStateToProps = ({ user, settings }) => ({
  username: user.username,
  expiresAt: user.expiresAt,
  organisationName: user.organisation.Name,
  environmentDisplayName: user.environmentDisplayName,
  logoUrl: user.companyLogoUrl,
  headerColor: settings.settings.HeaderBackgroundColor
});

const mapDispatchToProps = {
  ...userActions,
  setTokenExpired
};

const connectedWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
export default withNamespaces(['common'])(connectedWithRouter);
