import React from "react";
import MaterialTable from "material-table";

import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Modal from '@material-ui/core/Modal';

import Mode from "./ModeEnum";

class ProjectsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title ? props.title : '',
      data: props.data ? props.data : '',
      editable: props.editable ? props.editable : '',
      selectedRow: null,
      error: null,
      organisations: props.organisations ? props.organisations : '',
      selectedFilters: [],
      openDeleteEntry: false,
      deleteEntryObj: null,
    };
  }

  componentDidMount() {
    const orgFilter = document.getElementById("select-multiple-checkbox");
    const mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const values = mutation.target.value.split(",");
        this.setState({ selectedFilters: values.length > 0 && values[0] !== "" ? values : [] });
      });
    });

    if (orgFilter) {
      mutationObserver.observe(orgFilter, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
        attributeOldValue: true,
        characterDataOldValue: true
      });
    } else {
      mutationObserver.disconnect();
    }
  }

  convertValue(props) {
    return `${props.value.FirstNames} ${props.value.LastName}`
  }

  compareByProperty(a, b) {
    var p = "value";
    if (a[p] > b[p]) return 1;
    if (b[p] > a[p]) return -1;

    return 0;
  }

  onDeleteRowClickHandler = (entity) => {
    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: entity });
  }

  closeDeleteEntryPopup = () => {
    this.setState({ openDeleteEntry: false, deleteEntryObj: null })
  }

  handleDeleteEntry = async () => {

    const { deleteEntryObj } = this.state;
    if (deleteEntryObj !== null) {
      let errorOrResult = await this.props.onChangeMode(Mode.Delete, deleteEntryObj)
        .then(res => {
          return res;
        }, res => {
          return res;
        }
        );

      // delete was successfull
      if (!(errorOrResult instanceof Error)) {
      }
    }

    this.setState({ openDeleteEntry: !this.state.openDeleteEntry, deleteEntryObj: null });
  }

  render() {
    const { data, organisations, openDeleteEntry } = this.state;
    var lookupOrgs = {};
    var lookupSupervisors = {};
    var lookupCostCenters = {};
    var lookupCustomers = {};

    const { t } = this.props;
    const columns = [

      {
        title: t('organisation'), field: "Organisation.Name", lookup: lookupOrgs, defaultFilter: this.state.selectedFilters, filtering: true,
        render: rowData => (
          rowData.Organisation.ShortName ? <>{rowData.Organisation.ShortName}</> : <>{rowData.Organisation.Name}</>
        )
      },

      {
        title: t('construction-supervisor'), field: "Supervisor", lookup: lookupSupervisors,
        render: rowData => (
          <>{`${rowData.Supervisor.FirstNames} ${rowData.Supervisor.LastName}`}</>
        ),
        customFilterAndSearch: (term, rowData) =>
          //search
          (
            (typeof term) === "string" &&
            (
              term === '' ||
              (rowData.Supervisor != null && `${rowData.Supervisor.FirstNames} ${rowData.Supervisor.LastName}`.includes(term))
            )
          ) ||
          //filter
          (
            (typeof term) !== "string" &&
            (
              term.length === 0 ||
              (rowData.Supervisor == null && term.includes('null')) ||
              (rowData.Supervisor != null && term.includes(`${rowData.Supervisor.FirstNames} ${rowData.Supervisor.LastName}`))
            )
          ),
        filtering: true
      },

      {
        title: t('cost-center'), field: "CostCenter", lookup: lookupCostCenters, filtering: true
      },
      {
        title: t('customer'), field: "Customer.Name", lookup: lookupCustomers, filtering: true
      },
      {
        title: t('description'), field: "Name", filtering: false, defaultSort: "asc"
      },
      {
        title: t('order-total-amount'), field: "OrderTotalAmount", filtering: false,
        render: rowData => (
          <p>{`${(rowData.OrderTotalAmount !== undefined && rowData.OrderTotalAmount !== null ? rowData.OrderTotalAmount.toLocaleString() + " €" : '')}`}</p>
        ),
        cellStyle: {
          textAlign: "right"
        }
      },
      {
        title: t('remaining-amount-to-invoice'), field: "RemainingAmountToInvoice", filtering: false,
        render: rowData => (
          <p>{`${(rowData.RemainingAmountToInvoice !== undefined && rowData.RemainingAmountToInvoice !== null ? rowData.RemainingAmountToInvoice.toLocaleString() + " €" : '')}`}</p>
        ),
        cellStyle: {
          textAlign: "right"
        }
      },
      {
        title: t('is-closed'), field: 'IsClosed', type: 'boolean',
        render: rowData => (
          <Checkbox checked={rowData.IsClosed} disabled={true} />
        ),
        filtering: true
      }

    ];

    if (organisations.length > 0) {
      organisations.forEach(org => {
        lookupOrgs[org.Name] = org.Name;
      })
    }

    if (data !== null && data.length > 0) {
      data.forEach(s => {
        var key = `${s.Supervisor.FirstNames} ${s.Supervisor.LastName}`;
        lookupSupervisors[key] = key;
        lookupCostCenters[s.CostCenter] = s.CostCenter;
        lookupCustomers[s.Customer.Name] = s.Customer.Name;
      })
    }

    return (
      <div>
        <MaterialTable
          title={this.props.title}
          columns={columns}
          data={this.props.data}
          onRowClick={((evt, rowData) => { this.setState({ selectedRow: rowData }) })}
          options={{
            pageSize: this.props.data.length > 5 ? 10 : 5,
            pageSizeOptions: this.props.data.length > 5 ? [5, 10, 20] : [5],
            paginationType: "normal",
            addRowPosition: "first",
            initialPage: 0,
            draggable: false,
            filtering: true,
            maxBodyHeight: 620
          }}
          localization={{
            header: {
              actions: t('actions')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            body: {
              emptyDataSourceMessage: t('no-records-to-display'),
              addTooltip: t('add'),
              deleteTooltip: t('delete'),
              editTooltip: t('edit'),
              editRow: {
                saveTooltip: t('save'),
                cancelTooltip: t('cancel'),
                deleteText: t('deleteText')
              }
            },
            pagination: {
              firstTooltip: t('first-page'),
              previousTooltip: t('previous-page'),
              nextTooltip: t('next-page'),
              lastTooltip: t('last-page')
            }
          }}
          actions={[
            {
              icon: 'add',
              tooltip: t('add'),
              isFreeAction: true,
              onClick: (event) => this.props.onChangeMode(Mode.Add, null)
            },
            {
              icon: 'edit',
              tooltip: t('edit'),
              onClick: (event, rowData) => this.props.onChangeMode(Mode.Update, rowData)
            },
            {
              icon: () => <DeleteOutlinedIcon />,
              tooltip: t('delete'),
              onClick: (event, rowData) => this.onDeleteRowClickHandler(rowData)
            }
          ]}
        />
        <Modal open={openDeleteEntry} onClose={this.closeDeleteEntryPopup}>
          <div className="delete-popup">
            <div style={{ width: "100%", textAlign: "center", color: "#242424" }}>
              <h2>{t('deleteText')}</h2>
            </div>
            <div className="buttonContainer">
              <Button
                onClick={this.closeDeleteEntryPopup}
                style={{ marginRight: 7 }}
                variant="contained"
                color="primary"
                size="small"
              >
                {t('cancel')}
              </Button>
              <Button
                onClick={this.handleDeleteEntry}
                variant="contained"
                color="secondary"
                size="small"
              >
                {t('delete')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProjectsTable