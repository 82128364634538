import odata from 'odata-client';
import {
    UPDATE_PENSUM_REQUEST,
    UPDATE_PENSUM_ERROR,
    UPDATE_PENSUM_SUCCESS,
    TOKEN_EXPIRED
} from '../types';
import { getServiceUrl, getDefaultHeader } from '../../utils';
import RESOURCES from '../../api/resources';
import { showNotification } from '../notification';
/*import getToken from '../user/getToken';*/

export const tokenExpired = () => ({
    type: TOKEN_EXPIRED
});

export const updatePensumsRequest = () => ({
    type: UPDATE_PENSUM_REQUEST,
});

export const updatePensumsError = errorOrResults => ({
    type: UPDATE_PENSUM_ERROR,
    error: errorOrResults.message,
});

export const updatePensumsSuccess = payload => ({
    type: UPDATE_PENSUM_SUCCESS,
    payload,
});

export default (queryData) => async (dispatch, getState) => {
    dispatch(updatePensumsRequest());
    const ID = Object.keys(queryData)[0]

    const query = odata({service: getServiceUrl()}).resource(RESOURCES.Pensums, parseInt(ID));

    const errorOrResult = await query.patch(queryData[ID], {
        headers: {
          Authorization: `Bearer ${getState().user.token}`,
          ...getDefaultHeader()
        }
    })
        .then(resp => resp)
        .then(res => {
            const ok = res.statusCode <= 299 && res.statusCode >= 200;

            if (res.statusCode === 500) {
                const errorKey = "GENERAL_SERVER_ERROR";
                return new Error(errorKey);
            } else if (!ok) {
                console.log('update pensum api error', res.toJSON());
                const body = JSON.parse(res.body);
                const { Error: { Message } } = body
                const errorKey = res.statusCode === 401 ? `401: ${Message}` : `${Message}`;
                return new Error(errorKey);
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            dispatch(showNotification("Request probably timed out.", "error"))
        })

    if (errorOrResult instanceof Error) {
        dispatch(updatePensumsError(errorOrResult, UPDATE_PENSUM_ERROR));
        if (errorOrResult.message.split(":")[0] === "401") {
            dispatch(tokenExpired());
            dispatch(showNotification(errorOrResult.message.split(":")[1], "error"))
        }
        console.log("CODE ERROR: ", errorOrResult.message);
        dispatch(showNotification(errorOrResult.message, "error"))
        return;
    }

    dispatch(updatePensumsSuccess(errorOrResult));

    return errorOrResult;
};