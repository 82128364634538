import login from "./login";
import logout from "./logout";
import setLanguage from "./setLanguage";
import setTokenExpired from "./setTokenExpired";
import setChangeMessageToken from "./setChangeMessageToken";
import changePassword from "./changePassword";
import setEnvironmentLogo from "./setEnvironmentLogo";

export {
    login,
    logout,
    setLanguage,
    setTokenExpired,
    setChangeMessageToken,
    changePassword,
    setEnvironmentLogo
};

export default {
    login,
    logout,
    setLanguage,
    setTokenExpired,
    setChangeMessageToken,
    changePassword,
    setEnvironmentLogo
};
