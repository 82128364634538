import {
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_SUCCESS,
  TOKEN_EXPIRED
} from '../types';
import context from "../common/createEntity"
import RESOURCES from '../../api/resources';

const initialState = {};
export default entity => (state = initialState, action) => {

  context.init(context);

  context.CREATE_ENTITY_REQUEST = CREATE_PROJECT_REQUEST;
  context.CREATE_ENTITY_ERROR = CREATE_PROJECT_ERROR;
  context.CREATE_ENTITY_SUCCESS = CREATE_PROJECT_SUCCESS;
  context.TOKEN_EXPIRED = TOKEN_EXPIRED;

  context.entityName = "project";
  context.entityLocation = RESOURCES.Projects;
  context.hasOrganisation = true;

  return context.execute(context)(entity)(state, action);
};